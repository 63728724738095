<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row" v-if="file_preview">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <div class="col-md-8">
                                            <div class="alert alert-light" role="alert">
                                                <span>
                                                    <b>Note:</b> Do not put comma on any numeric values shuch as
                                                    basic salary, allowance, other deduction etc.
                                                </span>
                                            </div>
                                        </div>
                                        <table class="table table-hover table-bordered table-sm">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>First Name</th>
                                                    <th>Middle Name</th>
                                                    <th>Last Name</th>
                                                    <th>Basic Salary</th>
                                                    <th>Mobile No</th>
                                                    <th>Address</th>
                                                    <th>StaffID</th>
                                                    <th>Tin No</th>
                                                    <th>Nssf</th>
                                                    <th>Wcf</th>
                                                    <th>Allowance Deduction</th>
                                                    <th>Allowance</th>
                                                    <th>Social Security No</th>
                                                    <th>Residential Status</th>
                                                    <th>Employment Type</th>
                                                    <th>Loan Board</th>
                                                    <th>Department</th>
                                                    <th>Designation</th>
                                                    <th>Other Deductions</th>
                                                    <th>Bank Account No</th>
                                                    <th>Bank Name</th>
                                                    <th>Bank Branch</th>
                                                    <th>Birth Date</th>
                                                    <th>Contract Start Date</th>
                                                    <th>Contract End Date</th>
                                                    <th>Relative Full Name</th>
                                                    <th>Relative Mobile No</th>
                                                    <th>Relative Address</th>
                                                    <th>Gender</th>
                                                    <th>Relative Relationship</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(employee, i) in employees" :key="i">
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.first_name"/>
                                                        <div v-if="errors.first_name">
                                                            <span class="text-danger">{{ errors.first_name[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.middle_name"/>
                                                        <div v-if="errors.middle_name">
                                                            <span class="text-danger">{{ errors.middle_name[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.last_name"/>
                                                        <div v-if="errors.last_name">
                                                            <span class="text-danger">{{ errors.last_name[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.basic_salary">
                                                        <div v-if="errors.basic_salary">
                                                            <span class="text-danger">{{ errors.basic_salary[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td><input type="text" class="form-control custom-input-width" v-model="employee.mobile_no"/></td>
                                                    <td><input type="text" class="form-control custom-input-width" v-model="employee.address" /></td>
                                                    <td><input type="text" class="form-control custom-input-width" v-model="employee.staffId"></td>
                                                    <td><input type="text" class="form-control custom-input-width" v-model="employee.tin_no"/></td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.nssf" />
                                                        <div v-if="errors.nssf">
                                                            <span class="text-danger">{{ errors.nssf[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.wcf"/>
                                                        <div v-if="errors.wcf">
                                                            <span class="text-danger">{{ errors.wcf[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.allowance_deduction" />
                                                        <div v-if="errors.allowance_deduction">
                                                            <span class="text-danger">{{ errors.allowance_deduction[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.allowance"/>
                                                        <div v-if="errors.allowance">
                                                            <span class="text-danger">{{ errors.allowance[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.social_seq_no"/>
                                                        <div v-if="errors.social_seq_no">
                                                            <span class="text-danger">{{ errors.social_seq_no[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.residential_status"/>
                                                        <div v-if="errors.residential_status">
                                                            <span class="text-danger">{{ errors.residential_status[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.employee_type"/>
                                                        <div v-if="errors.employee_type">
                                                            <span class="text-danger">{{ errors.employee_type[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.loan_board"/>
                                                        <div v-if="errors.loan_board">
                                                            <span class="text-danger">{{ errors.loan_board[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td><input type="text" class="form-control custom-input-width" v-model="employee.departiment"/></td>
                                                    <td><input type="text" class="form-control custom-input-width" v-model="employee.designation"/></td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.other_deductions"/>
                                                        <div v-if="errors.other_deductions">
                                                            <span class="text-danger">{{ errors.other_deductions[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.bank_account_no" />
                                                        <div v-if="errors.bank_account_no">
                                                            <span class="text-danger">{{ errors.bank_account_no[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.bank_name">
                                                        <div v-if="errors.bank_name">
                                                            <span class="text-danger">{{ errors.bank_name[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td><input type="text" class="form-control custom-input-width" v-model="employee.bank_branch"></td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.birth_date">
                                                        <div v-if="errors.birth_date">
                                                            <span class="text-danger">{{ errors.birth_date[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.contract_start_date">
                                                        <div v-if="errors.contract_start_date">
                                                            <span class="text-danger">{{ errors.contract_start_date[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control custom-input-width" v-model="employee.contract_end_date">
                                                        <div v-if="errors.contract_end_date">
                                                            <span class="text-danger">{{ errors.contract_end_date[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td><input type="text" class="form-control custom-input-width" v-model="employee.relative_full_name" /></td>
                                                    <td><input type="text" class="form-control custom-input-width" v-model="employee.relative_mobile"/></td>
                                                    <td><input type="text" class="form-control custom-input-width" v-model="employee.relative_address" /></td>
                                                    <td><input type="text" class="form-control custom-input-width" v-model="employee.gender" /></td>
                                                    <td><input type="text" class="form-control custom-input-width" v-model="employee.relative_relationship" /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="row">
                                            <div class="col-md-12" v-if="error">
                                                <div class="alert alert-danger" role="alert">{{ error }}</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="mb-2" v-if="isSubmiting">
                                                    <button type="button" disabled @click="backToChooseFile" class="btn btn-info btn-sm mr-2"><i class="fas fa-chevron-left"> </i>Back</button>
                                                    <button type="button" disabled click="saveImportedEmployees" class="btn btn-success btn-sm">Save</button>
                                                </div>
                                                <div class="mb-2" v-else>
                                                    <button type="button" @click="backToChooseFile" class="btn btn-info btn-sm mr-2"><i class="fas fa-chevron-left"> </i>Back</button>
                                                    <button type="button" @click="saveImportedEmployees" class="btn btn-success btn-sm">Save</button>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <pulse-loader 
                                                    :loading="isSubmiting" 
                                                    :color="color"
                                                    v-if="isSubmiting" 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else class="row justify-content-center">
                        <div class="col-md-6" style="margin-top: 4%;">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Import Employees</span>
                                        </div>
                                        <div class="col-md-2">
                                            <RouterLink to="/hr-dashboard">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                               
                                <div class="card-body" >
                                    <div class="row justify-content-center p-2">
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <span>Select File:</span>
                                                <input type="file" @change="handleFileUpload" class="form-control"/>
                                                <div v-if="errors.file">
                                                    <span class="text-danger">{{ errors.file[0] }}</span>
                                                </div>
                                                <div v-if="file_error">
                                                    <span class="text-danger">{{ file_error }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 mt-4">
                                            <div class="col-md-12" v-if="isLoading">
                                                <button type="button" class="btn btn-info" disabled>Proceeding...</button>
                                            </div>
                                        <div class="col-md-12" v-else>
                                            <button type="button" @click="submitFile" class="btn btn-info" ><i class="fas fa-recycle"></i> Proceed</button>
                                        </div>
                                        </div>
                                    </div>
            
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <button type="button" @click="downloadEmployeeSheet" class="btn btn-info btn-sm"><i class="fas fa-download"></i> Download Employees Sheet</button>
                                        </div>
                                        <pulse-loader 
                                            :loading="isLoading" 
                                            :color="color"
                                            v-if="isLoading" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <ControlSidebar></ControlSidebar>
    </body>
</template>

<script>
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        Footer,
        ControlSidebar,
    },

    data(){
        return{
            isLoading: false,
            isSubmiting: false,
            file: null,
            errors: '',
            color: '#40aac7',
            file_error: '',
            file_preview: false,
            error: '',
            employees: [
                {
                    first_name: '',
                    middle_name: '',
                    last_name: '',
                    gender: '',
                    mobile_no: '',
                    address: '',
                    staffId: '',
                    tin_no: '',
                    nssf: '',
                    wcf: '',
                    basic_salary: '',
                    allowance_deduction: '',
                    allowance: '',
                    social_seq_no: '',
                    residential_status: '',
                    employee_type: '',
                    loan_board: '',
                    departiment: '',
                    designation: '',
                    other_deductions: '',
                    bank_account_no: '',
                    bank_name: '',
                    bank_branch: '',
                    birth_date: '',
                    contract_start_date: '',
                    contract_end_date: '',
                    relative_full_name: '',
                    relative_mobile: '',
                    relative_address: '',
                    relative_relationship: '',
                }
            ],
        }
    },

    methods:{
        handleFileUpload(event) {
            this.file = event.target.files[0];
        },

        async submitFile(){
            this.isLoading = true
            if (!this.file) {
                this.isLoading = false
                this.file_preview = false
                this.file_error = "Please select a file to upload.";
                return;
            }

            const formData = new FormData();
            formData.append('file', this.file);

            await Api().post('import-employees',formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.employees = res.data.employees
                    // console.log(res.data.employees)
                    this.file_preview = true
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    this.file_preview = false
                    this.file_error = error.response.data.error
                    console.log('Failed to impotr employees.',error)
                }
            })
        },

        backToChooseFile(){
            this.file_preview = false
        },

        async saveImportedEmployees(){
            this.isSubmiting = true
            await Api().post('save-imported-employees',{
                employees: this.employees
            })
            .then(res =>{
                if(res.status === 200){
                    this.isSubmiting = false
                    this.file_preview = false
                        Swal.fire({
                        title: "Successfully!",
                        text: `${res.data.message}`,
                        icon: "success",
                    });
                }else if(res.status === 203){
                    this.isSubmiting = false
                    this.file_preview = true
                    this.error = res.data.error
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isSubmiting = false
                    this.errors = error.response.data.errors
                    // this.file_preview = false
                    // this.file_error = error.response.data.error
                }
            })
        },

        downloadEmployeeSheet() {
            const fileUrl = 'https://erp-api.depima.co.tz/sheet/EMPLOYEE_SHEET.csv'; // Replace with your actual file URL
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', 'employees.csv'); // Set the desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    }
}
</script>

<style>
.custom-input-width {
    width: 200px;
    height: 32px;
    border: none
}
</style>