<template>
  <!-- Content Header (Page header) -->
        <div class="content-header" style="margin-top: 2%;">
          <div class="container-fluid">
            <div class="row mb-1">
              <!-- <div class="col-sm-6">
                <h1 class="m-0">Dashboard</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active">Dashboard v1</li>
                </ol>
              </div> -->
            </div>
            <!-- /.row -->
          </div>
          <!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
</template>

<script>
export default {

}
</script>

<style>

</style>