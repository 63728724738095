import axios from "axios";
import store from '../store/store'  

export default () =>{
    return axios.create({
        baseURL: `https://erp-api.depima.co.tz/api/v1/`,
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    })
}