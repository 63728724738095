<template>
  <footer class="main-footer">
    <strong>Copyright &copy; 2014-2021 <span style="color: #16B4C9;">Depima Software</span>.</strong>
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <!-- <b>Version</b> 3.1.0 -->
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>
 .main-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f8f9fa;
    /* padding: 10px 20px; */
    z-index: 1030;
}
</style>