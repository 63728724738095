<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span><b>Approve Leave Request</b></span>
                                        </div>
                                        <div class="col-md-2">
                                            <RouterLink to="/approved-leaves">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
                                <div class="card-body" v-else>
                                    <div class="row justify-content-center">
                                        <div class="col-md-8">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <span>Leave name: <b>{{ data.leave_name }}</b></span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <span>Employee Name: <b>{{ data.requestedBy }}</b></span>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2">
                                                        <div class="col-md-6">
                                                            <span>Number of days requested: <b>{{ data.days_requested }}</b></span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <span>Status: <span class="badge badge-success">{{ data.status }}</span></span>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2">
                                                        <div class="col-md-6">
                                                            <span>From Date: <b>{{ data.fromDate }}</b></span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <span>To Date: <b>{{ data.toDate }}</b></span>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-md-6">
                                                            <span><b>Reason</b></span>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            <p>{{ data.reason }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
                <ControlSidebar></ControlSidebar>
            </div>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
// import Swal from 'sweetalert2';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default{
    components: {
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer,
        PulseLoader
    },
    data(){
        return{
            data: {
                leave_name: '',
                id: this.$route.params.id,
                employeeId: '',
                fromDate: '',
                toDate: '',
                requestedBy: '',
                days_requested: 0,
                status: '',
                reason: '',
            },

            isLoading: false,
            isApproved: false,
            color: '#40aac7',
        }
    },

    mounted(){
        this.fetchLeaveRequest();
    },

    methods: {
        async fetchLeaveRequest(){
            this.isLoading = true
            await Api().get(`get-leave-byId/${this.data.id}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.data.leave_name = res.data[0].leave_types.name
                    this.data.requestedBy = res.data[0].requestedBy
                    this.data.fromDate = res.data[0].fromDate
                    this.data.toDate = res.data[0].toDate
                    this.data.days_requested = res.data[0].days_requested
                    this.data.status = res.data[0].status
                    this.data.reason = res.data[0].reason
                    this.data.employeeId = res.data[0].employeeId
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to get leave.',error)
                }
            })
        },
    },
}
</script>