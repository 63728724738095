<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-12" style=" margin-bottom: 4%;">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <span>Request expenses</span>
                                        </div>
                                        <div class="col-md-10">
                                            <RouterLink to="/expenses-report" v-if="hasPermission('expenses_reports')">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-highlighter"></i> Expenses Report</button> 
                                            </RouterLink>
                                            <RouterLink to="/new-category" v-if="hasPermission('new_expense_category')">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-satellite"></i> New Category</button> 
                                            </RouterLink>
                                            <RouterLink to="/expenses-category-list" v-if="hasPermission('expense_category_lists')">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-list"></i> Category List</button> 
                                            </RouterLink>
                                            <RouterLink to="/new-expenses" v-if="hasPermission('new_expenses')">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fa fa-record-vinyl"></i> New Expense</button> 
                                            </RouterLink>
                                            <RouterLink to="/expenses-list" v-if="hasPermission('expense_lists')" >
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-list-alt"></i> Expenses List</button> 
                                            </RouterLink>
                                            <RouterLink to="/requested-expenses-list" v-if="hasPermission('requested_expense')">
                                                <button type="button" class="btn btn-info btn-sm" ><i class="fas fa-vote-yea"></i> Requested Expenses</button> 
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
            
                                <div class="card-body" v-else>
                                    <div class="row">
                                        <table class="table table-sm table-responseve-sm mb-4">
                                            <tr>
                                                <td>Category Name</td>
                                                <td>Expenses Name</td>
                                                <td>Description</td>
                                                <td>Amount</td>
                                                <td></td>
                                            </tr>
                                            <tr v-for="(row, index) in rows" :key="index">
                                                <td>
                                                    <input
                                                        v-model="row.searchQuery"
                                                        placeholder="Search..."
                                                        @input="filterItems(index)"
                                                        class="form-control"
                                                    />
                                                    <div class="dropdown" >
                                                        <ul>
                                                            <li class="li-dropdown"
                                                                v-for="category in row.filteredItems"
                                                                :key="category.id"
                                                                @click="selectItem(index, category)">
                                                                {{ category.category_name }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div v-if="errors.categoryId">
                                                        <span class="text-danger">{{ errors.categoryId[0] }}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        v-model="row.dependentSearchQuery"
                                                        placeholder="Search..."
                                                        @input="filterDependentData(index)"
                                                        class="form-control"
                                                    />
                                                    <div class="dropdown" >
                                                        <ul>
                                                            <li class="li-dropdown"
                                                                v-for="expense in row.filteredDependentData"
                                                                :key="expense.id"
                                                                @click="onDependentItemSelect(index, expense)">
                                                                {{ expense.expenses_name }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div v-if="errors.categoryId">
                                                        <span class="text-danger">{{ errors.categoryId[0] }}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control" v-model="row.description" placeholder="Optional"/>
                                                </td>
                                                <td>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        :value="formattedAmount(index)" 
                                                        @input="updateAmount($event.target.value, index)"
                                                    />
                                                    <div v-if="errors.amount">
                                                        <span class="text-danger">{{ errors.amount[0] }}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <button type="button" class="btn btn-danger btn-sm" @click="removeRow(index)"><i class="fas fa-minus"></i></button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
            
                                    <div class="row pl-1">
                                        <div class="col-md-9">
                                            <tr>
                                                <td>
                                                    <button type="button" class="btn btn-info btn-sm" @click="addRow">
                                                        <i class="fas fa-plus"></i> Add Row
                                                    </button>
                                                </td>
                                            </tr>
                                        </div>
                                       
                                    </div>
            
                                    <div class="row mt-5">
                                        <div class="col-md-9">
                                            <tr>
                                                <td>
                                                    <date-picker v-model:value="data.date" 
                                                    value-type="YYYY-MM-DD" 
                                                    placeholder="Select Date"
                                                    />
                                                    <div v-if="errors.date">
                                                        <span class="text-danger">{{ errors.date[0] }}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control" placeholder="Reference No" v-model="data.ref_no"/>
                                                </td>
                                                <td width="300">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend" style="height: 32px;">
                                                            <span class="input-group-text" for="inputGroupSelect01">Payable Account</span>
                                                        </div>
                                                        <select class="form-control" style="height: 32px;" v-model="data.payable_account">
                                                            <option v-for="(account, i) in accounts" :key="i" :value="account.id">{{ account.account_name }} {{ account.account_no }} {{ account.banks.bank_name }}</option>
                                                        </select>
                                                        <div v-if="errors.payable_account">
                                                            <span class="text-danger">{{ errors.payable_account[0] }}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </div>
            
                                        <div class="col-md-3 mt-1">
                                            <tr>
                                                <td width="150"><b>Total Amount: </b></td>
                                                <td>{{ formatNumber(grandTotal) }}</td>
                                            </tr>
                                        </div>
                                    </div>
            
            
                                    <div class="row p-2 mt-5">
                                        <div class="col-md-4" v-if="isUpdating">
                                            <button type="button" class="btn btn-info btn-sm" disabled>Submiting...</button>
                                        </div>
                                        <div class="col-md-4" v-else>
                                            <button type="button" class="btn btn-info btn-sm" @click="requestExpenses">Submit</button>
                                        </div>
                                        <pulse-loader 
                                            :loading="isUpdating" 
                                            :color="color"
                                            v-if="isUpdating" 
                                        />
                                        <div class="col-md-6">
                                            <div class="alert alert-light" role="alert">
                                                <span>Follow procedures for expenses registration</span>
                                                <li>Register new category. Eg fuel</li>
                                                <li>Register new Expenses. Eg. Diseal, Petrol etc</li>
                                                <li>Request Expenses</li>
                                                <span>
                                                    <b>Note:</b> When you register expenses, there is no need to register again the next time you need to request.
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row justify-content-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import store from '../../store/store';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        DatePicker,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
                categoryId: '',
                expenses_name: '',
                date: '',
                ref_no: '',
                id: this.$route.params.id,
                payable_account: '',
                userId: store.state.user.id,
            },
            isLoading: false,
            isUpdating: false,
            errors: '',
            color: '#40aac7',
            categories: [],
            accounts: [],
            permissions: [],

            rows: [{
                searchQuery: '',
                dependentSearchQuery: '',
                category: '',
                categoryId: '',
                expensesId: '',
                description: '',
                expenses: [],
                amount: 0,
                filteredItems: [],
                filteredDependentData: [],
                selectedItem: null,
            }]
        }
    },

    mounted(){
        this.fetchCategories();
        this.fetchAccounts();
        // this.fetchPermissions();
    },

    computed: {
        // Method to get the formatted amount for a specific row
        formattedAmount() {
            return (index) => this.formatNumber(this.rows[index].amount);
        },

        grandTotal() {
            return this.rows.reduce((sum, item) => sum + item.amount, 0);
        },

    },

    methods: {
        formatNumber(value) {
            if (!value && value !== 0) return ''; // Handle empty or null values
            return new Intl.NumberFormat().format(value); // Format with commas
        },

        parseNumber(value) {
            let parsed = parseFloat(value.replace(/,/g, ''));
            return isNaN(parsed) ? 0 : parsed; // Parse the number, remove commas, default to 0 if NaN
        },

        updateAmount(value, index) {
            this.rows[index].amount = this.parseNumber(value); // Update the raw amount value
        },

        async fetchCategories(){
            this.isLoading = true
            await Api().get('expenses-categories')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.categories = res.data
                }
            }).catch(error =>{
                if(error){
                    console.log('Failed to get users')
                }
            })
        },

        async fetchAccounts(){
            this.isLoading = true
            await Api().get('accounts')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.accounts = res.data
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to fetch account informations.', error)
                }
            })
       },

        async requestExpenses(){
            this.isUpdating = true
            const dataToSubmit = this.rows.map(row =>({
                categoryId: row.categoryId,
                expensesId: row.expensesId,
                description: row.description,
                amount: row.amount,
            }))
           await Api().post('request-expenses',{
            data: dataToSubmit,
            date: this.data.date,
            ref_no: this.data.ref_no,
            total_amount: this.grandTotal,
            payable_account: this.data.payable_account
           })
           .then(res =>{
            if(res.status === 200){
                this.isUpdating = false
                Swal.fire({
                    title: "Successfully!",
                    text: `${res.data.message}`,
                    icon: "success"
                });
                this.resetRow();
            }else{
                this.isUpdating = false
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                });
            }
           }).catch(error =>{
            if(error.response.status === 422){
                this.isUpdating = false
                this.errors = error.response.data.errors
            }
           })
        },

        resetRow(){
            this.data.ref_no = ''
            this.data.date = ''
            this.rows = [{
                searchQuery: '',
                dependentSearchQuery: '',
                category: '',
                categoryId: '',
                expensesId: '',
                description: '',
                expenses: [],
                amount: '',
                filteredItems: [],
                filteredDependentData: [],
                selectedItem: null,
            }]
        },

        addRow(){
            this.rows.push({
                searchQuery: '',
                dependentSearchQuery: '',
                // category: '',
                categoryId: '',
                expensesId: '',
                description: '',
                expenses: [],
                amount: 0,
                filteredItems: [],
                filteredDependentData: [],
                selectedItem: null,
            })
        },

        removeRow(index){
            this.rows.splice(index, 1)
       },

       filterItems(rowIndex) {
            const row = this.rows[rowIndex];
            if(row.searchQuery.trim() === ''){
                row.filteredItems = [];
            }else{
                row.filteredItems = this.categories.filter(category =>
                    category.category_name.toLowerCase().includes(row.searchQuery.toLowerCase())
                );
            }
        },

        async selectItem(rowIndex, category) {
            // this.rows[rowIndex].selectedItem = category;
            this.rows[rowIndex].categoryId = category.id;
            this.rows[rowIndex].searchQuery = category.category_name;
            this.rows[rowIndex].filteredItems = [];
            await Api().get(`get-expenses-by-category-id/${category.id}`)
            .then(res =>{
                this.rows[rowIndex].expenses = res.data
            })
        },


        filterDependentData(rowIndex) {
            const row = this.rows[rowIndex];
            if(row.dependentSearchQuery.trim() === ''){
                row.filteredDependentData = []
            }else{
                const query = row.dependentSearchQuery.toLowerCase();
                row.filteredDependentData = row.expenses.filter(data =>
                    data.expenses_name.toLowerCase().includes(query)
                );
            }
        },

        onDependentItemSelect(rowIndex, dependentItem) {
            const row = this.rows[rowIndex];
            row.selectedDependentItem = dependentItem;
            row.expensesId = dependentItem.id;
            this.rows[rowIndex].filteredDependentData = [];
            this.rows[rowIndex].dependentSearchQuery = dependentItem.expenses_name;
        },

        // async fetchPermissions(){
        //     this.isLoading = true
        //     await Api().get(`permissions/${this.data.userId}`)
        //     .then(res =>{
        //         if(res.status === 200){
        //         this.isLoading = false
        //         this.permissions = res.data
        //         }
        //     }).catch(error =>{
        //         if(error){
        //         this.isLoading = false
        //         console.error('Failed to fetch permissions.',error)
        //         }
        //     });
        // },

        hasPermission(permissionKey) {
            return store.state.permissions.some(permission => permission.key === permissionKey && permission.value);
        }
    }
}
</script>

<style scoped>
table tr td{
    border: none;
}

input{
    height: 33px;
    padding: 2px;
}

.dropdown {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  max-height: 150px; /* Set a maximum height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.li-dropdown {
  padding: 10px;
  cursor: pointer;
}

.li-dropdown:hover {
  background-color: #f0f0f0;
}
</style>