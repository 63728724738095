<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 2%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-4 col-sm-6 col-lg-9" style="margin-top: 2%; margin-bottom: 4%;">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-10">
                                            <span>Billing</span>
                                        </div>
                                        <div class="col-2">
                                            <RouterLink to="/">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                <!-- 
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                /> -->
            
                                <div class="card-body" >
                                   <div class="row">
                                    <div class="col-md-6">
                                        <h1>Payment Status</h1>
                                        <p>Status: {{ paymentStatus }}</p>
                                        <p>Transaction Token: {{ transactionToken }}</p>
                                        <p>Payment Details:</p>
                                        <pre>{{ paymentDetails }}</pre>
                                    </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
// import Api from '../../services/Api';
// import Swal from 'sweetalert2';
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        // PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            paymentStatus: null,
            transactionToken: null,
            paymentDetails: null,
        }
    },

    created() {
        // Access the query parameters from the URL
        const query = this.$route.query;

        // Get the status and transactionToken from query parameters
        this.paymentStatus = query.status;
        this.transactionToken = query.transactionToken;

        // Decode and parse the JSON string from the 'data' query parameter
        if (query.data) {
        try {
            const decodedData = decodeURIComponent(query.data);
            this.paymentDetails = JSON.parse(decodedData);
        } catch (e) {
            console.error('Failed to parse payment details:', e);
            this.paymentDetails = null;
        }
        }
    }
}
</script>