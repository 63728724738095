<template>
   <DashboardComponent v-if="currentUser.user_type == 'Client'"/>
   <SelfServices v-else/>
</template>

<script>
import DashboardComponent from '../../components/DashboardComponent.vue';
import SelfServices from '../self_services/SelfServices.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
       DashboardComponent,
       SelfServices,
    },

    computed: {
        ...mapGetters(['currentUser'])
    },

};
</script>

<style>
</style>