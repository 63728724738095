<template>
    <div class="row justify-content-center" style="margin-top: 10%;">
        <div class="col-md-4">
            <div class="card">
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-md-6">
                            <span style="font-size: 24px; font-weight: 600;">Password Reset</span>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="alert alert-danger" role="alert" v-if="error_reset">
                                {{ error_reset }}
                                </div>
                                <label>New Password:</label>
                                <input type="password" class="form-control" v-model="data.password"/>
                                <div v-if="errors.password">
                                    <span class="text-danger">{{ errors.password[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Confirm Password:</label>
                                <input type="password" class="form-control" v-model="data.password_confirmation"/>
                                <div v-if="errors.password_confirmation">
                                    <span class="text-danger">{{ errors.password_confirmation[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div class="row ">
                        <div class="col-md-12" v-if="isLoading">
                            <button type="button" disabled @click="updatePassword" class="btn btn-info btn-sm btn-block">SUBMIT</button>
                        </div>
                        <div class="col-md-12" v-else>
                            <button type="button" @click="updatePassword" class="btn btn-info btn-sm btn-block">SUBMIT</button>
                        </div>
                    </div>
                  
                    <div class="row justify-content-center mt-2">
                        <pulse-loader
                         :loading="isLoading"
                         :color="color"
                         v-if="isLoading"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Api from '../../services/Api';
import Swal from 'sweetalert2';
// import store from '../../store/store';

export default{
    components: {
        PulseLoader
    },
    data(){
        return{
            data: {
                token: this.$route.query.token || '',
                email: this.$route.query.email || '',
                password: '',
                password_confirmation: '',
            },

            isLoading: false,
            color: '#40aac7',
            errors: '',
            isLogin: false,
            error_reset: ''
        }
    },

    // mounted(){
    //   const urlParams = new URLSearchParams(window.location.search);
    //   this.token = urlParams.get('token') || '';
    // },

    methods: {
        async updatePassword(){
            this.isLoading = true
            await Api().post('update-password',this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    Swal.fire({
                        title: "Successfully!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });
                    this.$router.push('/login')
                }else if(res.status === 201){
                    this.isLoading = false
                    this.error_reset = res.data.error
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isLoading = false
                    this.errors = error.response.data.errors
                }

                if(error.response.status === 201){
                    this.isLoading = false
                    this.error_reset = error.response.data.error
                }
            })
        }
    },
}
</script>