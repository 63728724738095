<template>
    <body class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
      <div class="wrapper">
        <Navbar></Navbar>
        <Sidebar></Sidebar>
        <div class="content-wrapper">
          <ContentHeader></ContentHeader>
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div class="col-lg-10" style="padding-bottom: 1%;">
                <div class="card">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-md-10">
                        <span>Income Statement</span>
                      </div>
                      <div class="col-md-2">
                        <RouterLink to="/new-sales">
                          <button type="button" class="btn btn-info btn-sm mr-2">
                            <i class="fas fa-chevron-left"></i> Back
                          </button>
                        </RouterLink>
                      </div>
                    </div>
                  </div>
  
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <date-picker
                            v-model:value="data.startDate"
                            value-type="YYYY-MM-DD"
                            placeholder="Start Date"
                            @change="onStartDateChange"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <date-picker
                            v-model:value="data.endDate"
                            value-type="YYYY-MM-DD"
                            placeholder="End Date"
                            @change="onEndDateChange"
                          />
                        </div>
                      </div>
                    </div>
  
                    <pulse-loader 
                        :loading="isLoading" 
                        :color="color" v-if="isLoading" 
                        style="margin-top: 50px; 
                        margin-left: 40%;" 
                    />
  
                    <!-- Styled Income Statement Table -->
                    <div v-else class="income-statement">
                      <h5 class="text-center">Income Statement</h5>
                      <p class="text-center">For Year Ended {{ yearEndDate }}</p>
  
                      <table class="table table-borderless table-sm">
                        <!-- Gross Revenue and Cost of Sales -->
                        <tr>
                          <td>Gross Revenue</td>
                          <td class="text-right">({{ formatNumber(gross_revenue) }})</td>
                        </tr>
                        <tr>
                          <td>Less: Discounts</td>
                          <td class="text-right">{{ formatNumber(discount) }}</td>
                        </tr>
                        <tr>
                          <td>Net Revenue</td>
                          <td class="text-right">({{ formatNumber(net_revenue) }})</td>
                        </tr>

                        <tr>
                          <td>Cost of Goods Sold (COGS)</td>
                          <td class="text-right">{{ formatNumber(cogs) }}</td>
                        </tr>
  
                        <!-- Gross Profit -->
                        <tr class="font-weight-bold">
                          <td>Gross Profit</td>
                          <td class="text-right">({{ formatNumber(gross_profit) }})</td>
                        </tr>
  
                        <!-- Operating Expenses -->
                        <tr>
                          <td></td>
                          <td class="text-right"></td>
                        </tr>
                        <tr>
                          <td>Operating Expenses</td>
                          <!-- <td class="text-right">{{ formatNumber(selling_expenses) }}</td> -->
                        </tr>

                        <tr v-for="(expense, i) in expenses" :key="i">
                            <td>{{ expense.expenses.expenses_name }}</td>
                            <td class="text-right">{{ formatNumber(expense.amount) }}</td>
                        </tr>
                        <tr>
                          <td>Total Operating Expenses</td>
                          <td class="text-right"><strong>{{ formatNumber(total_expenses) }}</strong></td>
                        </tr>
                        <tr class="font-weight-bold">
                          <td>Operating Income</td>
                          <td class="text-right">({{ formatNumber(operating_income) }})</td>
                        </tr>

                        <tr>
                          <td></td>
                          <td class="text-right"></td>
                        </tr>
                        
                        <tr>
                          <td>Other Income and Expenses</td>
                        </tr>

                        <tr>
                          <td>Interest Expense</td>
                          <td class="text-right">{{ formatNumber(interest_expense) }}</td>
                        </tr>
                        <tr>
                          <td>Earnings Before Tax (EBT)</td>
                          <td class="text-right">({{ formatNumber(earning_before_tax) }})</td>
                        </tr>
                        <tr>
                          <td>Income Tax</td>
                          <td class="text-right">{{ formatNumber(income_tax) }}</td>
                        </tr>

                        <tr>
                          <td></td>
                          <td class="text-right"></td>
                        </tr>
  
                        <!-- Net Income -->
                        <tr class="font-weight-bold">
                          <td>Net Income</td>
                          <td class="text-right">{{ formatNumber(net_income) }}</td>
                        </tr>
                      </table>
  
                      <!-- Print Button -->
                      <!-- <div class="text-right mt-3">
                        <button type="button" class="btn btn-primary" @click="printIncomeStatement">Print</button>
                      </div> -->
                    </div>
                    <!-- End of Styled Income Statement -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <ControlSidebar></ControlSidebar>
      </div>
    </body>
  </template>
  
  <script>
  import Api from '../../services/Api';
  import DatePicker from 'vue-datepicker-next';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import Navbar from '../../components/Navbar.vue';
  import Sidebar from '../../components/Sidebar.vue';
  import ContentHeader from '../../components/ContentHeader.vue';
  import Footer from '../../components/Footer.vue';
  import ControlSidebar from '../../components/ControlSidebar.vue';
  import 'vue-datepicker-next/index.css';
  
  export default {
    components: {
      DatePicker,
      PulseLoader,
      Navbar,
      Sidebar,
      ContentHeader,
      ControlSidebar,
      Footer,
    },
    data() {
      return {
        data: {
          startDate: '',
          endDate: '',
        },
        gross_revenue: 0.0,
        discount: 0.0,
        net_revenue: 0,
        cogs: 0,
        gross_profit: 0,
        expenses: 0,
        net_income: 0,
        total_expenses: 0,
        operating_income: 0,
        interest_expense: 0,
        earning_before_tax: 0,
        income_tax: 0,
        yearEndDate: '',
        isLoading: '',
        color: '#40aac7',
      };
    },
    methods: {
      onEndDateChange() {
        if (this.data.endDate) {
          this.search();
        }
      },

      onStartDateChange() {
        if (this.data.startDate) {
          this.search();
        }
      },

      async search() {
        if (!this.data.startDate || !this.data.endDate) {
          return;
        }
        this.isLoading = true;
        await Api()
          .post('income-statement', this.data)
          .then((res) => {
            if (res.status === 200) {
              this.isLoading = false;
              this.gross_revenue = res.data.grossRevenue;
              this.discount = res.data.discount;
              this.net_revenue = res.data.netRevenue;
              this.cogs = res.data.cogs;
              this.gross_profit = res.data.grossProfit;
              this.expenses = res.data.expenses;
              this.net_income = res.data.netIncome;
              this.total_expenses = res.data.totalExpenses
              this.operating_income = res.data.operatingIncome
              this.interest_expense = res.data.interestExpense
              this.net_income = res.data.netIncome
              this.yearEndDate = res.data.endedYear
              this.earning_before_tax = res.data.earningBeforeTax
            }
          })
          .catch((error) => {
            if (error) {
              this.isLoading = false;
              console.log('Failed to search income statement.', error);
            }
          });
      },

      formatNumber(value) {
        if (value < 0) {
          return `(${Math.abs(value).toLocaleString()})`; // Format with brackets for negative numbers
        }
        return value.toLocaleString(); // Format normally for positive numbers
      },

      printIncomeStatement() {
        window.print();
      },
    },
  };
  </script>
  
  <style scoped>
  @media print {
    .wrapper, .card {
      width: 100%;
      max-width: 210mm; /* A4 paper width */
      height: 297mm; /* A4 paper height */
    }
    .content-wrapper {
      margin: 0;
      padding: 0;
      padding-top: 20mm;
    }
    .income-statement {
      width: 100%;
      margin: auto;
      page-break-inside: avoid;
    }
    h5 {
      margin: 0;
      font-size: 20px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
    }
    td {
      padding: 5px;
      border: 1px solid #ddd;
      vertical-align: middle;
    }
    .text-right {
      text-align: right;
    }
  }
  </style>
  