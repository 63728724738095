<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light fixed-top bg-info">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button" style="color: #FFFFFF;">
          <i class="fas fa-bars"></i>
          
        </a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown mt-2">
        <pulse-loader 
            :loading="isLoading" 
            :color="color"
            v-if="isLoading" 
        />
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown mt-2 mr-2" v-if="currentUser.admin_type == 'Super Admin'">
        <RouterLink to="/client-lists">
          <button type="button" class="btn btn-info"><i class="fas fa-user-circle"></i> Clients</button>
        </RouterLink>
      </li>
      <li class="nav-item dropdown mt-2" v-if="currentUser.user_type != 'Normal'">
        <RouterLink to="/billing">
          <button type="button" class="btn btn-info"><i class="fas fa-credit-card"></i> Billing</button>
        </RouterLink>
      </li>
      <li class="nav-item dropdown">
          <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle" style="color: #FFFFFF"><b>{{ currentUser.full_name }}</b></a>
          <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
            <li><button type="button" class="dropdown-item" @click="logout">LogOut</button></li>
          </ul>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
</template>

<script>
import Api from '../services/Api';
import { mapActions, mapGetters } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  components: {
    PulseLoader
  },
  data(){
    return{
      isLoading: false,
      color: '#FFFFFF',
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    ...mapActions(['deleteToken','deleteUser',]),
    async logout(){
      this.isLoading = true
      await Api().post('logout')
      .then(res =>{
        if(res.status === 200){
          this.isLoading = false
          this.deleteToken();
          // this.deleteUser();
          this.$router.push('login')
        }
      }).catch(error =>{
        if(error){
          this.isLoading = false
          console.log('Failed to logout')
        }
      })
    }
  }
}
</script>

<style>

</style>