<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <content-loader v-if="isLoading"/>
                <div class="container-fluid" v-else>
                    <div class="row">
                        <div class="col-sm-6 col-md-3 col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <!-- <div class="col-md-1">
                                            <span>Account Lists</span>
                                        </div> -->
                                        <div class="col-md-12">
                                            <!-- <span class="mr-2"><b>Account Lists</b></span> -->
                                            <RouterLink to="/accounts-statement" v-if="hasPermission('account_statement')">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-satellite"></i> Account Statement</button> 
                                            </RouterLink>
                                            <RouterLink to="/new-capital" v-if="hasPermission('new_capital')">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-satellite"></i> New Capital</button> 
                                            </RouterLink>
                                            <RouterLink to="/amount-transfer" v-if="hasPermission('amount_transfer')">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-wallet"></i> Amount Transfer</button> 
                                            </RouterLink>
                                            <RouterLink to="/bank-branch-list" v-if="hasPermission('bank_branch_lists')">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-code-branch"></i> Bank Branch Lists</button> 
                                            </RouterLink>
                                            <RouterLink to="/new-bank-branch" v-if="hasPermission('new_bank_branch')">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-snowflake"></i> New Bank Branch</button> 
                                            </RouterLink>
                                            <RouterLink to="/bank-list" v-if="hasPermission('bank_lists')">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fa fa-record-vinyl"></i> Bank Lists</button> 
                                            </RouterLink>
                                            <RouterLink to="/new-bank" v-if="hasPermission('new_bank')">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-money-check"></i> New Bank</button> 
                                            </RouterLink>
                                            <RouterLink to="/new-bank-account" v-if="hasPermission('new_bank_account')">
                                                <button type="button" class="btn btn-info btn-sm" ><i class="fas fa-weight-hanging"></i> New Account</button> 
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="card-body">
                                    <div class="row mb-1">
                                        <div class="col-md-3">
                                            <input type="text" v-model="searchQuery" placeholder="Search..." class="form-control" style="height: 35px;" />
                                        </div>
                                    </div>
                                    <table id="example1" class="table table-hover table-bordered table-striped table-sm table-sm table-responsive-sm">
                                        <tr>
                                            <th>Account Name</th>
                                            <th>Account Number</th>
                                            <th>Bank</th>
                                            <th>Bank Branch</th>
                                            <th>Amount</th>
                                            <th v-if="hasPermission('edit_bank_acc')">Edit</th>
                                            <th v-if="hasPermission('delete_bank_acc')">Delete</th>
                                        </tr>
            
                                        <tbody>
                                            <tr v-for="(account, i) in displayedAccounts" :key="i">
                                                <td>{{ account.account_name }}</td>
                                                <td>{{ account.account_no }}</td>
                                                <td>{{ account.banks.bank_name }}</td>
                                                <td>{{ account.bank_branches.branch_name }}</td>
                                                <td>{{ formatNumber(account.amount) }}</td>
                                                <td v-if="hasPermission('edit_bank_acc')">
                                                    <RouterLink :to="{name: 'edit-bank-account', params: {id: account.id}}">
                                                        <button type="button" class="btn btn-info btn-sm"><i class="fa fa-edit"></i></button>
                                                    </RouterLink>
                                                </td>
                                                <td v-if="hasPermission('delete_bank_acc')">
                                                    <button type="button" class="btn btn-danger btn-sm" @click="deleteConfirm(account.id, i)"><i class="fa fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Pagination 
                                        :currentPage="currentPage" 
                                        :totalPages="totalPages" 
                                        @page-change="handlePageChange" 
                                    />

                                    <div class="row justify-content-center">
                                        <div class="col-md-6">
                                            <div class="alert alert-light" role="alert">
                                                <span>Follow procedures for creating an accounts.</span>
                                                <li>Register new bank. Eg CRDB, NMB etc</li>
                                                <li>Register new bank branch. Eg Mlimani City.</li>
                                                <li>Register new account.</li>
                                                <li>Add new capital to the account.</li>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>

</template>

<script>
import Api from '../../services/Api';
import ContentLoader from '../../components/ContentLoader.vue'
import Swal from 'sweetalert2';
import store from '../../store/store';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";
import Pagination from '../../components/Pagination.vue';

export default{
    components: {
        ContentLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer,
        Pagination,
    },
    data(){
        return{
            data: {
                userId: store.state.user.id,
            },
            isLoading: '',
            accounts: [],
            permissions: [],
            color: '#40aac7',
            displayedAccounts: [],
            searchQuery: '',
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
        }
    },

    mounted(){
        this.fetchAccounts();
        // this.fetchPermissions();
    },

    methods: {
        async fetchAccounts(){
            this.isLoading = true
            await Api().get('accounts')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.accounts = res.data
                    this.updatePaginatedData();
                }
            }).catch(error =>{
                if(error){
                    console.log('Failed to get users')
                }
            })
        },

        updatePaginatedData() {
                const filteredProducts = this.accounts.filter(account => 
                    account.account_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                    account.account_no.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                    account.banks.bank_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                    account.bank_branches.branch_name.toLowerCase().includes(this.searchQuery.toLowerCase())
                );
                const start = (this.currentPage - 1) * this.perPage;
                const end = start + this.perPage;
                this.displayedAccounts = filteredProducts.slice(start, end);
                this.totalPages = Math.ceil(filteredProducts.length / this.perPage);
            },

            handlePageChange(page) {
                this.currentPage = page;
                this.updatePaginatedData();
            },

        deleteConfirm(id, index){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true
            }).then((result) => {
            if (result.isConfirmed) {
                this.deleteAccount(id, index);
                swalWithBootstrapButtons.fire({
                title: "Deleted!",
                text: "Account has been deleted.",
                icon: "success"
                });
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire({
                title: "Cancelled",
                text: "Your account is safe",
                icon: "error"
                });
            }
            });
        },

        async deleteAccount(id, index){
            this.isLoading = true
            await Api().delete(`accounts/${id}`)
            .then(res =>{
                try{
                    if(res.status === 200){
                        this.isLoading = false
                        this.displayedAccounts.splice(index, 1)
                    }
                }catch(error){
                    console.log(error)
                }
            })
        },

        formatNumber(value) {
            if (!value && value !== 0) return ''; // Handle empty or null values
            return new Intl.NumberFormat().format(value); // Format with commas
        },

        hasPermission(permissionKey) {
            return store.state.permissions.some(permission => permission.key === permissionKey && permission.value);
        }
    },

    watch: {
        searchQuery: 'updatePaginatedData',
        currentPage: 'updatePaginatedData'
    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>