<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 5%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-md-10">
                                    
                                </div>
                                <div class="col-md-2">
                                    <RouterLink to="/salary-slip-list">
                                        <button type="button" class="btn btn-info btn-sm">Back</button>
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                        <pulse-loader 
                            :loading="isLoading" 
                            :color="color"
                            v-if="isLoading" 
                            style="margin-top: 50px; margin-left: 40%;"
                        />
                        <div class="card-body" id="print-content" v-else>
                            <div class="row">
                                <div class="col-md-3">
                                    <img :src="company_data.logo_name" alt="logo" width="100" /><br>
                                </div>
                                <div class="col-md-6">
                                    <span style="font-weight: 600; font-size: 25px; text-decoration: underline;">PAY SLIP FOR THE MONTH OF {{ employee_data.month.toUpperCase() }} {{ formattedDate }}</span>
                                </div>

                                <div class="col-md-3">
                                    <span style="font-weight: 600;">{{ company_data.business_name.toUpperCase() }}</span><br>
                                    <span style="font-weight: 600;">{{ company_data.client_address }}, {{ company_data.region }}</span><br>
                                    <span style="font-weight: 600;">{{ company_data.client_email }}</span><br>
                                    <div style="margin-top: 10px;">
                                        <span style="font-size: 24px; font-weight: 600">PAYSLIP #{{ employee_data.payslipId }}</span><br>
                                        <span style="font-weight: 600;">Salary Month: {{ employee_data.month.toUpperCase() }} {{ formattedDate }}</span>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="row">
                                <div class="col-md-9">
                                    <span style="font-weight: 600;">{{ employee_data.employee_name.toUpperCase() }}</span><br>
                                    <span style="font-weight: 600;">{{ employee_data.designation }}</span><br>
                                    <span style="font-weight: 600;">Employee ID: {{ employee_data.employeeId }}</span><br>
                                    <span style="font-weight: 600;">Joining Date: {{ joinDate }}</span><br>
                                </div>  
                            </div>

                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <span><b>Earnings</b></span>
                                    <table class="table table-bordered table-hover table-sm">
                                        <tr>
                                            <td style="font-weight: 600; font-size: 16px;">Basic Salary</td>
                                            <td style="font-weight: 600; font-size: 16px;" align="center">{{ formatNumber(employee_data.basic_salary) }}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-weight: 600; font-size: 16px;">Allowances</td>
                                            <td style="font-weight: 600; font-size: 16px;" align="center">{{ formatNumber(employee_data.allowances) }}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-weight: 600;">Total Earnings</td>
                                            <td style="font-weight: 600;" align="center">{{ formatNumber(employee_data.total_earnings) }}</td>
                                        </tr>
                                    </table>
                                    <span><b>Net Salary: {{ formatNumber(employee_data.net_salary) }}</b></span>
                                </div>

                                <div class="col-md-6">
                                    <span><b>Deductions</b></span>
                                    <table class="table table-bordered table-hover table-sm">
                                        <tr>
                                            <td style="font-weight: 600; font-size: 16px;">Paye</td>
                                            <td style="font-weight: 600; font-size: 16px;" align="center">{{ formatNumber(employee_data.paye) }}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-weight: 600; font-size: 16px;">Nssf</td>
                                            <td style="font-weight: 600; font-size: 16px;" align="center">{{ formatNumber(employee_data.nssf) }}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-weight: 600; font-size: 16px;">Wcf</td>
                                            <td style="font-weight: 600; font-size: 16px;" align="center">{{ formatNumber(employee_data.wcf) }}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-weight: 600; font-size: 16px;">Other Deductions</td>
                                            <td style="font-weight: 600; font-size: 16px;" align="center">{{ formatNumber(employee_data.other_deduction) }}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-weight: 600;">Total Deductions</td>
                                            <td style="font-weight: 600;" align="center">{{ formatNumber(employee_data.total_deductions) }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-4" v-if="isPrinting">
                                    <button type="button" disabled class="btn btn-info btn-sm hide-on-pdf" @click="printPdf"><i class="fas fa-print"></i> Print</button>
                                </div>
                                <div class="col-md-4" v-else>
                                    <button type="button" class="btn btn-info btn-sm hide-on-pdf" @click="printPdf"><i class="fas fa-print"></i> Print</button>
                                </div>
                                <pulse-loader 
                                    :loading="isPrinting" 
                                    :color="color"
                                    v-if="isPrinting" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";
import Api from "../../services/Api";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default{
    components: {
        Navbar,
        Sidebar,
        ContentHeader,
        Footer,
        ControlSidebar,
        PulseLoader,
    },

    data(){
        return{
            company_data: {
                business_name: '',
                logo_name: '',
                primary_color: '',
                secondary_color: '',
                mobile_no: '',
                client_address: '',
                region: '',
                website: '',
                tin_no: '',
                client_email: '',
            },

            employee_data: {
                payrollId: this.$route.params.id,
                employee_name: '',
                designation: '',
                employeeId: '',
                joined_date: '',
                date: '',
                month: '',
                basic_salary: 0,
                allowances: 0,
                total_earnings: 0,
                net_salary: 0,
                paye: 0,
                nssf: 0,
                wcf: 0,
                other_deduction: 0,
                total_deductions: 0,
                payslipId: 0,
            },

            color: '#40aac7',
            isLoading: false,
            isPrinting: false,
        }
    },

    mounted(){
        this.fetchCompanyInfo();
        this.fetchPaySlipInfo();
    },

    computed: {
        formattedDate() {
            const parsedDate = Date.parse(this.employee_data.date);
            if (isNaN(parsedDate)) {
                return 'Invalid Date';
            }
            const date = new Date(parsedDate);
            // const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date).toUpperCase();
            const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
            return `${year}`;
        },

        joinDate() {
            const parsedDate = Date.parse(this.employee_data.joined_date);
            if (isNaN(parsedDate)) {
                return 'Invalid Date';
            }

            const date = new Date(parsedDate); 
            const day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);
            const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
            const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
            return `${day} ${month} ${year}`;
        },
    },

    methods: {
        async fetchCompanyInfo(){
            this.isLoading = true
            await Api().get('get-company-info')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.company_data.primary_color = res.data.client.primary_color
                    this.company_data.secondary_color = res.data.client.secondary_color
                    this.company_data.logo_name = res.data.client.logo_name
                    this.company_data.mobile_no = res.data.client.mobile_no
                    this.company_data.business_name = res.data.client.business_name
                    this.company_data.client_address = res.data.client.address
                    this.company_data.region = res.data.client.region
                    this.company_data.website = res.data.client.website
                    this.company_data.tin_no = res.data.client.tin_no
                    this.company_data.client_email = res.data.client.email
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to get company info',error);
                }
            })
        },

        async fetchPaySlipInfo(){
            this.isLoading = true
            await Api().get(`view-pay-slip/${this.employee_data.payrollId}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.employee_data.employee_name = res.data.payrolls.first_name+' '+res.data.payrolls.middle_name+' '+res.data.payrolls.last_name
                    this.employee_data.designation = res.data.payrolls.employees.designation
                    this.employee_data.employeeId = res.data.payrolls.employees.staffId
                    this.employee_data.joined_date = res.data.payrolls.employees.contract_start_date
                    this.employee_data.date = res.data.payrolls.date
                    this.employee_data.month = res.data.payrolls.payrolls.month
                    this.employee_data.basic_salary = res.data.payrolls.basic_salary
                    this.employee_data.allowances = res.data.payrolls.allowance
                    this.employee_data.net_salary = res.data.payrolls.net_pay
                    this.employee_data.paye = res.data.payrolls.paye_tax
                    this.employee_data.nssf = res.data.payrolls.nssf
                    this.employee_data.wcf = res.data.payrolls.wcf
                    this.employee_data.other_deduction = res.data.payrolls.other_deductions
                    this.employee_data.total_deductions = res.data.payrolls.total_deductions
                    this.employee_data.payslipId = res.data.payrolls.payrolls.id
                    this.employee_data.total_earnings = res.data.total_earnings
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to fetch pay slip data.',error)
                }
            })
        },

        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        },

        async printPdf(){
            this.isPrinting = true
            // Hide buttons
            const buttons = document.querySelectorAll('.hide-on-pdf');
            buttons.forEach(button => button.style.display = 'none');

            const element = document.getElementById('print-content');
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/png');
            
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt',
                format: 'a4'
            });

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      
            // Convert the PDF to a Blob to create a URL for preview
            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new window or tab for preview
            const pdfWindow = window.open(pdfUrl, '_blank');

            if (pdfWindow) {
                // Automatically print the PDF after opening the preview
                pdfWindow.addEventListener('load', () => {
                    this.isPrinting = false
                // pdfWindow.print();
                });
            } else {
                this.isPrinting = false
                // If the window couldn't be opened (e.g., due to pop-up blockers)
                alert('Please allow pop-ups to preview and print the PDF.');
            }

            // Show buttons again
            buttons.forEach(button => button.style.display = 'inline-block');
            this.isPrinting = false
            
        },
    }
}
</script>