<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 4%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <content-loader v-if="isLoading"/>
                <div class="container-fluid" v-else>
                    <div class="row">
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body bg-info">
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <span style="font-size: 20px;">Welcome Back, <b>{{ currentUser.full_name }}</b></span>
                                                </div>
                                                <div class="col-md-2">
                                                    <img src="../../assets/icon.png" width="50" class="rounded-circle"/>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-md-6">
                                                    <RouterLink to="/profile-self">
                                                        <button type="button" class="btn btn-default">View Profile</button>
                                                    </RouterLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-6">
                                            <div class="small-box bg-info" style="background-color: #171a3b !important">
                                            <div class="inner">
                                                <h3>{{ pay_slip_count }}</h3>
                                                <p>Pay Slip</p>
                                            </div>
                                            <div class="icon">
                                                <i class="ion ion-bag"></i>
                                            </div>
                                            <RouterLink class="small-box-footer" to="/self-services-payslip">
                                                View Pay Slip <i class="fas fa-arrow-circle-right"></i>
                                            </RouterLink>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-6">
                                            <div class="small-box bg-success">
                                            <div class="inner">
                                                <h3>{{ total_invoices }}</h3>
                                                <p>Invoices</p>
                                            </div>
                                            <div class="icon">
                                                <i class="ion ion-pie-graph"></i>
                                            </div>
                                                <RouterLink to="/invoices-list" class="small-box-footer">
                                                    More info <i class="fas fa-arrow-circle-right"></i>
                                                </RouterLink>
                                                <!-- <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> -->
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="info-box">
                                                <span class="info-box-icon bg-info"><i class="fas fa-wallet"></i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Pending Leaves</span>
                                                    <span class="info-box-number">{{ pending_leave_count }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="info-box">
                                                <span class="info-box-icon bg-warning"><i class="fas fa-weight-hanging" style="color: #FFFFFF"></i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Approved Leaves</span>
                                                    <span class="info-box-number">{{ approved_leave_count }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-7">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <span style="font-size: 20px; font-weight: bold;">{{ !request_leave ? 'Leaves' : 'Request Leave' }}</span>
                                        </div>
                                        <div class="col-md-3 mb-2" v-if="!request_leave">
                                            <button type="button" @click="showRequestLeave" class="btn btn-info btn-sm">Request Leave</button>
                                        </div>
                                        <div class="col-md-3 mb-2" v-else>
                                            <button type="button" @click="cancelRequestLeave" class="btn btn-danger btn-sm">Cancel</button>
                                        </div>
                                    </div><hr>

                                    <div class="row" v-if="request_leave">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <select class="form-control" v-model="data.leave_type">
                                                            <option>Select Leave Type</option>
                                                            <option v-for="(leave, i) in leaveTypes" :key="i" :value="leave.id">{{ leave.name }}</option>
                                                        </select>
                                                        <div v-if="errors.leave_type">
                                                            <span class="text-danger">{{ errors.leave_type[0] }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <date-picker v-model:value="data.fromDate" 
                                                            value-type="YYYY-MM-DD" 
                                                            placeholder="From"
                                                        />
                                                        <div v-if="errors.fromDate">
                                                            <span class="text-danger">{{ errors.fromDate[0] }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <date-picker v-model:value="data.toDate" 
                                                            value-type="YYYY-MM-DD" 
                                                            placeholder="To"
                                                        />
                                                        <div v-if="errors.toDate">
                                                            <span class="text-danger">{{ errors.toDate[0] }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <textarea class="form-control" v-model="data.reason" placeholder="Reason..."></textarea>
                                                        <div v-if="errors.reason">
                                                            <span class="text-danger">{{ errors.reason[0] }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12" v-if="error">
                                                    <div class="alert alert-danger" role="alert">
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-4" v-if="isSubmitingLeave">
                                                    <button type="button" disabled class="btn btn-info btn-sm">Please wait...</button>
                                                </div>
                                                <div class="col-md-4" v-else>
                                                    <button type="button" @click="submitLeave" class="btn btn-info btn-sm">Submit</button>
                                                </div>
                                                <div class="col-md-6">
                                                    <pulse-loader 
                                                        :loading="isSubmitingLeave" 
                                                        :color="color"
                                                        v-if="isSubmitingLeave" 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row" v-else>
                                        <div class="col-md-6" v-for="(leave, i) in leaveBalance" :key="i">
                                            <div class="info-box">
                                                <span class="info-box-icon" :class="getLeaveTypeClass(leave.leave_types.name)">
                                                    <i :class="getLeaveTypeIcon(leave.leave_types.name)"></i>
                                                </span>
                                                <div class="info-box-content">
                                                    <div class="row">
                                                        <div class="col-md-6" style="border-right: 1px solid #c8c8c8;"> <!-- Add border-right here -->
                                                            <span class="info-box-text">{{ leave.leave_types.name }}</span>
                                                            <span class="info-box-number">{{ leave.allowed_days }} Days</span>
                                                        </div>
                                                        <div class="col-md-3" style="border-right: 1px solid #c8c8c8;">
                                                            <span class="info-box-text">Taken</span>
                                                            <span class="info-box-number">{{ leave.taken_days }}</span>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <span class="info-box-text">Avail</span>
                                                            <span class="info-box-number">{{ leave.remain_days }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                            <div class="row mb-1">
                                                <div class="col-md-3">
                                                    <input type="text" v-model="searchQuery" placeholder="Search..." class="form-control" style="height: 35px;" />
                                                </div>
                                            </div>
                                        <table id="example1" class="table table-hover table-bordered table-striped table-sm table-sm table-responsive-sm">
                                            <tr>
                                                <th>Leave Type</th>
                                                <th>Employee Name</th>
                                                <th>From</th>
                                                <th>To</th>
                                                <th>Days Requested</th>
                                                <th>Reason</th>
                                                <th>Requested By</th>
                                                <th>Status</th>
                                                <th>Approved By</th>
                                                <th>Action</th>
                                            </tr>
    
                                            <tbody>
                                                <tr v-for="(leave, i) in displayedLeaves" :key="i">
                                                    <td>{{ leave.leave_types.name }}</td>
                                                    <td>{{ leave.requestedBy }}</td>
                                                    <td>{{ leave.fromDate }}</td>
                                                    <td>{{ leave.toDate }}</td>
                                                    <td align="center">{{ leave.days_requested }}</td>
                                                    <td>{{ leave.reason }}</td>
                                                    <td>{{ leave.requestedBy }}</td>
                                                    <td>
                                                        <span v-if="leave.status === 'Approved'" class="badge badge-success">{{ leave.status }}</span>
                                                        <span v-else class="badge badge-danger">{{ leave.status }}</span>
                                                    </td>
                                                    <td>{{ leave.approved_by }}</td>
                                                    <td>
                                                        <button v-if="leave.status === 'Pending'" @click="deleteConfirm(leave.id, i)" type="button" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <Pagination 
                                            :currentPage="currentPage" 
                                            :totalPages="totalPages" 
                                            @page-change="handlePageChange" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import ContentLoader from '../../components/ContentLoader.vue'
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";
import { mapGetters } from "vuex";
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import store from '../../store/store';
import Pagination from '../../components/Pagination.vue';

export default{
    components: {
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer,
        ContentLoader,
        DatePicker,
        Pagination,
    },

    data(){
        return{
            data: {
               fromDate: '',
               toDate: '',
               leave_type: '',
               userId: store.state.user.id,
               reason: '',
            },

            total_invoices: 0,
            month_invoices: 0,
            total_purchases: 0,
            month_purchases: 0,

            isLoading: false,
            isSubmitingLeave: false,
            errors: '',
            error: '',
            color: '#40aac7',
            pay_slip_count: 0,
            request_leave: false,
            leaveBalance: [],
            leaveTypes: [],
            get_leaves: [],
            pending_leave_count: 0,
            approved_leave_count: 0,
            
            displayedLeaves: [],
            searchQuery: '',
            currentPage: 1,
            perPage: 4,
            totalPages: 0,

            leaveTypeIcons: {
                "Annual Leave": {
                    icon: "fas fa-calendar-alt",
                    bgColor: "bg-primary"
                },
                "Sick Leave (Full Pay)": {
                    icon: "fas fa-medkit",
                    bgColor: "bg-danger"
                },
                "Sick Leave (Half Pay)": {
                    icon: "fas fa-medkit",
                    bgColor: "bg-warning"
                },
                "Maternity Leave": {
                    icon: "fas fa-baby",
                    bgColor: "bg-success"
                },
                "Maternity Leave (Multiple Births)": {
                    icon: "fas fa-baby",
                    bgColor: "bg-info"
                },
                "Paternity Leave": {
                    icon: "fas fa-male",
                    bgColor: "bg-warning"
                },
                "Compassionate Leave": {
                    icon: "fas fa-heart",
                    bgColor: "bg-info"
                }
            }
        }
    },

    computed: {
        ...mapGetters(['currentUser'])
    },

    mounted(){
        this.paySlipCount();
        this.getLeaveBalances();
        this.getLeaveType();
        this.getLeave();
        this.fetTotalInvoices();
    },  

    methods: {
        async paySlipCount(){
            this.isLoading = true
            await Api().get('payslip-self-service-count')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.pay_slip_count = res.data
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to fetch payslip count.', error)
                }
            })
        },

        async getLeaveBalances(){
            this.isLoading = true
            await Api().get('get-leave_balance')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.leaveBalance = res.data
                }
            }).then(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to get leave types.',error);
                }
            })
        },

        async getLeaveType(){
            this.isLoading = true
            await Api().get('get-leave_types')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.leaveTypes = res.data
                }
            }).then(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to get leave types.',error);
                }
            })
        },

        getLeaveTypeIcon(leaveTypeName) {
            return this.leaveTypeIcons[leaveTypeName]?.icon || 'fas fa-question-circle';
        },

        getLeaveTypeClass(leaveTypeName) {
            return this.leaveTypeIcons[leaveTypeName]?.bgColor || 'bg-secondary';
        },

        showRequestLeave(){
            this.request_leave = true
        },

        cancelRequestLeave(){
            this.request_leave = false
            this.data = {}
        },

        async submitLeave(){
            this.isSubmitingLeave = true
            await Api().post('store-leave-request',this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isSubmitingLeave = false
                    this.request_leave = false
                    this.data = {}
                    Swal.fire({
                        title: "Successfully!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });
                }else if(res.status === 201){
                    this.isSubmitingLeave = false
                    this.error = res.data.error
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isSubmitingLeave = false
                    this.errors = error.response.data.errors
                }
            })
        },

        async getLeave(){
            this.isLoading = true
            await Api().get('get-leaves')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.get_leaves = res.data.leave_requests
                    this.pending_leave_count = res.data.pending_leave_counts
                    this.approved_leave_count = res.data.approved_leave_counts
                    this.updatePaginatedData();
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to get leaves.',error)
                }
            })
        },

        updatePaginatedData() {
            const filteredProducts = this.get_leaves.filter(leave => 
                leave.leave_types.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                leave.fromDate.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                leave.toDate.toLowerCase().includes(this.searchQuery.toLowerCase()) 
            );
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            this.displayedLeaves = filteredProducts.slice(start, end);
            this.totalPages = Math.ceil(filteredProducts.length / this.perPage);
        },

        handlePageChange(page) {
            this.currentPage = page;
            this.updatePaginatedData();
        },

        async deleteLeaveRequest(id, index){
            this.isLoading = true
            await Api().delete(`delete-reave-requests/${id}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.displayedLeaves.splice(index, 1)
                }
            })
        },

        deleteConfirm(id, index){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true
            }).then((result) => {
            if (result.isConfirmed) {
                this.deleteLeaveRequest(id, index);
                swalWithBootstrapButtons.fire({
                title: "Deleted!",
                text: "Leave request has been deleted.",
                icon: "success"
                });
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire({
                title: "Cancelled",
                text: "Your Leave request is safe",
                icon: "error"
                });
            }
            });
        },

        async fetTotalInvoices(){
         this.isLoading = true
         await Api().get('dashboard')
         .then(res =>{
            if(res.status === 200){
                this.isLoading = false
                this.total_invoices = res.data.invoices_count
                this.month_invoices = res.data.month_invoices
                this.month_purchases = res.data.month_purchases
                this.total_purchases = res.data.total_purchases
            }
         }).catch(error =>{
            if(error){
                this.isLoading = false
                console.log('Failed to fetch total invoices.',error)
            }
         })
       },
    },

    watch: {
        searchQuery: 'updatePaginatedData',
        currentPage: 'updatePaginatedData'
    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>