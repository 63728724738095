import { createRouter,createWebHistory } from "vue-router";
import Login from "../pages/Auth/Login.vue";
import Dashboard from "../pages/dasboard/Dashboard.vue";
import store from "../store/store";
import UserLists from "../pages/users/UserLists.vue";
import EditUser from "../pages/users/EditUser.vue";
import NewUser from "../pages/users/NewUser.vue";
import EmployeeLists from "../pages/human_resourse/EmployeeLists.vue";
import NewEmployee from "../pages/human_resourse/NewEmployee.vue";
import EditEmployee from "../pages/human_resourse/EditEmployee.vue";
import PayrollLists from "../pages/payroll/PayrollLists.vue";
import PreparePayroll from "../pages/payroll/PreparePayroll.vue";
import ViewPayroll from "../pages/payroll/ViewPayroll.vue";
import PendingPayroll from "../pages/payroll/PendingPayroll.vue";
import ViewPendingPayroll from "../pages/payroll/ViewPendingPayroll.vue";
import RejectedPayroll from "../pages/payroll/RejectedPayroll.vue";
import ViewRejectedPayroll from "../pages/payroll/ViewRejectedPayroll.vue";
import ApprovedPayroll from "../pages/payroll/ApprovedPayroll.vue";
import ViewApprovedPayroll from "../pages/payroll/ViewApprovedPayroll.vue";
import Sdl from "../pages/payroll/Sdl.vue";
import Nssf from "../pages/payroll/Nssf.vue";
import Wcf from "../pages/payroll/Wcf.vue";
import NetPay from "../pages/payroll/NetPay.vue";
import Paye from "../pages/payroll/Paye.vue";
import CustomersList from "../pages/customers/CustomersList.vue";
import NewCustomer from "../pages/customers/NewCustomer.vue";
import EditCustomer from "../pages/customers/EditCustomer.vue";
import NewSupplier from "../pages/suppliers/NewSupplier.vue";
import SupplierList from "../pages/suppliers/SupplierList.vue";
import EditSupplier from "../pages/suppliers/EditSupplier.vue";
import ProductsList from "../pages/products/ProductsList.vue";
import CategoriesList from "../pages/categories/CategoriesList.vue";
import NewCategory from "../pages/categories/NewCategory.vue";
import EditCategory from "../pages/categories/EditCategory.vue";
import NewProduct from "../pages/products/NewProduct.vue";
import EditProduct from "../pages/products/EditProduct.vue";
import SubProductsList from "../pages/products/SubProductsList.vue";
import NewSubProduct from "../pages/products/NewSubProduct.vue";
import EditSubProduct from "../pages/products/EditSubProduct.vue";
import PurchasesOrderList from "../pages/purchases/PurchasesOrderList.vue";
import NewPurchasesOrder from "../pages/purchases/NewPurchasesOrder.vue";
import ViewPurchases from "../pages/purchases/ViewPurchases.vue";
import NewSales from "../pages/sales/NewSales.vue";
import InvoicesList from "../pages/sales/InvoicesList.vue";
import ViewInvoice from "../pages/sales/ViewInvoice.vue";
import ProfomaLists from "../pages/sales/ProfomaLists.vue";
import ViewProfoma from "../pages/sales/ViewProfoma.vue";
import StockLists from "../pages/stock_management/StockLists.vue";
import EditStock from "../pages/stock_management/EditStock.vue";
import RequestedExpensesList from "../pages/expenses/RequestedExpensesList.vue";
import ExpensesCategoryList from "../pages/expenses/ExpensesCategoryList.vue";
import NewExpensesCategory from "../pages/expenses/NewExpensesCategory.vue";
import EditExpensesCategory from "../pages/expenses/EditExpensesCategory.vue";
import RegisterExpenses from "../pages/expenses/RegisterExpenses.vue";
import ExpensesList from "../pages/expenses/ExpensesList.vue";
import EditExpenses from "../pages/expenses/EditExpenses.vue";
import RequestExpenses from "../pages/expenses/RequestExpenses.vue";
import EditRequestedExpenses from "../pages/expenses/EditRequestedExpenses.vue";
import ExpensesReport from "../pages/expenses/ExpensesReport.vue";
import SalesReport from "../pages/sales/SalesReport.vue";
import AccountLists from "../pages/accounting/AccountLists.vue";
import BankLists from "../pages/accounting/BankLists.vue";
import NewBank from "../pages/accounting/NewBank.vue";
import EditBank from "../pages/accounting/EditBank.vue";
import BankBranchLists from "../pages/accounting/BankBranchLists.vue";
import NewBankBranch from "../pages/accounting/NewBankBranch.vue";
import EditBankBranch from "../pages/accounting/EditBankBranch.vue";
import NewBankAccount from "../pages/accounting/NewBankAccount.vue";
import EditBankAccount from "../pages/accounting/EditBankAccount.vue";
import AmountTransfer from "../pages/accounting/AmountTransfer.vue";
import NewCapital from "../pages/accounting/NewCapital.vue";
import AccountStatements from "../pages/accounting/AccountStatements.vue";
import ViewExpensesReq from "../pages/expenses/ViewExpensesReq.vue";
import Setting from "../pages/settings/Setting.vue";
import CompanyInfo from "../pages/company_info/CompanyInfo.vue";
import Billing from "../pages/billing/ClientBilling.vue";
import ForgetResetPassword from "../pages/Auth/ForgetResetPassword.vue";
import RegisterClient from "../pages/Auth/RegisterClient.vue";
import PasswordReset from "../pages/Auth/PasswordReset.vue";
import UploadStock from "../pages/products/UploadStock.vue";
import ImportEmployees from "../pages/human_resourse/ImportEmployees.vue";
import DpoSuccess from '../pages/billing/DpoSuccess.vue';
import ClientLists from "../pages/clients/ClientLists.vue";
import UpdateClientPayments from "../pages/clients/UpdateClientPayments.vue";
import SalarySlipList from "../pages/human_resourse/SalarySlipList.vue";
import ViewSalarySlip from "../pages/human_resourse/ViewSalarySlip.vue";
import SelfServices from "../pages/self_services/SelfServices.vue";
import SelfServicePaySlip from "../pages/self_services/SelfServicePaySlip.vue";
import ViewPayslipSelf from "../pages/self_services/ViewPayslipSelf.vue";
import ProfileSelf from "../pages/self_services/ProfileSelf.vue";
import HrDashboard from "../pages/human_resourse/HrDashboard.vue";
import PendingLeaves from "../pages/human_resourse/PendingLeaves.vue";
import ViewPendingLeaveRequests from "../pages/human_resourse/ViewPendingLeaveRequests.vue";
import ApprovedLeave from "../pages/human_resourse/ApprovedLeave.vue";
import ViewApprovedLeaveRequest from "../pages/human_resourse/ViewApprovedLeaveRequest.vue";
import LeaveLists from "../pages/human_resourse/LeaveLists.vue";
import EditLeaveType from "../pages/human_resourse/EditLeaveType.vue";
import IncomeStatement from "../pages/reports/IncomeStatement.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
      meta: {requiresAuth: true}
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/users-list',
      name: 'users-list',
      component: UserLists,
      meta: {requiresAuth: true}
    },
    {
      path: '/edit-user/:id',
      name: 'edit-user',
      component: EditUser,
      meta: {requiresAuth: true}
    },
    {
      path: '/new-user',
      name: 'new-user',
      component: NewUser,
      meta: {requiresAuth: true}
    },
    {
      path: '/employee-list',
      name: 'employee-list',
      component: EmployeeLists,
      meta: {requiresAuth: true}
    },
    {
      path: '/new-employee',
      name: 'new-employee',
      component: NewEmployee,
      meta: {requiresAuth: true}
    },
    {
      path: '/edit-employee/:id',
      name: 'edit-employee',
      component: EditEmployee,
      meta: {requiresAuth: true}
    },
    {
      path: '/payroll-list',
      name: 'payroll-list',
      component: PayrollLists,
      meta: {requiresAuth: true}
    },
    {
      path: '/prepare-payroll',
      name: 'prepare-payroll',
      component: PreparePayroll,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-payroll/:id',
      name: 'view-payroll',
      component: ViewPayroll,
      meta: {requiresAuth: true}
    },
    {
      path: '/pending-payroll',
      name: 'pending-payroll',
      component: PendingPayroll,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-pending-payroll/:id',
      name: 'view-pending-payroll',
      component: ViewPendingPayroll,
      meta: {requiresAuth: true}
    },
    {
      path: '/rejected-payroll',
      name: 'rejected-payroll',
      component: RejectedPayroll,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-rejected-payroll/:id',
      name: 'view-rejected-payroll',
      component: ViewRejectedPayroll,
      meta: {requiresAuth: true}
    },
    {
      path: '/aproved-payroll',
      name: 'approved-payroll',
      component: ApprovedPayroll,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-approved-payroll/:id',
      name: 'view-approved-payroll',
      component: ViewApprovedPayroll,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-sdl/:id',
      name: 'view-sdl',
      component: Sdl,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-nssf/:id',
      name: 'view-nssf',
      component: Nssf,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-wcf/:id',
      name: 'view-wcf',
      component: Wcf,
      meta: {requiresAuth: true}
    },
    {
      path: '/net-pay/:id',
      name: 'net-pay',
      component: NetPay,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-paye/:id',
      name: 'view-paye',
      component: Paye,
      meta: {requiresAuth: true}
    },
    {
      path: '/customers-list',
      name: 'customers-list',
      component: CustomersList,
      meta: {requiresAuth: true}
    },
    {
      path: '/new-customer',
      name: 'new-customer',
      component: NewCustomer,
      meta: {requiresAuth: true}
    },
    {
      path: '/edit-customer/:id',
      name: 'edit-customer',
      component: EditCustomer,
      meta: {requiresAuth: true}
    },
    {
      path: '/new-supplier',
      name: 'new-supplier',
      component: NewSupplier,
      meta: {requiresAuth: true}
    },
    {
      path: '/supplier-list',
      name: 'supplier-list',
      component: SupplierList,
      meta: {requiresAuth: true}
    },
    {
      path: '/edit-supplier/:id',
      name: 'edit-supplier',
      component: EditSupplier,
      meta: {requiresAuth: true}
    },
    {
      path: '/products-list',
      name: 'products-list',
      component: ProductsList,
      meta: {requiresAuth: true}
    },
    {
      path: '/categories-list',
      name: 'categories-list',
      component: CategoriesList,
      meta: {requiresAuth: true}
    },
    {
      path: '/new-product-category',
      name: 'new-product-category',
      component: NewCategory,
      meta: {requiresAuth: true}
    },
    {
      path: '/edit-category/:id',
      name: 'edit-category',
      component: EditCategory,
      meta: {requiresAuth: true}
    },
    {
      path: '/new-product',
      name: 'new-product',
      component: NewProduct,
      meta: {requiresAuth: true}
    },
    {
      path: '/edit-product/:id',
      name: 'edit-product',
      component: EditProduct,
      meta: {requiresAuth: true}
    },
    {
      path: '/sub-product-list',
      name: 'sub-product-list',
      component: SubProductsList,
      meta: {requiresAuth: true}
    },
    {
      path: '/new-sub-product',
      name: 'new-sub-product',
      component: NewSubProduct,
      meta: {requiresAuth: true}
    },
    {
      path: '/edit-sub-product/:id',
      name: 'edit-sub-product',
      component: EditSubProduct,
      meta: {requiresAuth: true}
    },
    {
      path: '/purchases-order-list',
      name: 'purchases-order-list',
      component: PurchasesOrderList,
      meta: {requiresAuth: true}
    },
    {
      path: '/new-purchases-order',
      name: 'new-purchases-order',
      component: NewPurchasesOrder,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-purchasing-order/:id',
      name: 'view-purchasing-order',
      component: ViewPurchases,
      meta: {requiresAuth: true}
    },
    {
      path: '/new-sales',
      name: 'new-sales',
      component: NewSales,
      meta: {requiresAuth: true}
    },
    {
      path: '/invoices-list',
      name: 'invoices-list',
      component: InvoicesList,
      meta: {requiresAuth: true}
    },
    {
      path: '/profoma-lists',
      name: 'profoma-lists',
      component: ProfomaLists,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-invoice/:id',
      name: 'view-invoice',
      component: ViewInvoice,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-profoma/:id',
      name: 'view-profoma',
      component: ViewProfoma,
      meta: {requiresAuth: true}
    },
    {
      path: '/stock-list',
      name: 'stock-list',
      component: StockLists,
      meta: {requiresAuth: true}
    },
    {
      path: '/edit-stock/:id',
      name: 'edit-stock',
      component: EditStock,
      meta: {requiresAuth: true}
    },
    {
      path: '/requested-expenses-list',
      name: 'requested-expenses-list',
      component: RequestedExpensesList,
      meta: {requiresAuth: true}
    },
    {
      path: '/expenses-category-list',
      name: 'expenses-category-list',
      component: ExpensesCategoryList,
      meta: {requiresAuth: true}
    },
    {
      path: '/new-category',
      name: 'new-category',
      component: NewExpensesCategory,
      meta: {requiresAuth: true}
    },
    {
      path: '/edit-category/:id',
      name: 'edit-category',
      component: EditExpensesCategory,
      meta: {requiresAuth: true}
    },
    {
      path: '/new-expenses',
      name: 'new-expenses',
      component: RegisterExpenses,
      meta: {requiresAuth: true}
    },
    {
      path: '/expenses-list',
      name: 'expenses-list',
      component: ExpensesList,
      meta: {requiresAuth: true}
    },
    {
      path: '/edit-expenses/:id',
      name: 'edit-expenses',
      component: EditExpenses,
      meta: {requiresAuth: true}
    },
    {
      path: '/request-expenses',
      name: 'request-expenses',
      component: RequestExpenses,
      meta: {requiresAuth: true}
    },
    {
      path: '/edit-requestes-expense/:id',
      name: 'edit-requestes-expense',
      component: EditRequestedExpenses,
      meta: {requiresAuth: true}
    },
    {
      path: '/expenses-report',
      name: 'expenses-report',
      component: ExpensesReport,
      meta: {requiresAuth: true}
    },
    {
      path: '/sales-report',
      name: 'sales-report',
      component: SalesReport,
      meta: {requiresAuth: true}
    },
    {
      path: '/account-list',
      name: 'account-list',
      component: AccountLists,
      meta: {requiresAuth: true}
    },
    {
      path: '/bank-list',
      name: 'bank-list',
      component: BankLists,
      meta: {requiresAuth: true}
    },
    {
      path: '/new-bank',
      name: 'new-bank',
      component: NewBank,
      meta: {requiresAuth: true}
    },
    {
      path: '/edit-bank/:id',
      name: 'edit-bank',
      component: EditBank,
      meta: {requiresAuth: true}
    },
    {
      path: '/bank-branch-list',
      name: 'bank-branch-list',
      component: BankBranchLists,
      meta: {requiresAuth: true}
    },
    {
      path: '/new-bank-branch',
      name: 'new-bank-branch',
      component: NewBankBranch,
      meta: {requiresAuth: true}
    },
    {
      path: '/edit-bank-branch/:id',
      name: 'edit-bank-branch',
      component: EditBankBranch,
      meta: {requiresAuth: true}
    },
    {
      path: '/new-bank-account',
      name: 'new-bank-account',
      component: NewBankAccount,
      meta: {requiresAuth: true}
    },
    {
      path: '/edit-bank-account/:id',
      name: 'edit-bank-account',
      component: EditBankAccount,
      meta: {requiresAuth: true}
    },
    {
      path: '/amount-transfer',
      name: 'amount-transfer',
      component: AmountTransfer,
      meta: {requiresAuth: true}
    },
    {
      path: '/new-capital',
      name: 'new-capital',
      component: NewCapital,
      meta: {requiresAuth: true}
    },
    {
      path: '/accounts-statement',
      name: 'accounts-statement',
      component: AccountStatements,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-expenses-requested/:id',
      name: 'view-expenses-requested',
      component: ViewExpensesReq,
      meta: {requiresAuth: true}
    },
    {
      path: '/settings',
      name: 'settings',
      component: Setting,
      meta: {requiresAuth: true}
    },
    {
      path: '/company-info',
      name: 'company-info',
      component: CompanyInfo,
      meta: {requiresAuth: true}
    },
    {
      path: '/billing',
      name: 'billing',
      component: Billing,
      meta: {requiresAuth: true}
    },
    {
      path: '/forget-password',
      name: 'forget-password',
      component: ForgetResetPassword,
    },
    {
      path: '/register-client',
      name: 'register-client',
      component: RegisterClient,
    },
    {
      path: '/password-reset',
      name: 'password-reset',
      component: PasswordReset,
    },
    {
      path: '/upload-stock',
      name: 'upload-stock',
      component: UploadStock,
      meta: {requiresAuth: true}
    },
    {
      path: '/import-employee',
      name: 'import-employee',
      component: ImportEmployees,
      meta: {requiresAuth: true}
    },
    {
      path: '/dpo-success',
      name: 'dpo-success',
      component: DpoSuccess,
      meta: {requiresAuth: true}
    },
    {
      path: '/client-lists',
      name: 'client-lists',
      component: ClientLists,
      meta: {requiresAuth: true}
    },
    {
      path: '/update-client-payments/:clientId',
      name: 'update-client-payments',
      component: UpdateClientPayments,
      meta: {requiresAuth: true}
    },
    {
      path: '/salary-slip-list',
      name: 'salary-slip-list',
      component: SalarySlipList,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-salary-slip/:id',
      name: 'view-salary-slip',
      component: ViewSalarySlip,
      meta: {requiresAuth: true}
    },
    {
      path: '/self-services',
      name: 'self-services',
      component: SelfServices,
      meta: {requiresAuth: true}
    },
    {
      path: '/self-services-payslip',
      name: 'self-services-payslip',
      component: SelfServicePaySlip,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-salary-slip-self/:id',
      name: 'view-salary-slip-self',
      component: ViewPayslipSelf,
      meta: {requiresAuth: true}
    },
    {
      path: '/profile-self',
      name: 'profile-self',
      component: ProfileSelf,
      meta: {requiresAuth: true}
    },
    {
      path: '/hr-dashboard',
      name: 'hr-dashboard',
      component: HrDashboard,
      meta: {requiresAuth: true}
    },
    {
      path: '/pending-leaves',
      name: 'pending-leaves',
      component: PendingLeaves,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-leave-request/:id',
      name: 'view-leave-request',
      component: ViewPendingLeaveRequests,
      meta: {requiresAuth: true}
    },
    {
      path: '/approved-leaves',
      name: 'approved-leaves',
      component: ApprovedLeave,
      meta: {requiresAuth: true}
    },
    {
      path: '/view-approved-leaves/:id',
      name: 'view-approved-leaves',
      component: ViewApprovedLeaveRequest,
      meta: {requiresAuth: true}
    },
    {
      path: '/leave-list',
      name: 'leave-list',
      component: LeaveLists,
      meta: {requiresAuth: true}
    },
    {
      path: '/edit-leave-type/:id',
      name: 'edit-leave-type',
      component: EditLeaveType,
      meta: {requiresAuth: true}
    },
    {
      path: '/income-statement',
      name: 'income-statement',
      component: IncomeStatement,
      meta: {requiresAuth: true}
    },
  ]
})

router.beforeEach((to, from, next) => {
  const isClientSubscribed = store.getters.isClientSubscribed;
  
  // List of public routes that do not require authentication or subscription status
  const publicPages = ['login', 'reset-password-request','client-lists','update-client-payments']; // Add any other public routes here
  
  if (publicPages.includes(to.name)) {
    next(); // Allow access to public pages
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({ name: 'login' }); // Redirect to login page if not authenticated
    } else if (isClientSubscribed !== 'Active') {
      if (to.name === 'dashboard' || to.name === 'billing') {
        next(); // Allow access to Dashboard and Billing pages
      } else {
        next({ name: 'billing' }); // Redirect to Billing page
      }
    } else {
      next(); // Allow navigation if authenticated and subscription is active
    }
  } else {
    next(); // Allow navigation for routes without requiresAuth
  }
});


export default router;