<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Settings</span>
                                        </div>
                                        <div class="col-md-2">
                                            <RouterLink to="/company-info">
                                                <button type="button" class="btn btn-info btn-sm" ><i class="fa fa-satellite"></i> Company Info</button> 
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
            
                                <div class="card-body" v-else>
                                    <div class="container container-border">
                                        <div class="row pt-3">
                                             <div class="col-md-4">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend" style="height: 32px;">
                                                            <span class="input-group-text" for="inputGroupSelect01">Select User</span>
                                                        </div>
                                                        <select class="form-control" v-model="data.userId" @change="fetchPermissions" style="height: 32px;">
                                                            <option v-for="(user,i) in users" :key="i" :value="user.id">{{ user.full_name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                             </div>
                                             <div class="col-md-2"></div>
                                             <div class="col-md-4">
                                                <span style="font-weight: 600;">User Permissions</span>
                                             </div>
                                        </div>
            
                                        <div class="container  mb-2" v-if="data.userId">
                                            <table class="table table-hover table-bordered table-sm table-responsive-sm mt-2">
                                                <tbody>
                                                    <tr>
                                                        <td colspan="6" align="center"><span style="font-weight: 600;">Menu Access</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.customer_suppliers">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Customer & Suppliers
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"  value="" id="defaultCheck1" v-model="permissions.sales_management">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Sales Management
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.expenses_management">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Expenses
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.product_services">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Products & Services
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.purchases_order">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Purchases Order
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.accounting_management">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Accounting
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.stock_management">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Stock Management
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.payroll_management">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Payroll Management
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.human_resources">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Human Resourses
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.user_access">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    User Access
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.settings_access">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Settings
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.employee_self_service">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Self Service
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
            
                                                    <tr>
                                                        <td colspan="6" align="center"><span style="font-weight: 600;">Edit & Delete</span></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td>Customers & Suppliers</td>
                                                        <td>Expenses</td>
                                                        <td>Products & Services</td>
                                                        <td>Products Category</td>
                                                        <td>Bank</td>
                                                        <td>Bank Account</td>
                                                    </tr>
            
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.delete_cust_suppl">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Delete
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.delete_expenses">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Delete
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.delete_product">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Delete
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.delete_product_cat">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Delete
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.delete_bank">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Delete
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.delete_bank_acc">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Delete
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
            
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.edit_cust_suppl">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    edit
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.edit_expenses">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    edit
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.edit_product">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    edit
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.edit_product_cat">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    edit
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.edit_bank">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    edit
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.edit_bank_acc">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    edit
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
            
                                                    <tr>
                                                        <td colspan="6"></td>
                                                    </tr>
            
                                                    <tr>
                                                        <td>Stock</td>
                                                        <td>Invoice</td>
                                                        <td>Purchases</td>
                                                        <td>Payroll</td>
                                                        <td colspan="2" align="center">Human Resourses</td>
                                                    </tr>
            
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.edit_stock">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Edit
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.delete_invoice">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Delete
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.delete_purchase">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Delete
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.delete_payroll">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Delete
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.edit_hr">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Edit
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.delete_hr">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                    Delete
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
            
                                                    <tr>
                                                        <td colspan="6" align="center"><span style="font-weight: 600;">Pages Access</span></td>
                                                    </tr>
            
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.new_supplier">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                New Supplier
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.suppliers_lists">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Suppliers List
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.new_customer">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                New Customer
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.sales_report">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Sales Reports
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.profoma_lists">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Profoma Lists
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.invoices_lists">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Invoices Lists
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
            
                                                    <tr>
                                                        <td colspan="6"></td>
                                                    </tr>
            
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.expenses_reports">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Expenses Report
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.new_expense_category">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                New Expense Category
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.expense_category_lists">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Expense Category List
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.expense_lists">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Expense List
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.new_expenses">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                New Expenses
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.requested_expense">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Requested Expenses
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
            
                                                    <tr>
                                                        <td colspan="6"></td>
                                                    </tr>
            
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.new_products">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                New Product
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.new_product_category">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                New Product Category
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.product_category_lists">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Product Category List
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.new_sub_products">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                New Sub Product
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.sub_products_lists">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Sub Product List
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.purchasing_order_lists">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Purchasing Order List
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
            
                                                    <tr>
                                                        <td colspan="6"></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.account_statement">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Account Statement
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.new_capital">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                New Capital
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.amount_transfer">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Amount Transfer
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.bank_branch_lists">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Bank Branch List
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.new_bank_branch">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                New Bank Branch
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.bank_lists">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Bank Lists
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
            
                                                    <tr>
                                                        <td colspan="6"></td>
                                                    </tr>
            
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.new_bank">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                New Bank
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.new_bank_account">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                New Bank Account
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.approved_payroll">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Approved Payroll
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.rejected_payroll">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Rejected Payroll
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.payroll_pending">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Payroll Pending
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="permissions.prepare_payroll">
                                                                <label class="form-check-label" for="defaultCheck1">
                                                                Prepare Payroll
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    
            
                                                </tbody>
                                            </table>
                                          
                                            <div class="row mt-4 pb-2">
                                                <div class="col-md-4" v-if="updating">
                                                    <button type="button" disabled class="btn btn-info btn-sm" @click="updatePermissions">Saving...</button>
                                                </div>
                                                <div class="col-md-4" v-else>
                                                    <button type="button" class="btn btn-info btn-sm" @click="updatePermissions">Save</button>
                                                </div>
                                                <pulse-loader 
                                                    :loading="updating" 
                                                    :color="color"
                                                    v-if="updating" 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from 'sweetalert2';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
                userId: ''
            },
            permissions: {
                customer_suppliers: false,
                sales_management: false,
                expenses_management: false,
                product_services: false,
                purchases_order: false,
                accounting_management: false,
                stock_management: false,
                payroll_management: false,
                employee_self_service: false,
                human_resources: false,
                user_access: false,
                settings_access: false,
                delete_cust_suppl: false,
                edit_cust_suppl: false,
                delete_expenses: false,
                edit_expenses: false,
                delete_product: false,
                edit_product: false,
                delete_product_cat: false,
                edit_product_cat: false,
                delete_bank: false,
                edit_bank: false,
                delete_bank_acc: false,
                edit_bank_acc: false,
                edit_stock: false,
                delete_invoice: false,
                delete_purchase: false,
                delete_payroll: false,
                delete_hr: false,
                edit_hr: false,
                new_supplier: false,
                suppliers_lists: false,
                new_customer: false,
                sales_report: false,
                profoma_lists: false,
                invoices_lists: false,
                expenses_reports: false,
                new_expense_category: false,
                expense_category_lists: false,
                expense_lists: false,
                new_expenses: false,
                requested_expense: false,
                new_products: false,
                new_product_category: false,
                product_category_lists: false,
                new_sub_products: false,
                sub_products_lists: false,
                purchasing_order_lists: false,
                account_statement: false,
                new_capital: false,
                amount_transfer: false,
                bank_branch_lists: false,
                new_bank_branch: false,
                bank_lists: false,
                new_bank: false,
                new_bank_account: false,
                approved_payroll: false,
                rejected_payroll: false,
                payroll_pending: false,
                prepare_payroll: false
            },
            isLoading: false,
            updating: false,
            users: [],
            color: '#40aac7',
        }
    },

    mounted(){
        this.fetchUsers();
    },

    methods: {
        async fetchUsers(){
            this.isLoading = true
            await Api().get('users')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.users = res.data
                }
            }).catch(error =>{
                if(error){
                    console.log('Failed to get users')
                }
            })
        },
        
        async fetchPermissions(){
            this.isLoading = true
            await Api().get(`permissions/${this.data.userId}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    res.data.forEach(permission =>{
                        if (Object.prototype.hasOwnProperty.call(this.permissions, permission.key)) {
                        this.permissions[permission.key] = permission.value;
                    }
                   })
                }
            }).catch(error =>{
                if(error){
                this.isLoading = false
                console.error('Failed to fetch permissions.',error)
                }
            });
        },

        async updatePermissions(){
            this.updating = true
           const updatedPermissions = Object.keys(this.permissions).map(key =>({
                key: key,
                value: this.permissions[key]
           }));
           await Api().post(`update-user-permissions/${this.data.userId}`,{permissions: updatedPermissions})
           .then(res =>{
            if(res.status === 200){
                this.updating = false
                Swal.fire({
                    title: "Successfully!",
                    text: `${res.data.message}`,
                    icon: "success"
                });
            }else{
                this.updating = false
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                });
            }
           }).catch(error =>{
            if(error){
                this.updating = false
                console.log('Failed to update user permissions.',error)
            }
           })
        }
    }
}
</script>

<style scoped>
.container-border{
    border:1px solid #e3e3e3 !important; 
}

/* .table tr td{
    border: none;
} */
</style>