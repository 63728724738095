<template>
    <div  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <content-loader v-if="isLoading"/>
                <div class="container-fluid" v-else>
                    <div class="row">
                        <div class="col-sm-6 col-md-3 col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <span>Clients List</span>
                                        </div>
                                        <div class="col-md-5">
                                            <RouterLink to="/register-client">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fa fa-record-vinyl"></i> New Account</button> 
                                            </RouterLink>
                                            <RouterLink to="/supplier-list" v-if="hasPermission('suppliers_lists')">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-list-alt"></i> Suppliers List</button> 
                                            </RouterLink>
                                            <RouterLink to="/new-customer" v-if="hasPermission('new_customer')">
                                                <button type="button" class="btn btn-info btn-sm" ><i class="fas fa-satellite"></i> New Customer</button> 
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="card-body">
                                    <div class="row mb-1">
                                        <div class="col-md-3">
                                            <input type="text" v-model="searchQuery" placeholder="Search..." class="form-control" style="height: 35px;" />
                                        </div>
                                    </div>
                                    <table id="example1" class="table table-hover table-bordered table-striped table-sm table-sm table-responsive-sm">
                                        <tr>
                                            <th>Client Name</th>
                                            <th>Phone</th>
                                            <th>Email</th>
                                            <th>Address</th>
                                            <th>Business Name</th>
                                            <th>Business Type</th>
                                            <th v-if="hasPermission('edit_cust_suppl')">Edit</th>
                                            <th v-if="hasPermission('delete_cust_suppl')">Delete</th>
                                        </tr>
            
                                        <tbody>
                                            <tr v-for="(client, i) in displayedClients" :key="i">
                                                <td>{{ client.full_name }}</td>
                                                <td>{{ client.phone }}</td>
                                                <td>{{ client.email }}</td>
                                                <td>{{ client.address }}</td>
                                                <td>{{ client.business_name }}</td>
                                                <td>{{ client.business_type }}</td>
                                                <td v-if="hasPermission('edit_cust_suppl')">
                                                    <RouterLink :to="{name: 'update-client-payments', params: {clientId: client.clientId}}">
                                                        <button type="button" class="btn btn-info btn-sm"><i class="fa fa-edit"></i></button>
                                                    </RouterLink>
                                                </td>
                                                <td v-if="hasPermission('delete_cust_suppl')">
                                                    <button type="button" class="btn btn-danger btn-sm" @click="deleteConfirm(client.clientId, i)"><i class="fa fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Pagination 
                                        :currentPage="currentPage" 
                                        :totalPages="totalPages" 
                                        @page-change="handlePageChange" 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ControlSidebar></ControlSidebar>
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../../services/Api';
import ContentLoader from '../../components/ContentLoader.vue'
import Swal from 'sweetalert2';
import store from '../../store/store';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";
import Pagination from '../../components/Pagination.vue';

export default{
    components: {
        ContentLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer,
        Pagination
    },
    data(){
        return{
            data: {
                userId: store.state.user.id,
            },
            isLoading: '',
            clients: [],
            permissions: [],
            color: '#40aac7',
            displayedClients: [],
            searchQuery: '',
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
        }
    },

    mounted(){
        this.fetchClients();
    },

    methods: {
        async fetchClients(){
            this.isLoading = true
            await Api().get('get-clients')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.clients = res.data
                    this.updatePaginatedData();
                }
            }).catch(error =>{
                if(error){
                    console.log('Failed to get users')
                }
            })
        },

        updatePaginatedData() {
            const filteredProducts = this.clients.filter(client => 
            client.full_name.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            this.displayedClients = filteredProducts.slice(start, end);
            this.totalPages = Math.ceil(filteredProducts.length / this.perPage);
        },

        handlePageChange(page) {
            this.currentPage = page;
            this.updatePaginatedData();
        },

        deleteConfirm(id, index){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true
            }).then((result) => {
            if (result.isConfirmed) {
                this.deleteClient(id, index);
                swalWithBootstrapButtons.fire({
                title: "Deleted!",
                text: "Client has been deleted.",
                icon: "success"
                });
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire({
                title: "Cancelled",
                text: "Your customer is safe",
                icon: "error"
                });
            }
            });
        },

        async deleteClient(id, index){
            this.isLoading = true
            await Api().delete(`delete-client/${id}`)
            .then(res =>{
                try{
                    if(res.status === 200){
                        this.isLoading = false;
                        this.displayedClients.splice(index, 1);
                    }
                }catch(error){
                    console.log(error);
                }
            })
        },

        hasPermission(permissionKey) {
            return store.state.permissions.some(permission => permission.key === permissionKey && permission.value);
        }
    },

    watch: {
        searchQuery: 'updatePaginatedData',
        currentPage: 'updatePaginatedData'
    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>