<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="row mt-2">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row justify-content-end">
                                    <div class="col-md-10">
                                        <span style="font-weight: 600;">Rejected Payroll</span>
                                    </div>
                                    <div class="col-md-2">
                                        <RouterLink to="/rejected-payroll">
                                            <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                        </RouterLink>
                                    </div>
                                </div>
                            </div>
                            <pulse-loader 
                                :loading="isLoading" 
                                :color="color"
                                v-if="isLoading" 
                                style="margin-top: 50px; margin-left: 40%;"
                            />
                            <div class="card-body" v-else>
                                <div class="table-responsive">
                                    <table id="example1" class="table table-bordered table-hover table-sm">
                                        <tr>
                                            <th>Employee Name</th>
                                            <th>Basic Salary</th>
                                            <th>Allowance</th>
                                            <th>Gross Salary</th>
                                            <th>Nssf</th>
                                            <th>Taxable Income</th>
                                            <th>Payee Tax</th>
                                            <th>Wcf</th>
                                            <th>Other Deductions</th>
                                            <th>Total Deductions</th>
                                            <th>Net Pay</th>
                                        </tr>
            
                                        <tbody>
                                            <tr v-for="(payroll, i) in payrolls" :key="i">
                                                <td>{{ payroll.first_name }} {{ payroll.middle_name }} {{ payroll.last_name }}</td>
                                                <td>{{ formatNumber(payroll.basic_salary) }}</td>
                                                <td>{{ formatNumber(payroll.allowance) }}</td>
                                                <td>{{ formatNumber(payroll.gross_salary) }}</td>
                                                <td>{{ formatNumber(payroll.nssf) }}</td>
                                                <td>{{ formatNumber(payroll.taxable_income) }}</td>
                                                <td>{{ formatNumber(payroll.paye_tax) }}</td>
                                                <td>{{ formatNumber(payroll.wcf) }}</td>
                                                <td>{{ formatNumber(payroll.other_deductions) }}</td>
                                                <td>{{ formatNumber(payroll.total_deductions) }}</td>
                                                <td>{{ formatNumber(payroll.net_pay) }}</td>
                                            </tr>
            
                                            <tr>
                                                <td><b>Total</b></td>
                                                <td><b>{{ formatNumber(total_basic_salary) }}</b></td>
                                                <td><b>{{ formatNumber(total_allowance) }}</b></td>
                                                <td><b>{{ formatNumber(total_gross_salary) }}</b></td>
                                                <td><b>{{ formatNumber(total_nssf) }}</b></td>
                                                <td><b>{{ formatNumber(total_taxable_income) }}</b></td>
                                                <td><b>{{ formatNumber(total_payee) }}</b></td>
                                                <td><b>{{ formatNumber(total_wcf) }}</b></td>
                                                <td><b>{{ formatNumber(total_other_deductions) }}</b></td>
                                                <td><b>{{ formatNumber(total_deduction) }}</b></td>
                                                <td><b>{{ formatNumber(total_net_pay) }}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-md-8">
                                        <span style="font-weight: 600;">Comment:</span><br>
                                        <span>{{ comment }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
// import Swal from 'sweetalert2';
// import $ from 'jquery';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default {
    components: {
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },

    data(){
        return {
            month: '',
            comment: '',
            preview_payroll: false,
            isLoading: false,
            color: '#40aac7',
            payrolls: [],
            payrollId: this.$route.params.id,
            total_basic_salary: 0,
            total_allowance: 0,
            total_gross_salary: 0,
            total_nssf: 0,
            total_taxable_income: 0,
            total_payee: 0,
            total_wcf: 0,
            total_other_deductions: 0,
            total_deduction: 0,
            total_net_pay: 0,
            errors: ''
        }
    },

    mounted(){
        this.fetchPayrollData();
    },

    methods: {
        async fetchPayrollData(){
            this.isLoading = true
            await Api().get(`show-rejected-payroll/${this.payrollId}`)
            .then(res => {
                if(res.status === 200){
                    this.isLoading = false
                    this.payrolls = res.data
                    this.total_basic_salary = res.data[0].payrolls.total_basic_salary
                    this.total_allowance = res.data[0].payrolls.total_allowance
                    this.total_gross_salary = res.data[0].payrolls.total_gross_salary
                    this.total_nssf = res.data[0].payrolls.total_nssf
                    this.total_taxable_income = res.data[0].payrolls.total_taxable_income
                    this.total_payee = res.data[0].payrolls.total_payee
                    this.total_wcf = res.data[0].payrolls.total_wcf
                    this.total_other_deductions = res.data[0].payrolls.total_other_deductions
                    this.total_deduction = res.data[0].payrolls.total_deduction
                    this.total_net_pay = res.data[0].payrolls.total_net_pay
                    this.comment = res.data[0].payrolls.comments
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.error('Failed to fetch preview data.', error)
                }
            })
        },

        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        },

    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>