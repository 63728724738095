<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import "../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css"
import "../node_modules/admin-lte/plugins/select2/css/select2.min.css"

export default {
  name: "App",
  components: {

  },

  data(){
    return{
      isLoggedIn: '',
    }
  },

  mounted() {
    this.$store.commit('resetTimer'); // Start the timer on mount
    // Listen to events to reset the timer
    window.addEventListener('mousemove', this.resetTimer);
    window.addEventListener('keydown', this.resetTimer);
  },

  beforeUnmount(){
    // Clean up event listeners
    window.removeEventListener('mousemove', this.resetTimer);
    window.removeEventListener('keydown', this.resetTimer);
  },

  methods: {
    resetTimer() {
      this.$store.commit('resetTimer');
    }
  },

  
  
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");
</style>
