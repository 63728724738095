<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <content-loader v-if="isLoading"/>
                <div class="container-fluid" v-else>
                    <div class="row">
                        <div class="col-sm-6 col-md-3 col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <span style="font-weight: 600;">Payroll Lists</span>
                                        </div>
                                        <div class="col-md-8">
                                            <RouterLink to="/aproved-payroll">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-save"></i> 
                                                    Approved Payroll
                                                    <span class="badge badge-danger right">{{ payroll_approved_count }}</span>
                                                </button> 
                                            </RouterLink>
                                            <RouterLink to="/rejected-payroll">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-spa"></i> 
                                                    Rejected Payroll
                                                    <span class="badge badge-danger right">{{ payroll_rejected_count }}</span>
                                                </button> 
                                            </RouterLink>
                                            <RouterLink to="/pending-payroll">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-space-shuttle"></i> 
                                                 Pending For Approval
                                                <span class="badge badge-danger right">{{ payroll_pending_count }}</span>
                                                </button> 
                                            </RouterLink>
                                            <RouterLink to="/prepare-payroll">
                                                <button type="button" class="btn btn-info btn-sm" ><i class="fas fa-satellite"></i> Prepare Payroll</button> 
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                <!-- <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                /> -->
            
                                <div class="card-body">
                                    <div class="row mb-1">
                                        <div class="col-md-3">
                                            <input type="text" v-model="searchQuery" placeholder="Search..." class="form-control" style="height: 35px;" />
                                        </div>
                                    </div>
                                    <table id="example1" class="table table-hover table-bordered table-striped table-sm table-sm table-responsive-sm">
                                        <tr>
                                            <th>Month</th>
                                            <th>Basic Salary</th>
                                            <th>Allowance</th>
                                            <th>Status</th>
                                            <th>Gross Salary</th>
                                            <th>Nssf</th>
                                            <th>Deductions</th>
                                            <th>Net Pay</th>
                                            <th>Payroll</th>
                                            <th>Sdl</th>
                                            <th>Nssf</th>
                                            <th>Paye</th>
                                            <th>Wcf</th>
                                            <th>Net Pay</th>
                                            <!-- <th>Delete</th> -->
                                        </tr>
            
                                        <tbody>
                                            <tr v-for="(payroll, i) in displayedPayrolls" :key="i">
                                                <td>{{ payroll.month }}</td>
                                                <td>{{ formatNumber(payroll.total_basic_salary) }}</td>
                                                <td>{{ formatNumber(payroll.total_allowance) }}</td>
                                                <td><span class="badge badge-success">{{ payroll.status }}</span></td>
                                                <td>{{ formatNumber(payroll.total_gross_salary) }}</td>
                                                <td>{{ formatNumber(payroll.total_nssf) }}</td>
                                                <td>{{ formatNumber(payroll.total_deduction) }}</td>
                                                <td>{{ formatNumber(payroll.total_net_pay) }}</td>
                                                <td>
                                                    <RouterLink :to="{name: 'view-payroll', params: {id: payroll.id}}">
                                                        <button type="button" class="btn btn-info btn-sm"><i class="fa fa-eye"></i></button>
                                                    </RouterLink>
                                                </td>
                                                <td>
                                                    <RouterLink :to="{name: 'view-sdl', params: {id: payroll.id}}">
                                                        <button v-if="payroll.status == 'Approved'" type="button" class="btn btn-warning btn-sm"><i class="fas fa-screwdriver"></i></button>
                                                    </RouterLink>
                                                </td>
                                                <td>
                                                    <RouterLink :to="{name: 'view-nssf', params: {id: payroll.id}}">
                                                        <button v-if="payroll.status == 'Approved'" type="button" class="btn btn-danger btn-sm"><i class="fas fa-satellite"></i></button>
                                                    </RouterLink>
                                                </td>
                                                <td>
                                                    <RouterLink :to="{name: 'view-paye', params: {id: payroll.id}}">
                                                        <button type="button" class="btn btn-info btn-sm"><i class="fa fa-eye"></i></button>
                                                    </RouterLink>
                                                </td>
                                                <td>
                                                    <RouterLink :to="{name: 'view-wcf', params: {id: payroll.id}}">
                                                        <button v-if="payroll.status == 'Approved'" type="button" class="btn btn-secondary btn-sm"><i class="fas fa-save"></i></button>
                                                    </RouterLink>
                                                </td>
                                                <td>
                                                    <RouterLink :to="{name: 'net-pay', params: {id: payroll.id}}">
                                                        <button v-if="payroll.status == 'Approved'" type="button" class="btn btn-success btn-sm"><i class="fas fa-quidditch"></i></button>
                                                    </RouterLink>
                                                </td>
                                              
                                                <!-- <td>
                                                    <button type="button" class="btn btn-danger btn-sm" @click="deleteConfirm(payroll.id)"><i class="fa fa-trash"></i></button>
                                                </td> -->
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Pagination 
                                        :currentPage="currentPage" 
                                        :totalPages="totalPages" 
                                        @page-change="handlePageChange" 
                                    />
                                    
                                    <div class="row justify-content-center">
                                        <div class="col-md-6">
                                            <div class="alert alert-light" role="alert">
                                                <span>Follow procedures for processing payeoll</span>
                                                <li>Prepare payroll >> select month >> submit for approval.</li>
                                                <li>Pending approval >> view >> approve.</li>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import ContentLoader from '../../components/ContentLoader.vue'
// import Swal from 'sweetalert2';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";
import Pagination from '../../components/Pagination.vue';

export default{
    components: {
        // PulseLoader,
        ContentLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer,
        Pagination,
    },
    data(){
        return{
            isLoading: '',
            payroll_lists: [],
            payroll_pending_count: 0,
            payroll_rejected_count: 0,
            payroll_approved_count: 0,
            color: '#40aac7',
            displayedPayrolls: [],
            searchQuery: '',
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
        }
    },

    mounted(){
        this.fetchPayrollLists()
    },

    methods: {
        async fetchPayrollLists(){
            this.isLoading = true
            await Api().get('payrolls')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.payroll_lists = res.data.payrolls
                    this.payroll_pending_count = res.data.payroll_pending_count
                    this.payroll_rejected_count = res.data.payroll_rejected_count
                    this.payroll_approved_count = res.data.payroll_approved_count
                    this.updatePaginatedData();
                }
            }).catch(error =>{
                if(error){
                    console.log('Failed to get users')
                }
            })
        },

        updatePaginatedData() {
            const filteredProducts = this.payroll_lists.filter(payroll => 
                payroll.month.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            this.displayedPayrolls = filteredProducts.slice(start, end);
            this.totalPages = Math.ceil(filteredProducts.length / this.perPage);
        },

        handlePageChange(page) {
            this.currentPage = page;
            this.updatePaginatedData();
        },

        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        }
    },

    watch: {
        searchQuery: 'updatePaginatedData',
        currentPage: 'updatePaginatedData'
    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>