<template>
    <div class="chart-wrapper">
      <span>Expenses</span>
      <apexchart
        v-if="chartDataLoaded"
        type="pie"
        :options="chartOptions"
        :series="series"
      />
      <div v-else>Loading...</div>
      <!-- <div class="card-body">
      </div> -->
    </div>
  </template>
  
  <script>
  import VueApexCharts from 'vue3-apexcharts';
  import Api from '../services/Api';
  
  export default {
    components: {
      apexchart: VueApexCharts
    },
    props: {
      apiUrl: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        series: [],
        chartOptions: {
          chart: {
            type: 'pie',
            height: 250
          },
          labels: [],
          responsive: [
            {
              breakpoint: 768,
              options: {
                chart: {
                  height: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            },
            {
              breakpoint: 480,
              options: {
                chart: {
                  height: 180
                },
                legend: {
                  position: 'bottom',
                  fontSize: '10px'
                }
              }
            }
          ]
        },
        chartDataLoaded: false
      };
    },
  
    async mounted() {
      try {
        const response = await Api().get(this.apiUrl);

        const expenses = response.data.expenses;

        if (Array.isArray(expenses)) {
          const labels = expenses.map(expense => expense.month);
          const data = expenses.map(expense => parseFloat(expense.total_amount));

          this.series = data;
          this.chartOptions.labels = labels;
          this.chartDataLoaded = true;
        } else {
          console.error('Unexpected data format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

  };
  </script>
  
  <style scoped>
  .chart-wrapper {
    width: 60%;
    /* margin: 0 auto; */
    max-width: 60%;
  }
  </style>
  