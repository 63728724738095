<template>
     <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6 col-md-3 col-lg-12" style="padding-bottom: 1%;">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Account Statement</span>
                                        </div>
                                        <div class="col-md-2">
                                            <RouterLink to="/account-list">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-chevron-left"></i> Back</button> 
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <date-picker v-model:value="data.startDate" 
                                                    value-type="YYYY-MM-DD" 
                                                    placeholder="Start Date"
                                                    @change="onStartDateChange"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <date-picker v-model:value="data.endDate" 
                                                value-type="YYYY-MM-DD" 
                                                placeholder="End Date"
                                                @change="onEndDateChange"
                                            />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <div class="input-group-prepend" style="height: 32px;">
                                                        <span class="input-group-text" for="inputGroupSelect01">Select Account</span>
                                                    </div>
                                                    <select class="form-control" style="height: 32px;" v-model="data.account_name" @change="onAccounrChange">
                                                        <option v-for="(account, i) in accounts" :key="i" :value="account.id">{{ account.account_name }} {{ account.account_no }} {{ account.banks.bank_name }}</option>
                                                    </select>
                                                    <div v-if="errors.account_name">
                                                        <span class="text-danger">{{ errors.account_name[0] }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <pulse-loader 
                                        :loading="isLoading" 
                                        :color="color"
                                        v-if="isLoading" 
                                        style="margin-top: 50px; margin-left: 40%;"
                                    />
            
                                    <table v-else id="example1" class="table table-hover table-sm table-sm table-responsive-sm">
                                        <tr>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Dr</th>
                                            <th>Cr</th>
                                            <th>Balance</th>
                                        </tr>
            
                                        <tbody >
                                            <tr v-for="(statement, i) in statements" :key="i">
                                                <td>{{ statement.date }}</td>
                                                <td>{{ statement.description }}</td>
                                                <td>{{ formatNumber(statement.dr) }} </td>
                                                <td>{{ formatNumber(statement.cr )}}</td>
                                                <td>{{ formatNumber(statement.balance) }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="1"></td>
                                                <td><b>TOTAL</b></td>
                                                <td class="double-line">{{ formatNumber(totalDr) }}</td>
                                                <td class="double-line">{{ formatNumber(totalCr) }}</td>
                                                <!-- <td class="double-line"></td> -->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
     </body>
</template>

<script>
import Api from '../../services/Api';
import DatePicker from 'vue-datepicker-next';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";
import 'vue-datepicker-next/index.css';

export default{
    components: {
        DatePicker,
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },

    data(){
        return{
            data: {
                startDate: '',
                endDate: '',
                account_name: '',
            },
            statements: [],
            accounts: [],
            isLoading: '',
            color: '#40aac7',
            errors: '',
            totalDr: 0,
            totalCr: 0,
           
        }
    },
    
    mounted(){
        this.fetchAccounts();
    },

    methods: {
        onEndDateChange(){
            if(this.data.endDate){
                this.search();
            }
        },

        onStartDateChange(){
            if(this.data.startDate){
                this.search();
            }
        },

        onAccounrChange(){
            if(this.data.account_name){
                this.search();
            }
        },

        async search(){
           if(!this.data.startDate || !this.data.endDate){
            return;
           }
           this.isLoading = true
           await Api().post('search-account-statements',this.data)
           .then(res => {
                if(res.status === 200){
                    this.isLoading = false
                    this.statements = res.data.statements
                    this.totalCr = res.data.totalCr
                    this.totalDr = res.data.totalDr
                }
           }).catch(error =>{
            if(error){
                this.isLoading = false
                console.log('Failed to search expenses.',error)
            }
           })
        },

        async fetchAccounts(){
            this.isLoading = true
            await Api().get('accounts')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.accounts = res.data
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to fetch account informations.', error)
                }
            })
       },

        // totalCr(){
        //     return this.statements.reduce((sum ,statement) =>{
        //         return sum + statement.cr;
        //     },0)
        // },

        // totalDr(){
        //     return this.statements.reduce((sum ,statement) =>{
        //         return sum + statement.dr;
        //     },0)
        // },

        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        },
    }
}
</script>

<style scoped>
   .table {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: rgb(242, 245, 248);
    background-color: rgb(242, 247, 250);
}

.table tbody tr td{
    border: none !important;
    font-size: 13px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.table tbody tr th{
    background-color: #e7e7f4;;
    font-size: 13px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.double-line{
   position: relative;
   height: 50px;
}

.double-line::before, .double-line::after{
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    border-top: 1px solid black;
}

.double-line::before{
    bottom: 50%;
}
</style>