<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 4%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <!-- <content-loader v-if="isLoading"/> -->
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6 col-md-3 col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span style="font-weight: 600;">PAYE</span>
                                        </div>
                                        <div class="col-md-2">
                                            <RouterLink to="/payroll-list">
                                                <button type="button" class="btn btn-info btn-sm" ><i class="fas fa-chevron-left"></i> Back</button> 
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
            
                                <div class="card-body" v-else  id="table-content">
                                    <table id="example1" class="table table-hover table-striped table-bordered table-sm table-sm table-responsive-sm">
                                        <tr align="center">
                                            <td colspan="13">
                                                <img src="../../assets/img/tralogo.jpg" width="60"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" align="center"><strong>Month</strong> </td>
                                            <td colspan="1" align="center" style="background-color: yellow;"><strong>{{ month }}</strong></td>
                                            <td colspan="1" align="center"><strong>Year</strong></td>
                                            <td colspan="1" align="center" style="background-color: yellow;"><strong>{{ formateDate(date) }}</strong></td>
                                            <td colspan="8"><strong>Tanzania Mainland</strong></td>
                                        </tr>
                                        <tr>
                                            <td colspan="13" align="center">
                                                <span style="font-weight: 600;">
                                                    Statements and Payments of Taxes Withheld for Employees(PAYE)
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="13">
                                                <p style="text-align: center; font-weight: 600;">
                                                    Note: This return is submitted under the provisions of Section 84 of the Income Tax Act, Cap 332. 
                                                    You are hereby required to submit the return and make payment within 7 days after the end of the month to which it refers. 
                                                </p>
                                            </td>
                                        </tr>
                                        
                                        <tr style="background-color: yellow;">
                                            <th width="50">S.N</th>
                                            <th>EMPLOYEE'S TIN</th>
                                            <th>TYPE OF EMPLOYMENT</th>
                                            <th>RESIDENTIAL STATUS</th>
                                            <th>NAME OF EMPLOYEE</th>
                                            <th>SOCIAL SEQURITY NUMBER</th>
                                            <th>BASIC PAY</th>
                                            <th>OTHER ALLOWANCES</th>
                                            <th>GROSS AMOUNT</th>
                                            <th>DEDUCTIONS</th>
                                            <th>TAXABLE AMOUNT</th>
                                            <th>TAX PAYABLE</th>
                                        </tr>
            
                                        <tbody>
                                           <tr v-for="(paye, i) in payes" :key="i">
                                                <td>{{ i+1 }}</td>
                                                <td>{{ paye.tin_no }}</td>
                                                <td>{{ paye.employement_type }}</td>
                                                <td>{{ paye.residential_status }}</td>
                                                <td>{{ paye.first_name }} {{ paye.middle_name }} {{ paye.last_name }}</td>
                                                <td>{{ paye.social_seq_no }}</td>
                                                <td>{{ formatNumber(paye.basic_salary) }}</td>
                                                <td>{{ formatNumber(paye.other_allowances) }}</td>
                                                <td>{{ formatNumber(paye.gross_salary) }}</td>
                                                <td>{{ formatNumber(paye.deductions) }}</td>
                                                <td>{{ formatNumber(paye.taxable_amount) }}</td>
                                                <td>{{ formatNumber(paye.tax_payable) }}</td>
                                           </tr>
            
                                           <tr>
                                            <td colspan="6"></td>
                                            <td><strong>{{ formatNumber(totaBasicPay()) }}</strong></td>
                                            <td><strong>{{ formatNumber(totalAllowance()) }}</strong></td>
                                            <td><strong>{{ formatNumber(totalGrossSalary()) }}</strong></td>
                                            <td><strong>{{ formatNumber(totalDeductions()) }}</strong></td>
                                            <td><strong>{{ formatNumber(totalTaxable()) }}</strong></td>
                                            <td><strong>{{ formatNumber(taxPayable()) }}</strong></td>
                                           </tr>
                                        </tbody>
                                    </table>
                                    <div class="row mt-3">
                                        <div class="col-md-4" v-if="isPrinting">
                                            <button type="button" disabled class="btn btn-info btn-sm hide-on-pdf" @click="printPdf"><i class="fas fa-print"></i> Print</button>
                                            <!-- <button type="button" disabled class="btn btn-secondary btn-sm ml-3 hide-on-pdf">Export to Excel</button> -->
                                        </div>
                                        <div class="col-md-4" v-else>
                                            <button type="button" class="btn btn-info btn-sm hide-on-pdf" @click="printPdf"><i class="fas fa-print"></i> Print</button>
                                            <!-- <button type="button" class="btn btn-secondary btn-sm ml-3 hide-on-pdf">Export to Excel</button> -->
                                        </div>
                                        <pulse-loader 
                                            :loading="isPrinting" 
                                            :color="color"
                                            v-if="isPrinting" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// import ContentLoader from '../../components/ContentLoader.vue'
// import Swal from 'sweetalert2';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        // ContentLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            date: '',
            month: '',
            isLoading: false,
            isPrinting: false,
            payrollId: this.$route.params.id,
            payes: [],
            color: '#40aac7'
        }
    },

    mounted(){
        this.fetchPaye();
    },

    methods: {

        async fetchPaye(){
            this.isLoading = true
            await Api().get(`get-paye/${this.payrollId}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.payes = res.data
                    this.month = res.data[0].payrolls.month
                    this.date = res.data[0].payrolls.date
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to get sdl informations.', error)
                }
            })
        },
       
        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        },

        totaBasicPay(){
            return this.payes.reduce((sum, item)=>{
                return sum + Number(item.basic_salary);
            },0)
        },

        totalAllowance(){
            return this.payes.reduce((sum, item)=>{
                return sum + Number(item.other_allowances);
            },0)
        },


        totalGrossSalary(){
            return this.payes.reduce((sum, item)=>{
                return sum + Number(item.gross_salary);
            },0)
        },

        totalDeductions(){
            return this.payes.reduce((sum, item)=>{
                return sum + Number(item.deductions);
            },0)
        },

        totalTaxable(){
            return this.payes.reduce((sum, item)=>{
                return sum + Number(item.taxable_amount);
            },0)
        },

        taxPayable(){
            return this.payes.reduce((sum, item)=>{
                return sum + Number(item.tax_payable);
            },0)
        },

        formateDate(dateString){
            const date = new Date(dateString)
            return date.getFullYear();
        },

        async printPdf(){
            // Hide buttons
            this.isPrinting = true
            const buttons = document.querySelectorAll('.hide-on-pdf');
            buttons.forEach(button => button.style.display = 'none');

            const element = document.getElementById('table-content');
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/png');
            
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt',
                format: 'a4'
            });

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      
            // Convert the PDF to a Blob to create a URL for preview
            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new window or tab for preview
            const pdfWindow = window.open(pdfUrl, '_blank');

            if (pdfWindow) {
                this.isPrinting = false
                // Automatically print the PDF after opening the preview
                pdfWindow.addEventListener('load', () => {
                // pdfWindow.print();
                });
            } else {
                this.isLoading = false
                // If the window couldn't be opened (e.g., due to pop-up blockers)
                alert('Please allow pop-ups to preview and print the PDF.');
            }

            // Show buttons again
            buttons.forEach(button => button.style.display = 'inline-block');
        },

    }
}
</script>

<style scoped>
    /* #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    } */
</style>