<template>
    <div class="card chart-wrapper">
      <div class="card-body">
        <span>Gender Distribution</span>
        <apexchart
          v-if="chartDataLoaded"
          type="pie"
          :options="chartOptions"
          :series="series"
        />
        <div v-else>Loading...</div>
      </div>
    </div>
  </template>
  
  <script>
  import VueApexCharts from 'vue3-apexcharts';
  import Api from '../services/Api'; // Make sure to configure your Axios API service here
  
  export default {
    components: {
      apexchart: VueApexCharts
    },
    props: {
      apiUrl: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        series: [], // Will store gender counts (male, female)
        chartOptions: {
          chart: {
            type: 'pie',
            height: 200
          },
          labels: ['Male', 'Female'], // Gender labels
          colors: ['#008FFB', '#FF4560'], // Blue for male, red for female
          responsive: [
            {
              breakpoint: 768,
              options: {
                chart: {
                  height: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            },
            {
              breakpoint: 480,
              options: {
                chart: {
                  height: 180
                },
                legend: {
                  position: 'bottom',
                  fontSize: '10px'
                }
              }
            }
          ]
        },
        chartDataLoaded: false // Track loading state
      };
    },
  
    async mounted() {
      try {
        // Fetch the gender data from the Laravel API
        const response = await Api().get(this.apiUrl);
  
        const { male, female } = response.data;
  
        // Set the series with male and female counts
        this.series = [male, female];
        this.chartDataLoaded = true;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };
  </script>
  
  <style scoped>
  .chart-wrapper {
    width: 65%;
    margin: 0 auto;
    max-width: 100%;
  }
  </style>
  