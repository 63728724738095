<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="row justify-content-center" style="margin-top: 6%;" v-if="!preview_payroll">
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span style="font-weight: 600;">Prepare Payroll</span>
                                    </div>
                                    <div class="col-md-2">
                                        <RouterLink to="/payroll-list">
                                            <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                        </RouterLink>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-6">
                                    <div class="card-body">
                                        <datepicker v-model="month"
                                            placeholder="Select Month"
                                            :minimum-view="'month'"
                                            :maximum-view="'month'"
                                            @changedMonth="handleChangedMonth"
                                        /><br>
                                        <button type="button" class="btn btn-info btn-sm mt-3" @click="previewPayroll">Preview</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div class="row mt-2" v-if="preview_payroll">
                    <div class="col-md-12">
                        <div class="card" style="margin-left: 5px; margin-right: 5px;">
                            <div class="card-header">
                                <span style="font-weight: 600;">Payroll Preview</span>
                            </div>
                            <pulse-loader 
                                :loading="isLoading" 
                                :color="color"
                                v-if="isLoading" 
                                style="margin-top: 50px; margin-left: 40%;"
                            />
                            <div class="card-body" v-else>
                                <div class="table-responsive">
                                    <table id="example1" class="table table-bordered table-hover table-sm">
                                        <tr>
                                            <th>Employee Name</th>
                                            <th>Basic Salary</th>
                                            <th>Allowance</th>
                                            <th>Gross Salary</th>
                                            <th>Nssf</th>
                                            <th>Taxable Income</th>
                                            <th>Payee Tax</th>
                                            <th>Wcf</th>
                                            <th>Other Deductions</th>
                                            <th>Total Deductions</th>
                                            <th>Net Pay</th>
                                        </tr>
            
                                        <tbody>
                                            <tr v-for="(preview, i) in previews" :key="i">
                                                <td>{{ preview.first_name }} {{ preview.middle_name }} {{ preview.last_name }}</td>
                                                <td>{{ formatNumber(preview.basic_salary) }}</td>
                                                <td>{{ formatNumber(preview.allowance) }}</td>
                                                <td>{{ formatNumber(preview.gross_salary) }}</td>
                                                <td>{{ formatNumber(preview.nssf) }}</td>
                                                <td>{{ formatNumber(preview.taxable_income) }}</td>
                                                <td>{{ formatNumber(preview.payee_tax) }}</td>
                                                <td>{{ formatNumber(preview.wcf) }}</td>
                                                <td>{{ formatNumber(preview.other_deductions) }}</td>
                                                <td>{{ formatNumber(preview.total_deductions )}}</td>
                                                <td>{{ formatNumber(preview.net_pay) }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Total</b></td>
                                                <td><b>{{ formatNumber(calculateTotalBasicSalary()) }}</b></td>
                                                <td><b>{{ formatNumber(calculateTotalAllowance()) }}</b></td>
                                                <td><b>{{ formatNumber(calculateTotalGrossSalary()) }}</b></td>
                                                <td><b>{{ formatNumber(calculateTotalNssf()) }}</b></td>
                                                <td><b>{{ formatNumber(totalTaxableIncome()) }}</b></td>
                                                <td><b>{{ formatNumber(totalPayee()) }}</b></td>
                                                <td><b>{{ formatNumber(totalWcf()) }}</b></td>
                                                <td><b>{{ formatNumber(otherDeductions()) }}</b></td>
                                                <td><b>{{ formatNumber(totalOtherDeductions()) }}</b></td>
                                                <td><b>{{ formatNumber(totalNetPay()) }}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-8">
                                    <button type="button" class="btn btn-info btn-sm" @click="back"><i class="fas fa-chevron-left"></i> Back</button>
                                    <button type="button" class="btn btn-success btn-sm ml-2" @click="savePayroll">Submit For Approval</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Datepicker from 'vuejs3-datepicker';
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from 'sweetalert2';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default {
    components: {
        Datepicker,
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },

    data(){
        return {
            month: '',
            preview_payroll: false,
            isLoading: false,
            color: '#40aac7',
            previews: [],
            errors: ''
        }
    },

    mounted(){
        this.fetchPreviewData();
    },

    setup(){
      function handleChangedMonth(payload) {
        console.log( payload )
      }
      return {handleChangedMonth}
    },

    methods: {
        async fetchPreviewData(){
            this.isLoading = true
            await Api().get('preview-payroll')
            .then(res => {
                if(res.status === 200){
                    this.isLoading = false
                    this.previews = res.data
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.error('Failed to fetch preview data.', error)
                }
            })
        },

        async savePayroll(){
            this.isLoading = true
            const date = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(this.month);
            await Api().post('payrolls',{month: date, data: this.previews})
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.preview_payroll = false
                    Swal.fire({
                        title: "Successfully!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });
                    this.$router.push('/payroll-list')
                }else{
                    this.isLoading = false
                    this.preview_payroll = false
                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                    });
                }
            }).catch(error =>{
                if(error.response.status === 201){
                    this.isLoading = false
                    this.preview_payroll = false
                    this.errors = error.response.data.error
                }
            })
        },

        back(){
            this.preview_payroll = false
        },

        previewPayroll(){
            this.preview_payroll = true
        },

        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        },

        calculateTotalBasicSalary(){
            return this.previews.reduce((sum, item)=>{
                return sum + Number(item.basic_salary);
            },0)
        },

        calculateTotalAllowance(){
            return this.previews.reduce((sum, item)=>{
                return sum + Number(item.allowance);
            },0)
        },

        calculateTotalGrossSalary(){
            return this.previews.reduce((sum, item)=>{
                return sum + Number(item.gross_salary);
            },0)
        },

        calculateTotalNssf(){
            return this.previews.reduce((sum, item)=>{
                return sum + Number(item.nssf);
            },0)
        },

        totalTaxableIncome(){
            return this.previews.reduce((sum, item)=>{
                return sum + Number(item.taxable_income);
            },0)
        },

        totalPayee(){
            return this.previews.reduce((sum, item)=>{
                return sum + Number(item.payee_tax);
            },0)
        },

        totalWcf(){
            return this.previews.reduce((sum, item)=>{
                return sum + Number(item.wcf);
            },0)
        },

        otherDeductions(){
            return this.previews.reduce((sum, item)=>{
                return sum + Number(item.other_deductions);
            },0)
        },

        totalOtherDeductions(){
            return this.previews.reduce((sum, item)=>{
                return sum + Number(item.total_deductions);
            },0)
        },

        totalNetPay(){
            return this.previews.reduce((sum, item)=>{
                return sum + Number(item.net_pay);
            },0)
        },
    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>