<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-light-primary elevation-1 bg-info" style="position: fixed;">
    <!-- Brand Logo -->
    <RouterLink to="/" class="brand-link">
      <img src="../assets/logo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-1" style="opacity: .8">
      <span class="brand-text font-weight-light" style="font-weight: 400 !important;">Depima Software</span>
    </RouterLink>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item">
            <RouterLink class="nav-link" to="/">
              <i class="nav-icon fas fa-th" style="color: #ecbe06 !important;"></i>
              <p style="color: #FFFFFF !important;">
                Dashboard
              </p>
            </RouterLink>
          </li>
          <li v-if="hasPermission('customer_suppliers')" class="nav-item">
            <RouterLink class="nav-link" to="/customers-list">
              <i class="nav-icon fas fa-user-plus" style="color: #ecbe06 !important;"></i>
              <p style="color: #FFFFFF !important;">
                Customers & Suppliers
              </p>
            </RouterLink>
          </li>
          <li v-if="hasPermission('sales_management')" class="nav-item">
            <RouterLink class="nav-link" to="/new-sales">
              <i class="nav-icon fas fa-folder" style="color: #ecbe06 !important;"></i>
              <p style="color: #FFFFFF !important;">
                Sales Management
              </p>
            </RouterLink>
          </li>
          <li v-if="hasPermission('expenses_management')" class="nav-item">
            <RouterLink class="nav-link" to="/request-expenses">
              <i class="nav-icon fas fa-eye-dropper" style="color: #ecbe06 !important;"></i>
              <p style="color: #FFFFFF !important;">
                Expenses Management
              </p>
            </RouterLink>
          </li>
          <li v-if="hasPermission('product_services')" class="nav-item">
            <RouterLink class="nav-link" to="/products-list">
              <i class="nav-icon fa fa-wallet" style="color: #ecbe06 !important;"></i>
              <p style="color: #FFFFFF !important;">
                Products / Services
              </p>
            </RouterLink>
          </li>
          <li v-if="hasPermission('purchases_order')" class="nav-item">
            <RouterLink class="nav-link" to="/new-purchases-order">
              <i class="nav-icon fas fa-marker" style="color: #ecbe06 !important;"></i>
              <p style="color: #FFFFFF !important;">
                Purchases Order
              </p>
            </RouterLink>
          </li>
          <li v-if="hasPermission('accounting_management')" class="nav-item">
            <RouterLink class="nav-link" to="/account-list">
              <i class="nav-icon fas fa-calculator" style="color: #ecbe06 !important;"></i>
              <p style="color: #FFFFFF !important;">
                Accounting Management
              </p>
            </RouterLink>
          </li>
          <li v-if="hasPermission('stock_management')" class="nav-item">
            <RouterLink class="nav-link" to="/stock-list">
              <i class="nav-icon fas fa-layer-group" style="color: #ecbe06 !important;"></i>
              <p style="color: #FFFFFF !important;">
                Stock Management
              </p>
            </RouterLink>
          </li>
          <li v-if="hasPermission('employee_self_service') && currentUser.package_type != 'Sungura' && currentUser.user_type != 'Normal'" class="nav-item">
            <RouterLink class="nav-link" to="/self-services">
              <i class="nav-icon fab fa-usps" style="color: #ecbe06 !important;"></i>
              <p style="color: #FFFFFF !important;">
                Employee Self Service
              </p>
            </RouterLink>
          </li>
          <li v-if="hasPermission('payroll_management') && currentUser.package_type != 'Sungura'" class="nav-item">
            <RouterLink class="nav-link" to="/payroll-list">
              <i class="nav-icon fa fa-tag" style="color: #ecbe06 !important;"></i>
              <p style="color: #FFFFFF !important;">
                Payroll Management
              </p>
            </RouterLink>
          </li>
          <li v-if="hasPermission('human_resources') && currentUser.package_type != 'Sungura'" class="nav-item">
            <RouterLink class="nav-link" to="/hr-dashboard">
              <i class="nav-icon fa fa-vial" style="color: #ecbe06 !important;"></i>
              <p style="color: #FFFFFF !important;">
                Huma Resourses
              </p>
            </RouterLink>
          </li>
          <li v-if="hasPermission('user_access')" class="nav-item">
            <RouterLink class="nav-link" to="/users-list">
              <i class="nav-icon fa fa-user" style="color: #ecbe06 !important;"></i>
              <p style="color: #FFFFFF !important;">
                User Access
              </p>
            </RouterLink>
          </li>
          <li v-if="hasPermission('settings_access')" class="nav-item">
            <RouterLink class="nav-link" to="/settings">
              <i class="nav-icon fas fa-cog" style="color: #ecbe06 !important;"></i>
              <p style="color: #FFFFFF !important;">
                Settings
              </p>
            </RouterLink>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
// import Api from '../services/Api';
import store from '../store/store';
import { mapGetters } from 'vuex';


export default {
  data(){
    return{
      data: {
        // userId: store.state.user.id,
      },
      permissions: [],
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    hasPermission(permissionKey) {
      return store.state.permissions.some(permission => permission.key === permissionKey && permission.value);
    }
  }
}
</script>

<style>
  .da{
    color: #ffff00
  }
</style>