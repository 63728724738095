<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6 col-md-3 col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Expenses Report</span>
                                        </div>
                                        <div class="col-md-2">
                                            <RouterLink to="/request-expenses">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-chevron-left"></i> Back</button> 
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <date-picker v-model:value="data.startDate" 
                                                    value-type="YYYY-MM-DD" 
                                                    placeholder="From"
                                                    @change="onStartDateChange"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <date-picker v-model:value="data.endDate" 
                                                value-type="YYYY-MM-DD" 
                                                placeholder="To"
                                                @change="onEndDateChange"
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    <pulse-loader 
                                        :loading="isLoading" 
                                        :color="color"
                                        v-if="isLoading" 
                                        style="margin-top: 50px; margin-left: 40%;"
                                    />
            
                                    <table v-else id="example1" class="table table-hover table-sm table-sm table-responsive-sm">
                                        <tr>
                                            <th>Date</th>
                                            <th>Expense Name</th>
                                            <th>Reference No</th>
                                            <th>Category</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                        </tr>
            
                                        <tbody >
                                            <tr v-for="(expense, i) in expenses" :key="i">
                                                <td>{{ expense.date }}</td>
                                                <td>{{ expense.expenses.expenses_name }}</td>
                                                <td>{{ expense.ref_no }}</td>
                                                <td>{{ expense.categories.category_name }}</td>
                                                <td>{{ expense.description }}</td>
                                                <td>{{ formatNumber(expense.amount) }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="4"></td>
                                                <td align="center"><b>TOTAL</b></td>
                                                <td class="double-line">{{ formatNumber(totalExpenses) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import DatePicker from 'vue-datepicker-next';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import 'vue-datepicker-next/index.css';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        DatePicker,
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
                startDate: '',
                endDate: '',
            },
            expenses: [],
            isLoading: '',
            color: '#40aac7',
            totalExpenses: 0,
           
        }
    },

    methods: {
        onEndDateChange(){
            if(this.data.endDate){
                this.search();
            }
        },

        onStartDateChange(){
            if(this.data.startDate){
                this.search();
            }
        },

        async search(){
           if(!this.data.startDate || !this.data.endDate){
            return;
           }
           this.isLoading = true
           await Api().post('search-expenses-report',this.data)
           .then(res => {
                if(res.status === 200){
                    this.isLoading = false
                    this.expenses = res.data.expenses
                    this.totalExpenses = res.data.totalExpenses
                }
           }).catch(error =>{
            if(error){
                this.isLoading = false
                console.log('Failed to search expenses.',error)
            }
           })
        },

        // total(){
        //     return this.expenses.reduce((sum ,expense) =>{
        //         return sum + expense.amount;
        //     },0)
        // },

        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        },
    }
}
</script>

<style scoped>
   .table {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: rgb(242, 245, 248);
    background-color: rgb(242, 247, 250);
}

.table tbody tr td{
    border: none !important;
    font-size: 13px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.table tbody tr th{
    background-color: #e7e7f4;;
    font-size: 13px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.double-line{
   position: relative;
   height: 50px;
}

.double-line::before, .double-line::after{
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    border-top: 1px solid black;
}

.double-line::before{
    bottom: 50%;
}
</style>