<template>
     <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-12" style="margin-bottom: 4%;">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <span>New Purchases Order</span>
                                        </div>
                                        <div class="col-md-3">
                                            <RouterLink to="/purchases-order-list" v-if="hasPermission('purchasing_order_lists')" >
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-poll"></i> Purchasing Order Lists</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
            
                                <div class="card-body" v-else>
                                    <div class="table-responsive">
                                        <table class="table table-sm">
                                            <tr>
                                                <td>Product Name</td>
                                                <td>Sub Product Name</td>
                                                <td>Buying Price</td>
                                                <td>Selling Price</td>
                                                <td>Quantity</td>
                                                <td>Total</td>
                                                <td></td>
                                            </tr>
            
                                            <tbody>
                                                <tr v-for="(row, index) in rows" :key="index">
                                                    <td width="250">
                                                        <input
                                                            v-model="row.searchQuery"
                                                            placeholder="Search..."
                                                            @input="filterItems(index)"
                                                        />
                                                        <div class="dropdown" >
                                                            <ul>
                                                                <li
                                                                    v-for="product in row.filteredItems"
                                                                    :key="product.id"
                                                                    @click="selectItem(index, product)">
                                                                    {{ product.product_name }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div v-if="errors.productId">
                                                            <span class="text-danger">{{ errors.productId[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td  width="250">
                                                        <input
                                                            v-model="row.dependentSearchQuery"
                                                            placeholder="Search..."
                                                            @input="filterDependentData(index)"
                                                        />
                                                        <div class="dropdown" >
                                                            <ul>
                                                                <li
                                                                    v-for="sub_product in row.filteredDependentData"
                                                                    :key="sub_product.id"
                                                                    @click="onDependentItemSelect(index, sub_product)">
                                                                    {{ sub_product.sub_product_name }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div v-if="errors.sub_productId">
                                                            <span class="text-danger">{{ errors.sub_productId[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" 
                                                            :value="formattedAmount(index, 'buying_price')" 
                                                            @input="updateAmount($event.target.value, index, 'buying_price')" 
                                                        />
                                                        <div v-if="errors.buying_price">
                                                            <span class="text-danger">{{ errors.buying_price[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" 
                                                            :value="formattedAmount(index, 'selling_price')" 
                                                            @input="updateAmount($event.target.value, index, 'selling_price')" 
                                                        />
                                                        <div v-if="errors.selling_price">
                                                            <span class="text-danger">{{ errors.selling_price[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" 
                                                            v-model="row.quantity" 
                                                            @input="calculateTotalItems(index)"
                                                        />
                                                        <div v-if="errors.quantity">
                                                            <span class="text-danger">{{ errors.quantity[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" 
                                                            :value="formattedTotal(index)" 
                                                            readonly
                                                        />
                                                        <div v-if="errors.total_items">
                                                            <span class="text-danger">{{ errors.total_items[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button type="button" class="btn btn-danger btn-sm" @click="removeRow(index)"><i class="fas fa-minus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-2" v-if="submiting">
                                            <button type="button" disabled @click="addRow" class="btn btn-info btn-sm mr-5"><i class="fas fa-plus"></i> Add Row</button>
                                        </div>
                                        <div class="col-md-2" v-else>
                                            <button type="button" @click="addRow" class="btn btn-info btn-sm mr-5"><i class="fas fa-plus"></i> Add Row</button>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <label class="input-group-text" for="inputGroupSelect01">Select Supplier</label>
                                                </div>
                                                <select class="form-control" v-model="data.supplierId" :disabled="!suppliers.length">
                                                    <option v-for="(supplier, i) in suppliers" :key="i" :value="supplier.id">{{ supplier.supplier_name }}</option>
                                                </select>
                                                <div v-if="errors.supplierId">
                                                    <span class="text-danger">{{ errors.supplierId[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-4">
                                            <td>
                                                <date-picker v-model:value="data.date" 
                                                value-type="YYYY-MM-DD" 
                                                placeholder="Select Date"
                                                />
                                                <div v-if="errors.date">
                                                    <span class="text-danger">{{ errors.date[0] }}</span>
                                                </div>
                                            </td>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <label class="input-group-text" for="inputGroupSelect01">Grand Total</label>
                                                </div>
                                               <select class="form-control grand-total">
                                                    <option>{{ grandTotal }}</option>
                                               </select>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mt-2">
                                        <div class="col-md-4"></div>
                                        <div class="col-md-6">
                                            <pulse-loader 
                                                :loading="submiting" 
                                                :color="color"
                                                v-if="submiting" 
                                            />
                                        </div>
                                    </div>
            
                                    <div class="row mt-4">
                                        <div class="col-md-2" v-if="submiting">
                                            <button type="button" disabled class="btn btn-info btn-sm" @click="submitData">Submit</button>
                                        </div>
                                        <div class="col-md-2" v-else>
                                            <button type="button" class="btn btn-info btn-sm" @click="submitData">Submit</button>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="input-group">
                                                <div class="input-group-prepend" style="height: 32px;">
                                                    <span class="input-group-text" for="inputGroupSelect01">Payable Account</span>
                                                </div>
                                                <select class="form-control" style="height: 32px;" v-model="data.payable_account">
                                                    <option v-for="(account, i) in accounts" :key="i" :value="account.id">{{ account.account_name }} {{ account.account_no }} {{ account.banks.bank_name }}</option>
                                                </select>
                                                <div v-if="errors.payable_account">
                                                    <span class="text-danger">{{ errors.payable_account[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
     </body>
</template>

<script>
import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Api from '../../services/Api';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import store from '../../store/store';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        DatePicker,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
               productId: '',
               sub_productId: '',
               buying_price: '',
               selling_price: '',
               quantity: '',
               total_items: '',
               supplierId: '',
               date: '',
               payable_account: '',
               userId: store.state.user.id,
            },
            isLoading: false,
            submiting: false,
            errors: '',
            color: '#40aac7',

            products: [],
            suppliers: [],
            accounts: [],
            permissions: [],
            rows: [{
                dependentSearchQuery: '',
                filteredDependentData: [],
                searchQuery: '',
                product: '',
                productId: '',
                sub_productId: '',
                sub_product: '',
                buying_price: 0,
                selling_price: 0,
                quantity: 0,
                total: 0,
                sub_products: [],
                filteredItems: this.products,
                selectedItem: null,
            }]
        }
    },

    mounted(){
        this.fetchProducts();
        this.fetchSuppliers();
        this.fetchAccounts();
        // this.fetchPermissions();
    },

    computed: {
        grandTotal() {
            return this.formatNumber(this.rows.reduce((sum, item) => sum + item.total, 0));
        },

        // Method to get the formatted amount for a specific row and field
        formattedAmount() {
            return (index, field) => this.formatNumber(this.rows[index][field]);
        },

        formattedTotal() {
            return (index) => this.formatNumber(this.calculateTotalItems(index));
        },
    },

    methods: {
        filterItems(rowIndex) {
            const row = this.rows[rowIndex];
            if(row.searchQuery.trim() === ''){
                row.filteredItems = [];
            }else{
                row.filteredItems = this.products.filter(product =>
                    product.product_name.toLowerCase().includes(row.searchQuery.toLowerCase())
                );
            }
        },

        async selectItem(rowIndex, product) {
            this.rows[rowIndex].selectedItem = product;
            this.rows[rowIndex].productId = product.id;
            this.rows[rowIndex].searchQuery = product.product_name; // Optionally set the search query to the selected item's name
            this.rows[rowIndex].filteredItems = []; // Hide the list after selecting an item
            await Api().get(`get-sub-producby-productId/${product.id}`)
            .then(res =>{
                this.rows[rowIndex].sub_products = res.data
                // this.rows[rowIndex].sub_product = ''
            })
        },
        

        filterDependentData(rowIndex) {
            const row = this.rows[rowIndex];
            const query = row.dependentSearchQuery.toLowerCase();
            row.filteredDependentData = row.sub_products.filter(data =>
                data.sub_product_name.toLowerCase().includes(query)
            );
        },

        onDependentItemSelect(rowIndex, dependentItem) {
            const row = this.rows[rowIndex];
            row.selectedDependentItem = dependentItem;
            row.sub_productId = dependentItem.id;
            this.rows[rowIndex].filteredDependentData = [];
            this.rows[rowIndex].dependentSearchQuery = dependentItem.sub_product_name;
        },

       async fetchProducts(){
        this.isLoading = true
        await Api().get('products')
        .then(res => {
            if(res.status === 200){
                this.isLoading = false
                this.products = res.data
            }
        }).catch(error =>{
            if(error){
                this.isLoading = false
                console.log('Failed to get products information.', error)
            }
        })
       },

       async fetchAccounts(){
        this.isLoading = true
        await Api().get('accounts')
        .then(res =>{
            if(res.status === 200){
                this.isLoading = false
                this.accounts = res.data
            }
        }).catch(error =>{
            if(error){
                this.isLoading = false
                console.log('Failed to fetch account informations.', error)
            }
        })
       },

       addRow(){
        this.rows.push({
            filteredDependentData: [],
            searchQuery: '',
            dependentSearchQuery: '',
            product: '',
            productId: '',
            sub_product: '',
            buying_price: 0,
            selling_price: 0,
            quantity: 0,
            total: 0,
            sub_products: [],
            filteredItems: this.products,
            selectedItem: null,
        })
       },

       removeRow(index){
        this.rows.splice(index, 1)
       },

       async submitData(){
        this.submiting = true
        const dataToSubmit = this.rows.map(row =>({
            productId: row.productId,
            sub_productId: row.sub_productId,
            buying_price: row.buying_price,
            selling_price: row.selling_price,
            quantity: row.quantity,
            supplierId: this.data.supplierId,
            total_items: row.total,
        }))
        await Api().post('purchases',{
            data: dataToSubmit,
            supplierId: this.data.supplierId,
            grand_total: this.grandTotal,
            date: this.data.date,
            payable_account: this.data.payable_account,
        })
        .then(res =>{
            if(res.status === 200){
                this.submiting = false
                this.resetRows()
                Swal.fire({
                    title: "Successfully!",
                    text: `${res.data.message}`,
                    icon: "success"
                });
            }else{
                this.submiting = false
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                });
            }
        }).catch(error =>{
            if(error.response.status === 422){
                this.submiting = false
                this.errors = error.response.data.errors
            }
        })
       },

       resetRows(){
        this.rows = [{
            product: '',
            sub_product: '',
            buying_price: 0,
            selling_price: 0,
            quantity: 0,
            sub_products: []
        }];
        this.data.supplierId = ''
       },

       async fetchSuppliers(){
        this.isLoading = true
        await Api().get('suppliers')
        .then(res =>{
            if(res.status === 200){
                this.isLoading = false
                this.suppliers = res.data
            }
        }).catch(error =>{
            if(error){
                this.isLoading = false
                console.log('Failed to fetch supplier informations.',error)
            }
        })
       },

       calculateTotalItems(index) {
            const row = this.rows[index];
            const buyingPrice = isNaN(row.buying_price) ? 0 : row.buying_price;
            const quantity = isNaN(row.quantity) ? 0 : row.quantity;
            return row.total = buyingPrice * quantity;
        },

        formatNumber(value) {
            if (!value && value !== 0) return ''; // Handle empty or null values
            return new Intl.NumberFormat().format(value); // Format with commas
        },

        parseNumber(value) {
            let parsed = parseFloat(value.replace(/,/g, ''));
            return isNaN(parsed) ? 0 : parsed; // Parse the number, remove commas, default to 0 if NaN
        },

        updateAmount(value, index, field) {
            this.rows[index][field] = this.parseNumber(value); // Update the raw amount value for the specified field
            this.calculateTotalItems(index);
        },

        // async fetchPermissions(){
        //     this.isLoading = true
        //     await Api().get(`permissions`)
        //     .then(res =>{
        //         if(res.status === 200){
        //         this.isLoading = false
        //         this.permissions = res.data
        //         }
        //     }).catch(error =>{
        //         if(error){
        //         this.isLoading = false
        //         console.error('Failed to fetch permissions.',error)
        //         }
        //     });
        // },

        hasPermission(permissionKey) {
            return store.state.permissions.some(permission => permission.key === permissionKey && permission.value);
        }
        
    }
}
</script>

<style scoped>
select{
    border:1px solid #e3e3e3; 
    width:100%;
    border-radius: 4px;
    padding: 5px;
}

input{
    border:1px solid #e3e3e3; 
    /* margin:0; 
    border-color: rgb(208, 217, 227);
    background:transparent;  */
    width:100%;
    border-radius: 4px;
    padding: 4px;
}

/* .row {
  margin-bottom: 20px;
} */

.dropdown {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  max-height: 150px; /* Set a maximum height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 10px;
  cursor: pointer;
}

li:hover {
  background-color: #f0f0f0;
}

table tr td{
    border: none;
}
</style>