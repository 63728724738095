<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 4%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row" v-if="file_preview">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <span>Preview stock to upload</span>
                                        </div>
                                    </div>
                                </div>

                                <!-- <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                />
                                  -->
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <div class="alert alert-light" role="alert">
                                                <span>
                                                    <b>Note:</b> Do not put comma on any numeric values shuch as
                                                    buying price, selling price and quantity.
                                                </span>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <table class="table table-sm table-bordered table-hover">
                                                <thead>
                                                    <td>Product Name</td>
                                                    <td>Sub Product Name</td>
                                                    <td>Category Name</td>
                                                    <td>Product Type</td>
                                                    <td>Unity Measure</td>
                                                    <td>Buying Price</td>
                                                    <td>Quantity</td>
                                                    <td>Selling Price</td>
                                                </thead>

                                                <tbody>
                                                    <tr v-for="(stock, i) in stocks" :key="i">
                                                        <td>
                                                            <input type="text" class="form-control input-td" v-model="stock.product_name"/>
                                                            <div v-if="errors.product_name">
                                                                <span class="text-danger">{{ errors.product_name[0] }}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control input-td" v-model="stock.sub_product"/>
                                                            <div v-if="errors.sub_product">
                                                                <span class="text-danger">{{ errors.sub_product[0] }}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control input-td" v-model="stock.category_name"/>
                                                            <div v-if="errors.category_name">
                                                                <span class="text-danger">{{ errors.category_name[0] }}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control input-td" v-model="stock.product_type"/>
                                                            <div v-if="errors.product_type">
                                                                <span class="text-danger">{{ errors.product_type[0] }}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control input-td" v-model="stock.unity_measure"/>
                                                            <div v-if="errors.unity_measure">
                                                                <span class="text-danger">{{ errors.unity_measure[0] }}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control input-td" v-model="stock.buying_price"/>
                                                            <div v-if="errors.buying_price">
                                                                <span class="text-danger">{{ errors.buying_price[0] }}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control input-td" v-model="stock.quantity"/>
                                                            <div v-if="errors.quantity">
                                                                <span class="text-danger">{{ errors.quantity[0] }}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control input-td" v-model="stock.selling_price"/>
                                                            <div v-if="errors.selling_price">
                                                                <span class="text-danger">{{ errors.selling_price[0] }}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-md-12" v-if="error">
                                            <div class="alert alert-danger" role="alert">{{ error }}</div>
                                        </div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-md-3" v-if="isSubmiting">
                                            <button type="button" disabled @click="backToChooseFile" class="btn btn-info btn-sm mr-2"><i class="fas fa-chevron-left"></i> Back</button>
                                            <button type="button" disabled @click="saveStock" class="btn btn-success btn-sm">Save</button>
                                        </div>
                                        <div class="col-md-3" v-else>
                                            <button type="button" @click="backToChooseFile" class="btn btn-info btn-sm mr-2"><i class="fas fa-chevron-left"></i> Back</button>
                                            <button type="button" @click="saveStock" class="btn btn-success btn-sm">Save</button>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="input-group">
                                                <div class="input-group-prepend" style="height: 34px;">
                                                    <span class="input-group-text" for="inputGroupSelect01">Select Supplier</span>
                                                </div>
                                                <select class="form-control" style="height: 34px;" v-model="supplier_name">
                                                    <option v-for="(supplier, i) in suppliers" :key="i" :value="supplier.id">{{ supplier.supplier_name }}</option>
                                                </select><br>
                                            </div>
                                            <div v-if="errors.supplierId">
                                                <span class="text-danger">{{ errors.supplierId[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <date-picker v-model:value="date" 
                                                value-type="YYYY-MM-DD" 
                                                placeholder="Select Date"
                                            />
                                            <div v-if="errors.date">
                                                <span class="text-danger">{{ errors.date[0] }}</span>
                                            </div>
                                        </div>

                                        <pulse-loader 
                                            :loading="isSubmiting" 
                                            :color="color"
                                            v-if="isSubmiting" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row justify-content-center" v-else>
                        <div class="col-md-6" style="margin-top: 4%;">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Upload new stock</span>
                                        </div>
                                        <div class="col-md-2">
                                            <RouterLink to="/products-list">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                               
                                <div class="card-body" >
                                    <div class="row justify-content-center p-2">
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <span>Select File:</span>
                                                <input type="file" class="form-control" @change="handleFileUpload"/>
                                                <div v-if="errors.file">
                                                    <span class="text-danger">{{ errors.file[0] }}</span>
                                                </div>
                                                <div v-if="file_error">
                                                    <span class="text-danger">{{ file_error }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 mt-4">
                                            <div class="col-md-12" v-if="isLoading">
                                                <button type="button" class="btn btn-info" disabled>Proceeding...</button>
                                            </div>
                                        <div class="col-md-12" v-else>
                                            <button type="button" @click="submitFile" class="btn btn-info" ><i class="fas fa-recycle"></i> Proceed</button>
                                        </div>
                                        </div>
                                    </div>
            
                                    <div class="row mb-3">
                                        <div class="col-md-4">
                                            <button type="button" @click="downloadStockSheet" class="btn btn-info btn-sm"><i class="fas fa-download"></i> Download Stock Sheet</button>
                                        </div>
                                        <pulse-loader 
                                            :loading="isLoading" 
                                            :color="color"
                                            v-if="isLoading" 
                                        />
                                    </div>
                                    <!-- <div class="col-md-4"></div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

export default{
    components: {
        PulseLoader,
        DatePicker,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
                file: null,
            },
            isLoading: false,
            isSubmiting: false,
            errors: '',
            color: '#40aac7',
            file_error: '',
            file_preview: false,
            supplier_name: '',
            date: '',
            error: '',
            suppliers: [],
            stocks: [
                {
                product_name: '',
                sub_product: '',
                category_name: '',
                product_type: '',
                unity_measure: '',
                buying_price: '',
                quantity: '',
                selling_price: '',
                },
            ],
        }
    },

    mounted(){
        this.fetchSuppliers();
    },

    methods: {
        handleFileUpload(event) {
            this.file = event.target.files[0];
        },

        async submitFile(){
            this.isLoading = true
            if (!this.file) {
                this.isLoading = false
                this.file_preview = false
                this.file_error = "Please select a file to upload.";
                return;
            }

            const formData = new FormData();
            formData.append('file', this.file);

            await Api().post("upload-stock",formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(res =>{
                if(res.status === 200)
                this.isLoading = false
                this.stocks = res.data.stocks
                this.file_preview = true
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to fetch stock.',error.response.data.errors)
                }
            })
        },

        async saveStock(){
            this.isSubmiting = true
            await Api().post('store-stock',{
                stocks: this.stocks,
                date: this.date,
                supplierId: this.supplier_name,
            })
            .then(res =>{
                if(res.status === 200){
                    this.isSubmiting = false
                    this.file_preview = false
                    this.date = ''
                    this.supplier_name = ''
                    Swal.fire({
                        title: "Successfully!",
                        text: `${res.data.message}`,
                        icon: "success",
                    });
                }else if(res.status === 203){
                    this.isSubmiting = false
                    this.file_preview = true
                    this.error = res.data.error
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isSubmiting = false
                    this.errors = error.response.data.errors
                }else{
                    this.isSubmiting = false
                    console.log(error.response.data.errors)
                }
            })
        },

        backToChooseFile(){
            this.file_preview = false
        },

        async fetchSuppliers(){
            await Api().get("suppliers")
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.suppliers = res.data
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to fetch suppliers.',error)
                }

            })
        },
        
        downloadStockSheet() {
            const fileUrl = 'https://erp-api.depima.co.tz/sheet/STOCK_SHEET.csv'; // Replace with your actual file URL
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', 'stock.csv'); // Set the desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

    }
}
</script>

<style scoped>
    .input-td{
        border: none;
        height: 20px;
    }
</style>