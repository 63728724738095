<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <!-- <content-loader v-if="isLoading"/> -->
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Employees List</span>
                                        </div>
                                        <div class="col-md-2">
                                            <RouterLink to="/hr-dashboard">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                            </RouterLink>
                                            <!-- <RouterLink to="/import-employee">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fas fa-cloud-download-alt"></i> Import Employees</button> 
                                            </RouterLink>
                                            <RouterLink to="/new-employee">
                                                <button type="button" class="btn btn-info btn-sm" ><i class="fa fa-user-plus"></i> New Employee</button> 
                                            </RouterLink> -->
                                        </div>
                                    </div>
                                </div>
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
            
                                <div class="card-body" v-else>
                                    <div class="row mb-1">
                                        <div class="col-md-3">
                                            <input type="text" v-model="searchQuery" placeholder="Search..." class="form-control" style="height: 35px;" />
                                        </div>
                                    </div>
                                    <table id="example1" class="table table-hover table-bordered table-striped table-sm table-sm table-responsive-sm">
                                        <tr>
                                            <th>Full Name</th>
                                            <th>Phone</th>
                                            <th>Address</th>
                                            <th>Basic Salary</th>
                                            <th>Department</th>
                                            <th v-if="hasPermission('edit_hr')">Edit</th>
                                            <th v-if="hasPermission('delete_hr')">Delete</th>
                                        </tr>
            
                                        <tbody>
                                            <tr v-for="(employee, i) in displayedEmployees" :key="i">
                                                <td>{{ employee.first_name }} {{ employee.middle_name }} {{ employee.last_name }}</td>
                                                <td>{{ employee.mobile_no }}</td>
                                                <td>{{ employee.address }}</td>
                                                <td>{{ formatNumber(employee.basic_salary) }}</td>
                                                <td>{{ employee.departiment }}</td>
                                                <td v-if="hasPermission('edit_hr')">
                                                    <RouterLink :to="{name: 'edit-employee', params: {id: employee.id}}">
                                                        <button type="button" class="btn btn-info btn-sm"><i class="fa fa-edit"></i></button>
                                                    </RouterLink>
                                                </td>
                                                <td v-if="hasPermission('delete_hr')">
                                                    <button type="button" class="btn btn-danger btn-sm" @click="deleteConfirm(employee.id, i)"><i class="fa fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Pagination 
                                        :currentPage="currentPage" 
                                        :totalPages="totalPages" 
                                        @page-change="handlePageChange" 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
// import ContentLoader from '../../components/ContentLoader.vue'
import Swal from 'sweetalert2';
import store from '../../store/store';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";
import Pagination from '../../components/Pagination.vue';

export default{
    components: {
        PulseLoader,
        // ContentLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer,
        Pagination,
    },
    data(){
        return{
            data: {
                userId: store.state.user.id,
            },
            isLoading: '',
            employees: [],
            permissions: [],
            color: '#40aac7',
            displayedEmployees: [],
            searchQuery: '',
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
        }
    },

    mounted(){
        this.fetchEmployees();
    },

    methods: {
        async fetchEmployees(){
            this.isLoading = true
            await Api().get('employees')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.employees = res.data.employees
                    this.updatePaginatedData();
                }
            }).catch(error =>{
                if(error){
                    console.log('Failed to get employees')
                }
            })
        },

        updatePaginatedData() {
            const filteredProducts = this.employees.filter(employee => 
                employee.first_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                employee.middle_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                employee.last_name.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            this.displayedEmployees = filteredProducts.slice(start, end);
            this.totalPages = Math.ceil(filteredProducts.length / this.perPage);
        },

        handlePageChange(page) {
            this.currentPage = page;
            this.updatePaginatedData();
        },

        deleteConfirm(id, index){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true
            }).then((result) => {
            if (result.isConfirmed) {
                this.deleteEmployee(id, index);
                swalWithBootstrapButtons.fire({
                title: "Deleted!",
                text: "Member has been deleted.",
                icon: "success"
                });
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire({
                title: "Cancelled",
                text: "Your member is safe",
                icon: "error"
                });
            }
            });
        },

        async deleteEmployee(id, index){
            this.isLoading = true
            await Api().delete(`employees/${id}`)
            .then(res =>{
                try{
                    if(res.status === 200){
                        this.isLoading = false
                        this.displayedEmployees.splice(index, 1)
                    }
                }catch(error){
                    console.log(error)
                }
            })
        },

        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        },

        hasPermission(permissionKey) {
            return store.state.permissions.some(permission => permission.key === permissionKey && permission.value);
        }
    },

    watch: {
        searchQuery: 'updatePaginatedData',
        currentPage: 'updatePaginatedData'
    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>