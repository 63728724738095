<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-3 col-sm-6 col-lg-12" style="margin-bottom: 1%;">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-10">
                                            <span style="font-weight: 600;">Create new employee</span>
                                        </div>
                                        <div class="col-2">
                                            <RouterLink to="/hr-dashboard">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                <!-- 
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                /> -->
            
                                <div class="card-body" >
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-hover table-sm">
                                            <tr>
                                                <td colspan="4" align="center"><span style="font-weight: 600;">Employee Informations</span></td>
                                            </tr>
            
                                            <tr>
                                                <td>First Name <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Middle Name <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Last Name <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Mobile No</td>
                                            </tr>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <input type="text" v-model="data.first_name"/>
                                                        <div v-if="errors.first_name">
                                                            <span class="text-danger">{{ errors.first_name[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.middle_name" />
                                                        <div v-if="errors.middle_name">
                                                            <span class="text-danger">{{ errors.middle_name[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.last_name" />
                                                        <div v-if="errors.last_name">
                                                            <span class="text-danger">{{ errors.last_name[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.mobile_no" />
                                                    </td>
                                                </tr>
                                            </tbody>
            
                                            <tr>
                                                <td colspan="4"></td>
                                            </tr>
            
                                            <tr>
                                                <td>Address <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Staff ID</td>
                                                <td>Tin No <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>NSSF <span style="color: red; font-weight: 600;">*</span></td>
                                            </tr>
            
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <input type="text" v-model="data.address"/>
                                                        <div v-if="errors.address" >
                                                            <span class="text-danger">{{ errors.address[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.staffId"/>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.tin_no"/>
                                                        <div v-if="errors.tin_no" >
                                                            <span class="text-danger">{{ errors.tin_no[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select v-model="data.nssf">
                                                            <option>Yes</option>
                                                            <option>No</option>
                                                        </select>
                                                        <div v-if="errors.nssf">
                                                            <span class="text-danger">{{ errors.nssf[0] }}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
            
                                            <tr>
                                                <td colspan="4"></td>
                                            </tr>
            
                                            <tr>
                                                <td>Basic Salary <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Allowance Deduction <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Allowance <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Social Sequrity No <span style="color: red; font-weight: 600;">*</span></td>
                                            </tr>
            
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <input type="text" v-model="data.basic_salary"/>
                                                        <div v-if="errors.basic_salary">
                                                            <span class="text-danger">{{ errors.basic_salary[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select v-model="data.allowance_deduction">
                                                            <option>No</option>
                                                            <option>Yes</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.allowance"/>
                                                        <div v-if="errors.allowance">
                                                            <span class="text-danger">{{ errors.allowance[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.social_seq_no"/>
                                                        <div v-if="errors.social_seq_no">
                                                            <span class="text-danger">{{ errors.social_seq_no[0] }}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
            
                                            <tr>
                                                <td colspan="4"></td>
                                            </tr>
            
                                            <tr>
                                                <td>Employment Type <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Loan Board <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Department</td>
                                                <td>Designation</td>
                                            </tr>
            
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <select v-model="data.employee_type">
                                                            <option>Permanent</option>
                                                            <option>Contract</option>
                                                        </select>
                                                        <div v-if="errors.employee_type">
                                                            <span class="text-danger">{{ errors.employee_type[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select v-model="data.loan_board">
                                                            <option>No</option>
                                                            <option>Yes</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.departiment"/>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.designation"/>
                                                    </td>
                                                </tr>
                                            </tbody>
            
                                            <tr>
                                                <td colspan="4"></td>
                                            </tr>
            
                                            <tr>
                                                <td>Other Deductions <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Bank Account No <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Bank Name <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Bank Branch <span style="color: red; font-weight: 600;">*</span></td>
                                            </tr>
            
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <input type="text" v-model="data.other_deductions"/>
                                                        <div v-if="errors.other_deductions">
                                                            <span class="text-danger">{{ errors.other_deductions[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.bank_account_no"/>
                                                        <div v-if="errors.bank_account_no">
                                                            <span class="text-danger">{{ errors.bank_account_no[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.bank_name"/>
                                                        <div v-if="errors.bank_name">
                                                            <span class="text-danger">{{ errors.bank_name[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.bank_branch"/>
                                                        <div v-if="errors.bank_branch">
                                                            <span class="text-danger">{{ errors.bank_branch[0] }}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
            
                                            <tr>
                                                <td colspan="4"></td>
                                            </tr>
            
                                            <tr>
                                                <td>Birth Date <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Contract Start Date <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Contract End Date <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>WCF</td>
                                            </tr>
            
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <date-picker v-model:value="data.birth_date" value-type="YYYY-MM-DD" />
                                                        <div v-if="errors.birth_date">
                                                            <span class="text-danger">{{ errors.birth_date[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <date-picker v-model:value="data.contract_start_date" value-type="YYYY-MM-DD" />
                                                        <div v-if="errors.contract_start_date">
                                                            <span class="text-danger">{{ errors.contract_start_date[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <date-picker v-model:value="data.contract_end_date" value-type="YYYY-MM-DD" />
                                                        <div v-if="errors.contract_end_date">
                                                            <span class="text-danger">{{ errors.contract_end_date[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select v-model="data.wcf">
                                                            <option>No</option>
                                                            <option>Yes</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                            </tbody>
            
                                            <!-- <tr>
                                                <td colspan="3" align="center" style="font-weight: 600;">Relative Informations</td>
                                            </tr> -->
            
                                            <tr>
                                                <td>Residential Status <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Relative Name <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Relative Mobile No <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Relative Address <span style="color: red; font-weight: 600;">*</span></td>
                                            </tr>
            
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <select v-model="data.residential_status">
                                                            <option>Resident</option>
                                                            <option>Non Resident</option>
                                                        </select>
                                                        <div v-if="errors.residential_status">
                                                            <span class="text-danger">{{ errors.residential_status[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.relative_full_name"/>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.relative_mobile"/>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.relative_address"/>
                                                    </td>
                                                </tr>
                                            </tbody>

                                            <tr>
                                                <td>Employee Gender <span style="color: red; font-weight: 600;">*</span></td>
                                                <td>Relative Relationship <span style="color: red; font-weight: 600;">*</span></td>
                                            </tr>
                                            
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <select v-model="data.gender">
                                                            <option>Male</option>
                                                            <option>Female</option>
                                                        </select>
                                                        <div v-if="errors.gender">
                                                            <span class="text-danger">{{ errors.gender[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="data.relationship"/>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12" v-if="error">
                                            <div class="alert alert-danger" role="alert">{{ error }}</div>
                                        </div>
                                    </div>
            
                                    <div class="row">
                                        <div class="col-md-4" v-if="isLoading">
                                            <button type="button" class="btn btn-info btn-sm" disabled>Submiting...</button>
                                        </div>
                                        <div class="col-md-4" v-else>
                                            <button type="button" class="btn btn-info btn-sm" @click="createEmployee">Submit</button>
                                        </div>
                                        <pulse-loader 
                                            :loading="isLoading" 
                                            :color="color"
                                            v-if="isLoading" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        DatePicker,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
               first_name: '',
               middle_name: '',
               last_name: '',
               gender: '',
               relationship: '',
               mobile_no: '',
               address: '',
               staffId: '',
               tin_no: '',
               nssf: 'Yes',
               wcf: 'No',
               residential_status: 'Resident',
               basic_salary: '',
               allowance_deduction: 'No',
               allowance: 0,
               social_seq_no: '',
               employee_type: '',
               loan_board: 'No',
               departiment: '',
               designation: '',
               other_deductions: 0,
               bank_account_no: '',
               bank_name: '',
               bank_branch: '',
               birth_date: '',
               contract_start_date: '',
               contract_end_date: '',
               relative_full_name: '',
               relative_mobile: '',
               relative_address: ''
            },
            isLoading: false,
            errors: '',
            color: '#40aac7',
            error: '',
        }
    },

    methods: {
        async createEmployee(){
            this.isLoading = true
            await Api().post('employees',this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    Swal.fire({
                        title: "Successfully!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });
                    this.$router.push('/hr-dashboard')
                }else if(res.status === 203){
                    this.isLoading = false
                    this.error = res.data.error
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isLoading = false
                    this.errors = error.response.data.errors
                }
            })
        }
    }
}
</script>

<style scoped>
/* .size{
    height: 32px;
    border-color: rgb(208, 217, 227);
} */

input{
    border:1px solid #e3e3e3; 
    /* margin:0; 
    border-color: rgb(208, 217, 227);
    background:transparent;  */
    width:100%;
    border-radius: 4px;
    padding: 4px;
}

select{
    border:1px solid #e3e3e3; 
    width:100%;
    border-radius: 4px;
    padding: 5px;
}
</style>