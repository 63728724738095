<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 4%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <content-loader v-if="isLoading"/>
                <div class="container-fluid" v-else>
                    <div class="row">
                        <div class="col-md-12" v-if="client.days_remaining == 0 && client.subscriptions == 'InActive'">
                            <div class="alert alert-danger" role="alert">
                                Your account is currently inactive due to a missed subscription payment. 
                                To restore full access to our services, please make the necessary payment.
                            </div>
                        </div>
                        <div class="col-md-12" v-else-if="client.days_remaining == 0">
                            <div class="alert alert-danger" role="alert">
                                Your subscription expired on {{ client.subscription_end }}. 
                                As a result, your access to premium features has been temporarily disabled. 
                                To regain full access, please renew your subscription.
                            </div>
                        </div>
                        <div class="col-md-12" v-else-if="client.days_remaining <= 5">
                            <div class="alert alert-secondary" role="alert">
                                Your current subscription plan is set to expire on {{ client.subscription_end }}. 
                                To avoid any interruption in your access, please renew your subscription as soon as possible.
                                Only <span class="badge badge-danger">{{ client.days_remaining }}</span> days remain.
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="info-box">
                                                <span class="info-box-icon bg-info" style="background-color: #171a3b !important"><i class="fab fa-wolf-pack-battalion"></i></span>
                                                <div class="info-box-content">
                                                <span class="info-box-text">Total Revenue</span>
                                                <span class="info-box-number">{{ formatNumber(total_revenues) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="info-box">
                                            <span class="info-box-icon bg-info"><i class="fas fa-wallet"></i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Total Expenses</span>
                                                    <span class="info-box-number">{{ formatNumber(total_expenses) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="info-box">
                                            <span class="info-box-icon bg-success"><i class="fas fa-university"></i></span>
                                            <div class="info-box-content">
                                            <span class="info-box-text">Total Discounts</span>
                                            <span class="info-box-number">{{ formatNumber(total_discounts) }}</span>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="info-box">
                                                <span class="info-box-icon bg-warning"><i class="fas fa-weight-hanging" style="color: #FFFFFF"></i></span>
                                        
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Total Vat</span>
                                                    <span class="info-box-number">{{ formatNumber(total_vat) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="info-box">
                                                <span class="info-box-icon bg-info" style="background-color: #171a3b !important"><i class="fab fa-wolf-pack-battalion"></i></span>
                                                <div class="info-box-content">
                                                <span class="info-box-text">Total Users</span>
                                                <span class="info-box-number">{{ formatNumber(total_users )}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="info-box">
                                            <span class="info-box-icon bg-info"><i class="fas fa-wallet"></i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Total Profit</span>
                                                    <span class="info-box-number">{{ formatNumber(net_income) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body mb-4">
                                            <div class="row justify-content-center">
                                                <BarChart apiUrl="dashboard" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row justify-content-center">
                                                <PieChart apiUrl="dashboard" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row justify-content-center">
                                                <ProfitBarChart apiUrl="dashboard" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row justify-content-center">
                                                <ExpensesPieChart apiUrl="dashboard" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body mb-4">
                                            <div class="row justify-content-center">
                                                <ExpensesBarChart apiUrl="dashboard" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- <div class="row mt-4">
                        <div class="col-md-3 col-sm-6 col-12">
                            <div class="info-box">
                                <span class="info-box-icon bg-info" style="background-color: #171a3b !important"><i class="fab fa-wolf-pack-battalion"></i></span>
                                <div class="info-box-content">
                                <span class="info-box-text">MONTHLY EXPENSES</span>
                                <span class="info-box-number">0</span>
                                </div>
                            </div>
                        </div>
                    
                        <div class="col-md-3 col-sm-6 col-12">
                            <div class="info-box">
                            <span class="info-box-icon bg-info"><i class="fas fa-wallet"></i></span>
                            <div class="info-box-content">
                            <span class="info-box-text">TOTAL EXPENSES</span>
                            <span class="info-box-number">0</span>
                            </div>
                            </div>
                        </div>
                    
                        <div class="col-md-3 col-sm-6 col-12">
                            <div class="info-box">
                            <span class="info-box-icon bg-success"><i class="fas fa-university"></i></span>
                            <div class="info-box-content">
                            <span class="info-box-text">MONTH SALES</span>
                            <span class="info-box-number">0</span>
                            </div>
                            </div>
                        </div>
                    
                        <div class="col-md-3 col-sm-6 col-12">
                            <div class="info-box">
                            <span class="info-box-icon bg-warning"><i class="fas fa-weight-hanging" style="color: #FFFFFF"></i></span>
                    
                            <div class="info-box-content">
                                <span class="info-box-text">TOTAL SALES</span>
                                <span class="info-box-number">0</span>
                            </div>
                            </div>
                        </div>
                    
                        <div class="col-lg-3 col-6">
                            <div class="small-box bg-info" style="background-color: #171a3b !important">
                            <div class="inner">
                                <h3>{{ month_invoices }}</h3>
                                <p>Month Invoices</p>
                            </div>
                            <div class="icon">
                                <i class="ion ion-bag"></i>
                            </div>
                            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                    
                        <div class="col-lg-3 col-6">
                            <div class="small-box bg-info">
                            <div class="inner">
                                <h3>{{ total_invoices }}<sup style="font-size: 20px"></sup></h3>
                                <p>Total Invoices</p>
                            </div>
                            <div class="icon">
                                <i class="ion ion-stats-bars"></i>
                            </div>
                            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                    
                        <div class="col-lg-3 col-6">
                            <div class="small-box bg-success">
                            <div class="inner">
                                <h3>{{ month_purchases }}</h3>
                                <p>Month Purchasing Orders</p>
                            </div>
                            <div class="icon">
                                <i class="ion ion-pie-graph"></i>
                            </div>
                            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                    
                        <div class="col-lg-3 col-6">
                            <div class="small-box bg-warning">
                            <div class="inner">
                                <h3>{{ total_purchases }}</h3>
                                <p>Total Purchasing Orders</p>
                            </div>
                            <div class="icon">
                                <i class="ion ion-person-add"></i>
                            </div>
                            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                    </div> -->

                    <!-- <div class="row" v-if="total_invoices == 0">
                        <div class="col-md-12">
                            <div class="alert alert-secondary" role="alert">
                                System analytics will be shown here after issue atleast one invoices.
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-bottom: 5%;" v-else>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row justify-content-center">
                                                <BarChart apiUrl="dashboard" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-6">
                            <PieChart apiUrl="dashboard" />
                        </div>
                    </div> -->
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import ContentLoader from '../components/ContentLoader.vue'
import Api from '../services/Api';
import { mapActions } from 'vuex';
import Navbar from "../components/Navbar.vue";
import Sidebar from "../components/Sidebar.vue";
import ContentHeader from "../components/ContentHeader.vue";
import Footer from "../components/Footer.vue";
import ControlSidebar from "../components/ControlSidebar.vue";
import BarChart from '../components/BarChart.vue';
import PieChart from '../components/PieChart.vue';
import ExpensesPieChart from './ExpensesPieChart.vue';
import ExpensesBarChart from './ExpensesBarChart.vue';
import ProfitBarChart from './ProfitBarChart.vue';

export default {
    components: {
        ContentLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer,
        BarChart,
        PieChart,
        ExpensesPieChart,
        ExpensesBarChart,
        ProfitBarChart,
    },

    data(){
        return{
            isLoading: false,
            client: {},
            total_invoices: 0,
            month_invoices: 0,
            total_purchases: 0,
            month_purchases: 0,
            total_revenues: 0,
            total_expenses: 0,
            total_discounts: 0,
            total_users: 0,
            total_customers: 0,
            total_vat: 0,
            net_income: 0,
        }
    },

    mounted(){
        this.fetchClient();
        this.fetTotalInvoices();
    },

    methods: {
        ...mapActions(['updateClient']),
       async fetchClient(){
        this.isLoading = true
        await Api().get('get-client')
        .then(res =>{
            if(res.status === 200){
                this.isLoading = false
                this.client = res.data
                this.updateClient(res.data)
            }
        }).catch(error =>{
            if(error){
                this.isLoading = false
                console.log('Failed to detch client info.',error)
            }
        })
       },

       async fetTotalInvoices(){
         this.isLoading = true
         await Api().get('dashboard')
         .then(res =>{
            if(res.status === 200){
                this.isLoading = false
                this.total_invoices = res.data.invoices_count
                this.month_invoices = res.data.month_invoices
                this.month_purchases = res.data.month_purchases
                this.total_purchases = res.data.total_purchases
                this.total_revenues = res.data.total_revenues
                this.total_expenses = res.data.total_expenses
                this.total_discounts = res.data.total_discounts
                this.total_users = res.data.total_users
                this.total_customers = res.data.total_customers
                this.total_vat = res.data.total_vat
                this.net_income = res.data.netIncome
            }
         }).catch(error =>{
            if(error){
                this.isLoading = false
                console.log('Failed to fetch total invoices.',error)
            }
         })
       },

       formatNumber(value){
            return new Intl.NumberFormat().format(value);
       },
    }
};
</script>

<style>

</style>