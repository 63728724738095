<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 2%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-10" style="margin-bottom: 2%;">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-10">
                                            <span>Billing</span>
                                        </div>
                                        <div class="col-2">
                                            <RouterLink to="/">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                        
                                <div class="card-body" >
                                    <div class="row justify-content-center">
                                        <!-- <div class="container">
                                            <div class="row justify-content-center">
                                                <div class="btn-group btn-group-toggle mb-5" data-toggle="buttons">
                                                <label class="btn btn-primary" :class="{ active: isMonthly }" @click="togglePricing('monthly')">
                                                    Monthly
                                                </label>
                                                <label class="btn btn-primary" :class="{ active: !isMonthly }" @click="togglePricing('yearly')">
                                                    Yearly
                                                </label>
                                                </div>
                                            </div>
                                            <div class="row justify-content-center">
                                                <div class="col-md-12" v-if="any_error">
                                                    <div class="alert alert-danger" role="alert">
                                                    {{ any_error }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="card mb-4 box-shadow">
                                                        <div class="card-header text-center">
                                                             <span style="font-size: 22px; font-weight: bold;">SUNGURA</span>
                                                            <div class="col-md-12">
                                                                <span style="font-size: 30px; font-weight: bold; color: #40aac7;">Tsh. {{ formatNumber(data.sunguraAmount) }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="card-body">
                                                            <span style="font-size: 20px;">✔️ Number of Products 1,000</span><br>
                                                            <span style="font-size: 20px;">✔️ Maximum Users 2</span><br>
                                                            <span style="font-size: 20px;">❌ Payroll Management</span><br>
                                                            <span style="font-size: 20px;">❌ Human Resources</span><br>
                                                            <span style="font-size: 20px;">❌ Number of Employees</span><br>
                                                            <span style="font-size: 20px;">✔️ Expenses Management</span><br>
                                                            <span style="font-size: 20px;">✔️ Accounting Management</span><br>
                                                            <span style="font-size: 20px;">✔️ Stock Management</span><br>
                                                            <div v-if="isLoading">
                                                                <button type="button" disabled class="btn btn-lg btn-block btn-info mt-3">Please wait...</button>
                                                            </div>
                                                            <div v-else>
                                                                <button type="button" class="btn btn-lg btn-block btn-outline-info mt-3" @click="updateAmount(data.sunguraAmount)">Proceed</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="card mb-4 box-shadow">
                                                        <div class="card-header text-center">
                                                        <span style="font-size: 22px; font-weight: bold;">FARASI</span>
                                                        <div class="col-md-12">
                                                            <span style="font-size: 30px; font-weight: bold; color: #40aac7;">Tsh. {{ formatNumber(data.farasiAmount) }}</span>
                                                        </div>
                                                        </div>
                                                        <div class="card-body">
                                                            <span style="font-size: 20px;">✔️ Number of Products 5,000</span><br>
                                                            <span style="font-size: 20px;">✔️ Maximum Users 10</span><br>
                                                            <span style="font-size: 20px;">✔️ Payroll Management</span><br>
                                                            <span style="font-size: 20px;">✔️ Human Resourses</span><br>
                                                            <span style="font-size: 20px;">✔️ Number of Employees 10</span><br>
                                                            <span style="font-size: 20px;">✔️ Expenses Management</span><br>
                                                            <span style="font-size: 20px;">✔️ Accounting Management</span><br>
                                                            <span style="font-size: 20px;">✔️ Stock Management</span><br>
                                                            <div v-if="isLoading">
                                                                <button type="button" disabled class="btn btn-lg btn-block btn-info mt-3">Please wait...</button>
                                                            </div>
                                                            <div v-else>
                                                                <button type="button" class="btn btn-lg btn-block btn-outline-info mt-3" @click="updateAmount(data.farasiAmount)">Proceed</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="card mb-4 box-shadow">
                                                        <div class="card-header text-center">
                                                            <span style="font-size: 22px; font-weight: bold;">TEMBO</span>
                                                            <div class="col-md-12">
                                                                <span style="font-size: 30px; font-weight: bold; color: #40aac7;">Tsh. {{ formatNumber(data.temboAmount) }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="card-body">
                                                            <span style="font-size: 20px;">✔️ Unlimited Products</span><br>
                                                            <span style="font-size: 20px;">✔️ Unlimited Users</span><br>
                                                            <span style="font-size: 20px;">✔️ Payroll Management</span><br>
                                                            <span style="font-size: 20px;">✔️ Human Resources</span><br>
                                                            <span style="font-size: 20px;">✔️ Unlimite Employees</span><br>
                                                            <span style="font-size: 20px;">✔️ Expenses Management</span><br>
                                                            <span style="font-size: 20px;">✔️ Accounting Management</span><br>
                                                            <span style="font-size: 20px;">✔️ Stock Management</span><br>
                                                            <div v-if="isLoading">
                                                                <button type="button" disabled class="btn btn-lg btn-block btn-info mt-3">Please wait...</button>
                                                            </div>
                                                            <div v-else>
                                                                <button type="button" class="btn btn-lg btn-block btn-outline-info mt-3" @click="updateAmount(data.temboAmount)">Proceed</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Subscription Amount</span>
                                                <input class="form-control"
                                                 v-model="formattedAmount"
                                                  @blur="removeFormatting('amount')" 
                                                    @focus="addFormatting('amount')"
                                                 />
                                                <div v-if="errors.amount">
                                                    <span class="text-danger">{{ errors.amount[0] }}</span>
                                                </div>
                                                <div >
                                                    <span class="text-danger">{{ amount_error }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="row justify-content-center mt-2">
                                        <div class="col-md-6">
                                            <span>Subscription minimum amount is <b>Tsh 45,000</b> per month.</span><br>
                                            <span>Also you can pay <b>Tsh 500,000</b> per year.</span>
                                        </div>
                                    </div> -->
                                    <div class="row mt-3">
                                        <div class="col-md-4" v-if="isLoading">
                                            <button type="button" disabled class="btn btn-info btn-sm">Please wait...</button>
                                        </div>
                                        <div class="col-md-4" v-else>
                                            <button type="button" @click="initiateBilling" class="btn btn-info btn-sm">Proceed</button>
                                        </div>
                                        <pulse-loader 
                                            :loading="isLoading" 
                                            :color="color"
                                            v-if="isLoading" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
// import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
                amount: '',
                sunguraAmount: 15000,
                farasiAmount: 37500,
                temboAmount: 45000,
            },
            isMonthly: true,
            sunguraMonthlyPrice: 15000,
            sunguraYearlyPrice: 160000,
            farasiMonthlyPrice: 37500,
            farasiYearlyPrice: 430000,
            temboMonthlyPrice: 45000,
            temboYearlyPrice: 500000,
            isLoading: false,
            isUpdating: false,
            errors: '',
            color: '#40aac7',
            amount_error: '',
            any_error: '',
        }
    },

    computed: {
        formattedAmount: {
            get() {
                return this.formatNumber(this.data.amount);
            },

            set(value) {
                this.data.amount = this.parseNumber(value);
            }
        },
    },

    methods: {
        async initiateBilling() {
            this.isLoading = true;
            const controller = new AbortController(); // Create an AbortController instance
            const timeout = setTimeout(() => {
                controller.abort(); // Abort the request if it takes too long
            }, 20000); // Timeout set to 6000ms (6 seconds)

            try {
                const res = await Api().post('pesapal/payment', this.data, {
                signal: controller.signal, // Attach the signal to the request
                });

                if (res.status === 200) {
                    window.location.href = res.data.redirect_url;
                    this.data.amount = 0;
                } else {
                    this.amount_error = res.data.error;
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    // Handle request timeout scenario
                    this.any_error = 'The request took too long and was aborted.';
                } else if (error.code === 'ERR_NETWORK' || error.message.includes('ERR_CONNECTION_TIMED_OUT')) {
                    // Handle connection timeout error
                    this.any_error = 'The connection timed out. Please check your internet connection and try again.';
                } else if (error.response && error.response.status === 422) {
                    this.errors = error.response.data.errors;
                } else if (error.response) {
                    this.any_error = error.response.data.error;
                } else {
                    this.any_error = 'An unexpected error occurred.';
                }
            } finally {
                clearTimeout(timeout); // Clear the timeout to avoid memory leaks
                this.isLoading = false;
            }
        },


        // async initiateBilling(){
        //     this.isLoading = true
        //     await Api().post('pesapal/payment',this.data)
        //     .then(res =>{
        //         if(res.status === 200){
        //             window.location.href = res.data.redirect_url;
        //             // window.open(res.data.redirect_url, '_blank');
        //             this.isLoading = false
        //             this.data.amount = 0
        //         }else{
        //             this.isLoading = false
        //             this.amount_error = res.data.error
        //         }
        //     }).catch(error => {
        //         if(error.response.status === 422){
        //             this.isLoading = false
        //             this.errors = error.response.data.errors
        //         }else{
        //             this.isLoading = false
        //             this.any_error = error.response.data.error
        //         }
        //     });
        // },

        async createDpoToken(){
            this.isLoading = true
            await Api().post('create-pdo-token',this.data)
            .then(res =>{
                if(res.status === 200){
                    // window.location.href = res.data.redirect_url;
                    window.open(res.data.paymentUrl, '_blank');
                    this.isLoading = false
                    this.data.amount = 0
                }else{
                    this.isLoading = false
                    this.amount_error = res.data.error
                }
            }).catch(error => {
                if(error.response.status === 422){
                    this.isLoading = false
                    this.errors = error.response.data.errors
                }else{
                    this.isLoading = false
                    this.any_error = error.response.data.error
                }
            });
        },

        async azamInitPayment(){
            this.isLoading = true
            await Api().post('azam-init-payment',this.data)
            .then(res =>{
                if(res.status === 200){
                    // window.location.href = res.data.redirect_url;
                    window.open(res.data.paymentUrl, '_blank');
                    this.isLoading = false
                    this.data.amount = 0
                }else{
                    this.isLoading = false
                    this.amount_error = res.data.error
                }
            }).catch(error => {
                if(error.response.status === 422){
                    this.isLoading = false
                    this.errors = error.response.data.errors
                }else{
                    this.isLoading = false
                    this.any_error = error.response.data.error
                }
            });
        },

        // Method to update price based on clicked card
        updateAmount(price) {
            this.data.amount = price;  // Update the amount with the clicked card's price
            this.initiateBilling();
            // console.log("Updated amount: ", this.data.amount); 
        },

        togglePricing(option) {
            if (option === 'monthly') {
                this.isMonthly = true;
                this.data.sunguraAmount = this.sunguraMonthlyPrice;
                this.data.farasiAmount = this.farasiMonthlyPrice;
                this.data.temboAmount = this.temboMonthlyPrice;
            } else if (option === 'yearly') {
                this.isMonthly = false;
                this.data.sunguraAmount = this.sunguraYearlyPrice;
                this.data.farasiAmount = this.farasiYearlyPrice;
                this.data.temboAmount = this.temboYearlyPrice;
            }
        },

        formatNumber(value) {
            return new Intl.NumberFormat().format(value);
        },
        

        // parseNumber(value) {
        //     return parseFloat(value.replace(/,/g, '')) || 0;
        // },

        parseNumber(value) {
            if (typeof value !== 'string') {
                value = String(value);
            }
            return parseFloat(value.replace(/,/g, '')) || 0;
        },
        

        addFormatting(fieldName) {
            this.data[fieldName] = this.formatNumber(this.data[fieldName]);
        },

        removeFormatting(fieldName) {
            this.data[fieldName] = this.parseNumber(this.data[fieldName]);
        },
    }
}
</script>