<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-4 col-sm-6 col-lg-9" style="margin-top: 2%; margin-bottom: 4%;">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-10">
                                            <span>Create new product information</span>
                                        </div>
                                        <div class="col-2">
                                            <RouterLink to="/products-list">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
            
                                <div class="card-body" v-else>
                                    <div class="row justify-content-center p-2">
                                        <div class="col-md-12" v-if="error">
                                            <div class="alert alert-danger" role="alert">{{ error }}</div>
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Product Category</span>
                                                <select class="form-control" v-model="data.category_name">
                                                    <option v-for="(category, i) in categories" :key="i" :value="category.id">{{ category.category_name }}</option>
                                                </select>
                                                <div v-if="errors.category_name">
                                                    <span class="text-danger">{{ errors.category_name[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Product Name</span>
                                                <input class="form-control" v-model="data.product_name"/>
                                                <div v-if="errors.product_name">
                                                    <span class="text-danger">{{ errors.product_name[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                
                                  
                                    <div class="row justify-content-center p-2">
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Product Type</span>
                                                <select class="form-control" v-model="data.product_type">
                                                    <option>Goods</option>
                                                    <option>Services</option>
                                                </select>
                                                <div v-if="errors.product_type">
                                                    <span class="text-danger">{{ errors.product_type[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <!-- <div class="form-group">
                                                <span>Unit Measures</span>
                                                <select class="form-control" v-model="data.unit_measure">
                                                    <option>PC</option>
                                                    <option>CM</option>
                                                    <option>M</option>
                                                    <option>MM</option>
                                                    <option>BOX</option>
                                                    <option>CARTON</option>
                                                </select>
                                                <div v-if="errors.unit_measure">
                                                    <span class="text-danger">{{ errors.unit_measure[0] }}</span>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                
                                    <div class="row justify-content-center mb-3">
                                        <div class="col-4" v-if="isCreatinProduct">
                                            <button type="button" class="btn btn-info btn-sm" disabled>Submiting...</button>
                                        </div>
                                        <div class="col-4" v-else>
                                            <button type="button" class="btn btn-info btn-sm" @click="createProduct">Submit</button>
                                        </div>
                                        <pulse-loader 
                                            :loading="isCreatinProduct" 
                                            :color="color"
                                            v-if="isCreatinProduct" 
                                        />
                                        <div class="col-6">
                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
                product_name: '',
                product_type: '',
                category_name: '',
                // unit_measure: '',
            },
            categories: [],
            isLoading: false,
            isCreatinProduct: false,
            errors: '',
            color: '#40aac7',
            error: '',
        }
    },

    mounted(){
        this.fetchCategory();
    },

    methods: {
        async fetchCategory(){
            this.isLoading = true
            await Api().get('categories')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.categories  = res.data
                }
            }).catch(error => {
                if(error){
                    this.isLoading = false
                    console.log('Failed to fetch categories information.',error)
                }
            })
        },

        async createProduct(){
            this.isCreatinProduct = true
            await Api().post('products',this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isCreatinProduct  = false
                    Swal.fire({
                        title: "Successfully!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });
                    this.data.category_name = ''
                    this.data.product_name = ''
                    this.data.product_type = ''
                    // this.$router.push('/products-list')
                }
                if(res.status === 201){
                    this.isCreatinProduct = false
                    this.error = res.data.error
                    // Swal.fire({
                    // icon: "error",
                    // title: "Error",
                    // text: `${res.data.error}`,
                    // });
                }else if(res.status === 203){
                    this.isCreatinProduct = false
                    this.error = res.data.error
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isCreatinProduct = false
                    this.errors = error.response.data.errors
                }
            })
        }
    }
}
</script>