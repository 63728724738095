<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card" style="margin-left: 5px; margin-right: 5px;">
                            <div class="card-header">
                                <div class="row justify-content-end">
                                    <div class="col-md-10">
                                        <span style="font-weight: 600;">Payroll</span>
                                    </div>
                                    <div class="col-md-2">
                                        <RouterLink to="/payroll-list">
                                            <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                        </RouterLink>
                                    </div>
                                </div>
                            </div>
                            <pulse-loader 
                                :loading="isLoading" 
                                :color="color"
                                v-if="isLoading" 
                                style="margin-top: 50px; margin-left: 40%;"
                            />
                            <div class="card-body" id="table-content" v-else>
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-4">
                                        <h5><strong>PAYROLL FOR {{ month }} {{ formateDate(date) }}</strong></h5>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table id="example1" class="table table-striped table-bordered table-hover table-sm">
                                        <tr>
                                            <th>Employee Name</th>
                                            <th>Basic Salary</th>
                                            <th>Allowance</th>
                                            <th>Gross Salary</th>
                                            <th>Nssf</th>
                                            <th>Taxable Income</th>
                                            <th>Payee Tax</th>
                                            <th>Wcf</th>
                                            <th>Other Deductions</th>
                                            <th>Total Deductions</th>
                                            <th>Net Pay</th>
                                        </tr>
            
                                        <tbody>
                                            <tr v-for="(payroll, i) in payrolls" :key="i">
                                                <td>{{ payroll.first_name }} {{ payroll.middle_name }} {{ payroll.last_name }}</td>
                                                <td>{{ formatNumber(payroll.basic_salary) }}</td>
                                                <td>{{ formatNumber(payroll.allowance) }}</td>
                                                <td>{{ formatNumber(payroll.gross_salary) }}</td>
                                                <td>{{ formatNumber(payroll.nssf) }}</td>
                                                <td>{{ formatNumber(payroll.taxable_income) }}</td>
                                                <td>{{ formatNumber(payroll.paye_tax) }}</td>
                                                <td>{{ formatNumber(payroll.wcf) }}</td>
                                                <td>{{ formatNumber(payroll.other_deductions) }}</td>
                                                <td>{{ formatNumber(payroll.total_deductions) }}</td>
                                                <td>{{ formatNumber(payroll.net_pay) }}</td>
                                            </tr>
            
                                            <tr>
                                                <td><b>Total</b></td>
                                                <td><b>{{ formatNumber(total_basic_salary) }}</b></td>
                                                <td><b>{{ formatNumber(total_allowance) }}</b></td>
                                                <td><b>{{ formatNumber(total_gross_salary) }}</b></td>
                                                <td><b>{{ formatNumber(total_nssf) }}</b></td>
                                                <td><b>{{ formatNumber(total_taxable_income) }}</b></td>
                                                <td><b>{{ formatNumber(total_payee) }}</b></td>
                                                <td><b>{{ formatNumber(total_wcf) }}</b></td>
                                                <td><b>{{ formatNumber(total_other_deductions) }}</b></td>
                                                <td><b>{{ formatNumber(total_deduction) }}</b></td>
                                                <td><b>{{ formatNumber(total_net_pay) }}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row" v-if="status === 'Approved'">
                                    <div class="col-md-4" v-if="isPrinting">
                                        <button type="button" disabled class="btn btn-info btn-sm hide-on-pdf" @click="printPdf"><i class="fas fa-print"></i> Print</button>
                                    </div>
                                    <div class="col-md-4" v-else>
                                        <button type="button" class="btn btn-info btn-sm hide-on-pdf" @click="printPdf"><i class="fas fa-print"></i> Print</button>
                                    </div>
                                    <pulse-loader 
                                        :loading="isPrinting" 
                                        :color="color"
                                        v-if="isPrinting" 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// import Swal from 'sweetalert2';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default {
    components: {
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },

    data(){
        return {
            month: '',
            date: '',
            status: '',
            preview_payroll: false,
            isLoading: false,
            isPrinting: false,
            color: '#40aac7',
            payrolls: [],
            payrollId: this.$route.params.id,
            total_basic_salary: 0,
            total_allowance: 0,
            total_gross_salary: 0,
            total_nssf: 0,
            total_taxable_income: 0,
            total_payee: 0,
            total_wcf: 0,
            total_other_deductions: 0,
            total_deduction: 0,
            total_net_pay: 0,
            errors: ''
        }
    },

    mounted(){
        this.fetchPayrollData();
    },

    methods: {
        async fetchPayrollData(){
            this.isLoading = true
            await Api().get(`payrolls/${this.payrollId}`)
            .then(res => {
                if(res.status === 200){
                    this.isLoading = false
                    this.payrolls = res.data
                    this.total_basic_salary = res.data[0].payrolls.total_basic_salary
                    this.total_allowance = res.data[0].payrolls.total_allowance
                    this.total_gross_salary = res.data[0].payrolls.total_gross_salary
                    this.total_nssf = res.data[0].payrolls.total_nssf
                    this.total_taxable_income = res.data[0].payrolls.total_taxable_income
                    this.total_payee = res.data[0].payrolls.total_payee
                    this.total_wcf = res.data[0].payrolls.total_wcf
                    this.total_other_deductions = res.data[0].payrolls.total_other_deductions
                    this.total_deduction = res.data[0].payrolls.total_deduction
                    this.total_net_pay = res.data[0].payrolls.total_net_pay
                    this.month = res.data[0].payrolls.month
                    this.date = res.data[0].payrolls.date
                    this.status = res.data[0].payrolls.status
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.error('Failed to fetch preview data.', error)
                }
            })
        },

        async printPdf(){
            this.isPrinting = true
            // Hide buttons
            const buttons = document.querySelectorAll('.hide-on-pdf');
            buttons.forEach(button => button.style.display = 'none');

            const element = document.getElementById('table-content');
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/png');
            
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt',
                format: 'a4'
            });

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      
            // Convert the PDF to a Blob to create a URL for preview
            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new window or tab for preview
            const pdfWindow = window.open(pdfUrl, '_blank');

            if (pdfWindow) {
                this.isPrinting = false
                // Automatically print the PDF after opening the preview
                pdfWindow.addEventListener('load', () => {
                // pdfWindow.print();
                });
            } else {
                this.isPrinting = false
                // If the window couldn't be opened (e.g., due to pop-up blockers)
                alert('Please allow pop-ups to preview and print the PDF.');
            }

            // Show buttons again
            buttons.forEach(button => button.style.display = 'inline-block');
        },


        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        },

        formateDate(dateString){
            const date = new Date(dateString)
            return date.getFullYear();
        }
    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>