<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 5%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-4 col-sm-6 col-lg-9">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-10">
                                            <span>Update user information</span>
                                        </div>
                                        <div class="col-2">
                                            <RouterLink to="/users-list">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
            
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
            
                                <div class="card-body" v-else>
                                    <div class="row justify-content-center p-2">
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Full Name</span>
                                                <input class="form-control" v-model="data.full_name"/>
                                                <div v-if="errors.full_name">
                                                    <span class="text-danger">{{ errors.full_name[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Phone</span>
                                                <input class="form-control" v-model="data.phone"/>
                                            </div>
                                        </div>
                                    </div>
                
                                    <div class="row justify-content-center p-2">
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Email</span>
                                                <input type="email" class="form-control" v-model="data.email"/>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Select Status</span>
                                                <select class="form-control" v-model="data.status">
                                                    <option>Active</option>
                                                    <option>InActive</option>
                                                </select>
                                                <div v-if="errors.status">
                                                    <span class="text-danger">{{ errors.status[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                
                                    <div class="row justify-content-center mb-3">
                                        <div class="col-4" v-if="isUpdating">
                                            <button type="button" class="btn btn-info btn-sm" disabled>Update...</button>
                                        </div>
                                        <div class="col-4" v-else>
                                            <button type="button" class="btn btn-info btn-sm" @click="updateUser">Update</button>
                                        </div>
                                        <pulse-loader 
                                            :loading="isUpdating" 
                                            :color="color"
                                            v-if="isUpdating" 
                                        />
                                        <div class="col-6">
                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
                full_name: '',
                phone: '',
                email: '',
                status: '',
                id: this.$route.params.id,
            },
            isLoading: false,
            isUpdating: false,
            errors: '',
            color: '#40aac7'
        }
    },

    mounted(){
        this.getData();
    },

    methods: {
        async getData(){
            this.isLoading = true
            Api().get(`users/${this.data.id}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.data.full_name = res.data.full_name
                    this.data.phone = res.data.phone
                    this.data.email = res.data.email
                    this.data.status = res.data.status
                }else{
                    this.isLoading = false
                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                    });
                }
            }).catch(error =>{
                if(error.response === 422){
                    this.isLoading = false
                    this.errors = error.response.data.errors
                }
            })
        },

        async updateUser(){
            this.isUpdating = true
            await Api().put(`users/${this.data.id}`,this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isUpdating = false
                    Swal.fire({
                        title: "Successfully!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });
                    this.$router.push('/users-list')
                }else{
                    this.isUpdating = false
                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                    });
                }
            }).catch(error =>{
                if(error){
                    this.isUpdating = false
                    this.errors = error.response.data.errors
                }
            })
        }
    }
}
</script>