import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate";

let timer = null;

const store = createStore({
    plugins: [createPersistedState()],
    
    state(){
        return{
            token: null,
            user: null,
            client: {},
            permissions: [],
            idleTimeout: 30 * 60 * 1000, // 15 minutes
        }
    },

    mutations: {
        setToken(state, token){
            state.token = token
        },

        clearToken(state){
            state.token = null
        },

        clearUser(state){
            state.user = null
        },

        clearUClient(state){
            state.client = null
        },

        setUser(state, user){
            state.user = user
        },

        setClient(state, client){
            state.client = client
        },

        setPermissions(state, permissions){
            state.permissions = permissions
        },

        resetTimer(state) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                state.token = null;
                // state.user = null;
                // state.client = null;
                // state.permissions = [];
            }, state.idleTimeout);
        }
    },

    actions: {
        updateToken({commit}, token){
            commit('setToken', token)
            commit('resetTimer'); // Reset the timer whenever the token is updated
        },

        deleteToken({commit}){
            commit('clearToken')
            clearTimeout(timer); // Clear the timeout if token is manually deleted
        },

        
        updateUser({commit}, user){
            commit('setUser', user);
            commit('resetTimer'); // Reset the timer whenever the user is updated
        },

        updateClient({commit}, client){
            commit('setClient', client);
            commit('resetTimer'); // Reset the timer whenever the client is updated
        },

        updatePermissions({commit}, permissions){
            commit('setPermissions', permissions)
            commit('resetTimer'); // Reset the timer whenever permissions are updated
        },
        
        deleteUser({commit}){
            commit('clearUser')
        },

        deleteClient({commit}){
            commit('clearUClient')
        },
    },

    getters: {
        token: (state) => state.token,
        currentUser: (state) => state.user,
        currentClient: (state) => state.client,
        isAuthenticated: (state) => !!state.token, // Convert token to boolean
        isClientSubscribed: (state) => {
            // Use ternary operator to handle null or undefined
            return state.client.subscriptions !== null && state.client.subscriptions !== undefined
            ? state.client.subscriptions
            : 'InActive';
        }
    }
})

export default store

