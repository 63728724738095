<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 2%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-4 col-sm-6 col-lg-9" style="margin-top: 2%; margin-bottom: 4%;">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-10">
                                            <span>Update Stock information</span>
                                        </div>
                                        <div class="col-2">
                                            <RouterLink to="/stock-list">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
            
                                <div class="card-body" v-else>
                                    <div class="row justify-content-center p-2">
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Product Name</span>
                                                <select class="form-control">
                                                    <option>{{ data.product_name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Sub Product Name</span>
                                                <select class="form-control">
                                                    <option>{{ data.sub_product_name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                
                                  
                                    <div class="row justify-content-center p-2">
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Selling Price</span>
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    v-model="formattedSellingPrice" 
                                                    @blur="removeFormatting('selling_price')" 
                                                    @focus="addFormatting('selling_price')"
                                                />
                                                <div v-if="errors.selling_price">
                                                    <span class="text-danger">{{ errors.selling_price[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Buying Price</span>
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    v-model="formattedBuyingPrice" 
                                                    @blur="removeFormatting('buying_price')" 
                                                    @focus="addFormatting('buying_price')"
                                                />
                                                <div v-if="errors.buying_price">
                                                    <span class="text-danger">{{ errors.buying_price[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="row justify-content-center p-2">
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Quantity</span>
                                                <input type="text" class="form-control" v-model="data.quantity"/>
                                                <div v-if="errors.quantity">
                                                    <span class="text-danger">{{ errors.quantity[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Porduct Type</span>
                                                <select class="form-control">
                                                    <option>{{ data.product_type }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                
                                    <div class="row justify-content-center mb-3">
                                        <div class="col-4" v-if="isUpdating">
                                            <button type="button" class="btn btn-info btn-sm" disabled>Submiting...</button>
                                        </div>
                                        <div class="col-4" v-else>
                                            <button type="button" class="btn btn-info btn-sm" @click="updateStock">Submit</button>
                                        </div>
                                        <pulse-loader 
                                            :loading="isUpdating" 
                                            :color="color"
                                            v-if="isUpdating" 
                                        />
                                        <div class="col-6">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
                productId: '',
                product_name: '',
                sub_product_name: '',
                sub_productId: '',
                selling_price: 0,
                buying_price: 0,
                product_type: '',
                quantity: 0,
                id: this.$route.params.id,
            },
            isLoading: false,
            isUpdating: false,
            errors: '',
            color: '#40aac7',
        }
    },

    mounted(){
        this.fetchStock();
    },

    computed: {
        formattedSellingPrice: {
            get() {
                return this.formatNumber(this.data.selling_price);
            },

            set(value) {
                this.data.selling_price = this.parseNumber(value);
            }
        },

        formattedBuyingPrice: {
            get() {
                return this.formatNumber(this.data.buying_price);
            },
            
            set(value) {
                this.data.buying_price = this.parseNumber(value);
            }
        },
    },

    methods: {
        async fetchStock(){
            this.isLoading = true
            await Api().get(`stocks/${this.data.id}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.data.productId = res.data[0].productId
                    this.data.sub_productId = res.data[0].sub_productId
                    this.data.product_name = res.data[0].products.product_name
                    this.data.sub_product_name = res.data[0].sub_products.sub_product_name
                    this.data.quantity = res.data[0].quantity
                    this.data.selling_price = res.data[0].selling_price
                    this.data.buying_price = res.data[0].buying_price
                    this.data.product_type = res.data[0].products.product_type
                }
            }).catch(error =>{
                if(error){
                    console.log('Failed to get users')
                }
            })
        },

        async updateStock(){
            this.isUpdating = true
            await Api().put(`stocks/${this.data.id}`,this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isUpdating = false
                    Swal.fire({
                        title: "Successfully!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });
                    // this.$router.push('/customers-list')
                }else{
                    this.isUpdating = false
                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                    });
                }
            }).catch(error =>{
                if(error){
                    this.isUpdating = false
                    this.errors = error.response.data.errors
                }
            })
        },

        formatNumber(value) {
            return new Intl.NumberFormat().format(value);
        },

        parseNumber(value) {
            return parseFloat(value.replace(/,/g, '')) || 0;
        },

        addFormatting(fieldName) {
            this.data[fieldName] = this.formatNumber(this.data[fieldName]);
        },

        removeFormatting(fieldName) {
            this.data[fieldName] = this.parseNumber(this.data[fieldName]);
        },
    }
}
</script>