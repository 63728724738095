<template>
     <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-12" style="margin-bottom: 4%;">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <span>Create an invoice</span>
                                        </div>
                                        <div class="col-md-6">
                                            <RouterLink to="/income-statement" v-if="hasPermission('sales_report')">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fa fa-record-vinyl"></i> Income Statement</button> 
                                            </RouterLink>
                                            <RouterLink to="/sales-report" v-if="hasPermission('sales_report')">
                                                <button type="button" class="btn btn-info btn-sm mr-2" ><i class="fa fa-record-vinyl"></i> Sales Report</button> 
                                            </RouterLink>
                                            <RouterLink to="/profoma-lists" v-if="hasPermission('profoma_lists')">
                                                <button type="button" class="btn btn-secondary btn-sm mr-2"><i class="fas fa-poll"></i> Profoma Invoices List</button>
                                            </RouterLink>
                                            <RouterLink to="/invoices-list" v-if="hasPermission('invoices_lists')">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-file-invoice"></i> Tax Invoices List</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
            
                                <div class="card-body" v-else>
                                    <div class="table-responsive">
                                        <table class="table table-sm">
                                            <tr>
                                                <td>Product Name</td>
                                                <td>Sub Product Name</td>
                                                <td>Selling Price</td>
                                                <td>Quantity</td>
                                                <td>Avail Qty</td>
                                                <td>Total</td>
                                                <td></td>
                                            </tr>
            
                                            <tbody>
                                                <tr v-for="(row, index) in rows" :key="index">
                                                    <td width="250">
                                                        <input
                                                            v-model="row.searchQuery"
                                                            placeholder="Search..."
                                                            @input="filterItems(index)"
                                                        />
                                                        <div class="dropdown" >
                                                            <ul>
                                                                <li
                                                                    v-for="product in row.filteredItems"
                                                                    :key="product.id"
                                                                    @click="selectItem(index, product)">
                                                                    {{ product.product_name }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div v-if="errors.productId">
                                                            <span class="text-danger">{{ errors.productId[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td  width="250">
                                                        <input
                                                            v-model="row.dependentSearchQuery"
                                                            placeholder="Search..."
                                                            @input="filterDependentData(index)"
                                                        />
                                                        <div class="dropdown" >
                                                            <ul>
                                                                <li
                                                                    v-for="sub_product in row.filteredDependentData"
                                                                    :key="sub_product.id"
                                                                    @click="onDependentItemSelect(index, sub_product)">
                                                                    {{ sub_product.sub_products.sub_product_name }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div v-if="errors.sub_productId">
                                                            <span class="text-danger">{{ errors.sub_productId[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" 
                                                            :value="formattedAmount(index)" 
                                                            @input="updateAmount($event.target.value, index)"
                                                        />
                                                        <div v-if="errors.selling_price">
                                                            <span class="text-danger">{{ errors.selling_price[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="row.quantity" @input="calculateTotalItems(index)"/>
                                                        <div v-if="errors.quantity">
                                                            <span class="text-danger">{{ errors.quantity[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" v-model="row.qty_avail" readonly/>
                                                    </td>
                                                    <td>
                                                        <input type="text" 
                                                            :value="formattedTotal(index)"
                                                            readonly
                                                        />
                                                        <div v-if="errors.total_items">
                                                            <span class="text-danger">{{ errors.total_items[0] }}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button type="button" class="btn btn-danger btn-sm" @click="removeRow(index)"><i class="fas fa-minus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-2" v-if="submiting">
                                            <button type="button" disabled @click="addRow" class="btn btn-info btn-sm mr-5"><i class="fas fa-plus"></i> Add Row</button>
                                        </div>
                                        <div class="col-md-2" v-else>
                                            <button type="button" @click="addRow" class="btn btn-info btn-sm mr-5"><i class="fas fa-plus"></i> Add Row</button>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="input-group">
                                                <input
                                                    placeholder="Search Customers..."
                                                    class="form-control customer-input"
                                                    v-model="searchCustomers"
                                                    @input="filterCustomers"
                                                />
                                                <div class="dropdown" >
                                                    <ul>
                                                        <li
                                                            v-for="customer in filteredCustomers"
                                                            :key="customer.id"
                                                            @click="selectCustomer(customer)"
                                                            >
                                                            {{ customer.customer_name }}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div v-if="errors.customerId">
                                                    <span class="text-danger">{{ errors.customerId[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
            
                                        <div class="col-md-3">
                                            <date-picker v-model:value="data.date" 
                                            value-type="YYYY-MM-DD" 
                                            placeholder="Select Date"
                                            />
                                            <div v-if="errors.date">
                                                <span class="text-danger">{{ errors.date[0] }}</span>
                                            </div>
                                        </div>
            
                                        <div class="col-md-2">
                                            <div class="input-group">
                                                <div class="input-group-prepend" style="height: 32px;">
                                                    <span class="input-group-text" for="inputGroupSelect01">Discount(%)</span>
                                                </div>
                                              <input type="text" class="form-control" style="height: 32px;" v-model="data.discountPercent"/>
                                            </div>
                                        </div>
            
                                        <div class="col-md-3">
                                            <table class="table table-hover table-bordered table-sm">
                                                <tbody>
                                                    <tr>
                                                        <td>Gand Total: </td>
                                                        <td>{{ formatNumber(grandTotal) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Discount: </td>
                                                        <td>{{ formatNumber(discountAmount) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sub Total: </td>
                                                        <td>{{ formatNumber(subTotal) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="data.applyVat">
                                                                <label class="custom-control-label" for="customCheck1">VAT(18%)</label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {{ formatNumber(vatAmount) }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Amount</td>
                                                        <td>{{ formatNumber(finalTotal) }}</td>
                                                    </tr>
                                                    <!-- <tr>
                                                        <td>Amount Paid</td>
                                                        <td>
                                                            <input type="text" class="form-control" style="height: 32px;" v-model="data.amount_paid"/>
                                                            <div v-if="errors.amount_paid">
                                                                <span class="text-danger">{{ errors.amount_paid[0] }}</span>
                                                            </div>
                                                        </td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    
            
                                    <div class="row">
                                        <div class="col-md-4"></div>
                                        <div class="col-md-6">
                                            <pulse-loader 
                                                :loading="submiting" 
                                                :color="color"
                                                v-if="submiting" 
                                            />
                                        </div>
                                    </div>
            
                                    <div class="row">
                                        <div class="col-md-4" v-if="submiting">
                                            <button type="button" disabled class="btn btn-secondary btn-sm mr-3">Profoma Invoice</button>
                                            <button type="button" disabled class="btn btn-info btn-sm">Tax Invoice</button>
                                        </div>
                                        <div class="col-md-4" v-else>
                                            <button type="button" class="btn btn-secondary btn-sm mr-3" @click="createProfoma">
                                                <i class="fas fa-poll"></i>
                                                Profoma Invoice
                                            </button>
                                            <button type="button" class="btn btn-info btn-sm" @click="submitData">
                                                <i class="fas fa-file-invoice"></i>
                                                Tax Invoice
                                            </button>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="input-group">
                                                <div class="input-group-prepend" style="height: 32px;">
                                                    <span class="input-group-text" for="inputGroupSelect01">Receivable Account</span>
                                                </div>
                                                <select class="form-control" style="height: 32px;" v-model="data.receivable_account">
                                                    <option v-for="(account, i) in accounts" :key="i" :value="account.id">{{ account.account_name }} {{ account.account_no }} {{ account.banks.bank_name }}</option>
                                                </select>
                                                <div v-if="errors.receivable_account">
                                                    <span class="text-danger">{{ errors.receivable_account[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
     </body>
</template>

<script>
import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Api from '../../services/Api';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import store from '../../store/store';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        DatePicker,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
               productId: '',
               sub_productId: '',
               selling_price: '',
               quantity: 0,
               total_items: '',
               customerId: '',
               invoice_type: '',
               date: '',
               discountPercent: 0,
               discountAmount: 0,
               total_amount: 0,
               amount_paid: '',
               applyVat: false,
               vatPercent: 18,
               receivable_account: '',
               userId: store.state.user.id,
            },
            isLoading: false,
            submiting: false,
            errors: '',
            color: '#40aac7',
            searchCustomers: '',
            selectedCustomer: null,

            products: [],
            customers: [],
            filteredCustomers: [],
            accounts: [],
            permissions: [],
            rows: [{
                dependentSearchQuery: '',
                filteredDependentData: [],
                searchQuery: '',
                product: '',
                productId: '',
                sub_productId: '',
                sub_product: '',
                selling_price: 0,
                quantity: 0,
                qty_avail: 0,
                total: 0,
                formattedAmount: 0.0,
                sub_products: [],
                filteredItems: this.products,
                selectedItem: null,
            }]
        }
    },

    mounted(){
        this.fetchProducts();
        this.fetchCustomers();
        this.fetchAccounts();
        // this.fetchPermissions();
    },

    computed: {
        grandTotal() {
            return this.rows.reduce((sum, item) => sum + item.total, 0);
        },

        discountAmount(){
            if(!this.grandTotal || !this.data.discountPercent){
                return 0
            }
            return (this.grandTotal * this.data.discountPercent)/100
        },

        subTotal(){
            return this.grandTotal - this.discountAmount
        },

        vatAmount(){
            if(!this.data.applyVat || !this.data.vatPercent){
                return 0
            }
            return (this.subTotal * this.data.vatPercent)/100
        },

        finalTotal(){
            return this.subTotal + this.vatAmount
        },

        // Method to get the formatted amount for a specific row
        formattedAmount() {
            return (index) => this.formatNumber(this.rows[index].selling_price);
        },

        formattedTotal() {
            return (index) => this.formatNumber(this.calculateTotalItems(index));
        },
    },

    methods: {
        filterItems(rowIndex) {
            const row = this.rows[rowIndex];
            if(row.searchQuery.trim() === ''){
                row.filteredItems = [];
            }else{
                row.filteredItems = this.products.filter(product =>
                    product.product_name.toLowerCase().includes(row.searchQuery.toLowerCase())
                );
            }
        },

        async selectItem(rowIndex, product) {
            this.rows[rowIndex].selectedItem = product;
            this.rows[rowIndex].productId = product.id;
            this.rows[rowIndex].searchQuery = product.product_name;
            this.rows[rowIndex].filteredItems = [];
            await Api().get(`get-stock-sub-product-by-productId/${product.id}`)
            .then(res =>{
                this.rows[rowIndex].sub_products = res.data
            })
        },
        

        filterDependentData(rowIndex) {
            const row = this.rows[rowIndex];
            if(row.dependentSearchQuery.trim() === ''){
                row.filteredDependentData = []
            }else{
                const query = row.dependentSearchQuery.toLowerCase();
                row.filteredDependentData = row.sub_products.filter(data =>
                    data.sub_products.sub_product_name.toLowerCase().includes(query)
                );
            }
        },

        onDependentItemSelect(rowIndex, dependentItem) {
            const row = this.rows[rowIndex];
            row.selectedDependentItem = dependentItem;
            row.sub_productId = dependentItem.sub_productId;
            row.selling_price = dependentItem.selling_price;
            row.qty_avail = dependentItem.quantity;
            this.rows[rowIndex].filteredDependentData = [];
            this.rows[rowIndex].dependentSearchQuery = dependentItem.sub_products.sub_product_name;
        },

        filterCustomers(){
            if(this.searchCustomers.trim() === ''){
                this.filteredCustomers = [];
            }else{
                this.filteredCustomers = this.customers.filter(customer =>
                customer.customer_name.toLowerCase().includes(this.searchCustomers.toLowerCase())
                );
            }
        },

        selectCustomer(customer){
            this.selectedCustomer = customer
            this.data.customerId = customer.id
            this.searchCustomers = customer.customer_name
            this.filteredCustomers = []
        },

        formatNumber(value) {
            if (!value && value !== 0) return ''; // Handle empty or null values
            return new Intl.NumberFormat().format(value); // Format with commas
        },

        parseNumber(value) {
            let parsed = parseFloat(value.replace(/,/g, ''));
            return isNaN(parsed) ? 0 : parsed; // Parse the number, remove commas, default to 0 if NaN
        },

        updateAmount(value, index) {
            this.rows[index].selling_price = this.parseNumber(value); // Update the raw amount value
            this.calculateTotalItems(index)
        },

       async fetchProducts(){
        this.isLoading = true
        await Api().get('sales')
        .then(res => {
            if(res.status === 200){
                this.isLoading = false
                this.products = res.data.products
            }
        }).catch(error =>{
            if(error){
                this.isLoading = false
                console.log('Failed to get products information.', error)
            }
        })
       },

       async fetchAccounts(){
        this.isLoading = true
        await Api().get('accounts')
        .then(res =>{
            if(res.status === 200){
                this.isLoading = false
                this.accounts = res.data
            }
        }).catch(error =>{
            if(error){
                this.isLoading = false
                console.log('Failed to fetch account informations.', error)
            }
        })
       },

       addRow(){
        this.rows.push({
            filteredDependentData: [],
            searchQuery: '',
            dependentSearchQuery: '',
            product: '',
            productId: '',
            sub_product: '',
            selling_price: 0,
            quantity: 0,
            qty_avail: 0,
            total: 0,
            formattedAmount: 0.0,
            sub_products: [],
            filteredItems: this.products,
            selectedItem: null,
        })
       },

       removeRow(index){
        this.rows.splice(index, 1)
       },

       async submitData(){
        this.submiting = true
        const dataToSubmit = this.rows.map(row =>({
            productId: row.productId,
            sub_productId: row.sub_productId,
            selling_price: row.selling_price,
            quantity: row.quantity,
            total_items: row.total,
        }))
        await Api().post('sales',{
            data: dataToSubmit,
            customerId: this.data.customerId,
            date: this.data.date,
            grand_total: this.grandTotal,
            discount_percentage: this.data.discountPercent,
            discount_amount: this.discountAmount,
            vat_percentage: this.data.vatPercent,
            vat_amount: this.vatAmount,
            sub_total: this.subTotal,
            total_amount: this.finalTotal,
            amount_paid: this.data.amount_paid,
            invoice_type: this.data.invoice_type,
            receivable_account: this.data.receivable_account
        })
        .then(res =>{
            if(res.status === 200){
                this.submiting = false
                this.resetRows()
                Swal.fire({
                    title: "Successfully!",
                    text: `${res.data.message}`,
                    icon: "success"
                });
            }else{
                this.submiting = false
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                });
            }
        }).catch(error =>{
            if(error.response.status === 422){
                this.submiting = false
                this.errors = error.response.data.errors
            }
        })
       },

       async createProfoma(){
        this.submiting = true
        const dataToSubmit = this.rows.map(row =>({
            productId: row.productId,
            sub_productId: row.sub_productId,
            selling_price: row.selling_price,
            quantity: row.quantity,
            total_items: row.total,
        }))
        await Api().post('store-profoma',{
            data: dataToSubmit,
            customerId: this.data.customerId,
            date: this.data.date,
            grand_total: this.grandTotal,
            discount_percentage: this.data.discountPercent,
            discount_amount: this.discountAmount,
            vat_percentage: this.data.vatPercent,
            vat_amount: this.vatAmount,
            sub_total: this.subTotal,
            total_amount: this.finalTotal,
            amount_paid: this.data.amount_paid,
            invoice_type: this.data.invoice_type,
            receivable_account: this.data.receivable_account
        })
        .then(res =>{
            if(res.status === 200){
                this.submiting = false
                this.resetRows()
                Swal.fire({
                    title: "Successfully!",
                    text: `${res.data.message}`,
                    icon: "success"
                });
            }else{
                this.submiting = false
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                });
            }
        }).catch(error =>{
            if(error.response.status === 422){
                this.submiting = false
                this.errors = error.response.data.errors
            }
        })
       },

       resetRows(){
        this.rows = [{
            product: '',
            sub_product: '',
            buying_price: 0,
            selling_price: 0,
            quantity: 0,
            sub_products: [],
        }];
        this.data.customerId = ''
        this.data.date = ''
        this.data.amount_paid = 0
        this.data.discountPercent = 0
        this.data.applyVat = false
       },

       async fetchCustomers(){
        this.isLoading = true
        await Api().get('sales')
        .then(res =>{
            if(res.status === 200){
                this.isLoading = false
                this.customers = res.data.customers
            }
        }).catch(error =>{
            if(error){
                this.isLoading = false
                console.log('Failed to fetch customer informations.',error)
            }
        })
       },

       calculateTotalItems(index) {
        const row = this.rows[index];
            const sellingPrice = isNaN(row.selling_price) ? 0 : row.selling_price;
            const quantity = isNaN(row.quantity) ? 0 : row.quantity;
            return row.total = sellingPrice * quantity;
        },     
        
        // async fetchPermissions(){
        //     this.isLoading = true
        //     await Api().get(`permissions`)
        //     .then(res =>{
        //         if(res.status === 200){
        //         this.isLoading = false
        //         this.permissions = res.data
        //         }
        //     }).catch(error =>{
        //         if(error){
        //         this.isLoading = false
        //         console.error('Failed to fetch permissions.',error)
        //         }
        //     });
        // },

        hasPermission(permissionKey) {
            return store.state.permissions.some(permission => permission.key === permissionKey && permission.value);
        }
    }
}
</script>

<style scoped>
select{
    border:1px solid #e3e3e3; 
    width:100%;
    border-radius: 4px;
    padding: 5px;
}

input{
    border:1px solid #e3e3e3; 
    width:100%;
    border-radius: 4px;
    padding: 2px;
}

.customer-input{
    border:1px solid #e3e3e3; 
    width:100%;
    border-radius: 4px;
    height: 35px;
}

/* .row {
  margin-bottom: 20px;
} */

.dropdown {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  max-height: 150px; /* Set a maximum height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 10px;
  cursor: pointer;
}

li:hover {
  background-color: #f0f0f0;
}

table tr td{
    border: none;
}
</style>