<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-4 col-sm-6 col-lg-9" style="margin-bottom: 4%;">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-10">
                                            <span>Create new user information</span>
                                        </div>
                                        <div class="col-2">
                                            <RouterLink to="/users-list">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                <!-- 
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                /> -->
            
                                <div class="card-body" >
                                    <div class="row ">
                                        <div class="col-md-12">
                                            <div class="alert alert-light" role="alert">
                                                <span>
                                                    <b>Note:</b> Do not forget to update user persmissions under settings menu. 
                                                    The user not granted any access when created for the first time.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center p-2">
                                        <div class="col-md-12" v-if="package_error">
                                            <div class="alert alert-danger" role="alert">{{ package_error }}</div>
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Email</span>
                                                <input type="email" class="form-control" v-model="data.email"/>
                                                <div v-if="errors.email">
                                                    <span class="text-danger">{{ errors.email[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Full Name</span>
                                                <input class="form-control" v-model="data.full_name"/>
                                                <div v-if="errors.full_name">
                                                    <span class="text-danger">{{ errors.full_name[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                
                                    <div class="row justify-content-center p-2">
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Phone</span>
                                                <input class="form-control" v-model="data.phone"/>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Select Status</span>
                                                <select class="form-control" v-model="data.status">
                                                    <option>Active</option>
                                                    <option>InActive</option>
                                                </select>
                                                <div v-if="errors.status">
                                                    <span class="text-danger">{{ errors.status[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="row justify-content-center p-2">
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Password</span>
                                                <input type="password" class="form-control" v-model="data.password"/>
                                                <div v-if="errors.password">
                                                    <span class="text-danger">{{ errors.password[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Comfirm Password</span>
                                                <input type="password" class="form-control" v-model="data.comfirm_password"/>
                                                <div v-if="errors.comfirm_password">
                                                    <span class="text-danger">{{ errors.comfirm_password[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <span class="text-danger">{{ password_error }}</span>
                                        </div>
                                    </div>
                
                                    <div class="row justify-content-center mb-3">
                                        <div class="col-4" v-if="isLoading">
                                            <button type="button" class="btn btn-info btn-sm" disabled>Submiting...</button>
                                        </div>
                                        <div class="col-4" v-else>
                                            <button type="button" class="btn btn-info btn-sm" @click="createUser">Submit</button>
                                        </div>
                                        <pulse-loader 
                                            :loading="isLoading" 
                                            :color="color"
                                            v-if="isLoading" 
                                        />
                                        <div class="col-6">
                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
                full_name: '',
                phone: '',
                email: '',
                status: '',
                password: '',
                comfirm_password: '',
            },
            isLoading: false,
            isUpdating: false,
            errors: '',
            color: '#40aac7',
            password_error: '',
            package_error: '',
        }
    },

    methods: {
        async createUser(){
            this.isLoading = true
            await Api().post('users',this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    Swal.fire({
                        title: "Successfully!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });
                    this.$router.push('/users-list')
                }
                if(res.status === 201){
                    this.isLoading = false
                    this.password_error = res.data.error
                    this.package_error = res.data.package_error
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isLoading = false
                    this.errors = error.response.data.errors
                }
            })
        }
    }
}
</script>