<template>
    <div class="container-fluid" style="background-color: #16B4C9;">
        <div class="row justify-content-center" style="margin-top: 5%;">
           <div class="col-md-8 ">
               <div class="card">
                   <div class="card-body">
                       <div class="row justify-content-center mb-2">
                           <div class="col-md-3">
                               <span style="font-size: 24px; font-weight: 600;">Create Account</span>
                           </div>
                       </div>
                       <div class="row" v-if="any_error">
                            <div class="col-md-12">
                                <div class="alert alert-danger" role="alert">
                                    {{ any_error }}
                                </div>
                            </div>
                        </div>
                       <div class="row">
                           <div class="col-6">
                               <div class="form-group">
                                   <label>User Name:</label>
                                   <input type="text" class="form-control" v-model="clientData.user_name"/>
                                   <div v-if="errors.user_name">
                                       <span class="text-danger">{{ errors.user_name[0] }}</span>
                                   </div>
                               </div>
                           </div>
                           <div class="col-6">
                               <div class="form-group">
                                   <label>Email:</label>
                                   <input type="email" class="form-control" v-model="clientData.user_email"/>
                                   <div v-if="errors.user_email">
                                       <span class="text-danger">{{ errors.user_email[0] }}</span>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div class="row">
                           <div class="col-6">
                               <div class="form-group">
                                   <label>Business Name:</label>
                                   <input type="text" class="form-control" v-model="clientData.business_name"/>
                                   <div v-if="errors.business_name">
                                       <span class="text-danger">{{ errors.business_name[0] }}</span>
                                   </div>
                               </div>
                           </div>
                           <div class="col-6">
                               <div class="form-group">
                                   <label>Business Type:</label>
                                   <select class="form-control" v-model="clientData.business_type">
                                       <option>Retail</option>
                                       <option>Microfinance</option>
                                       <option>Software</option>
                                       <option>Hospital</option>
                                       <option>Coporate</option>
                                       <option>Logistic</option>
                                       <option>Others</option>
                                   </select>
                                   <div v-if="errors.business_type">
                                       <span class="text-danger">{{ errors.business_type[0] }}</span>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div class="row">
                           <div class="col-6">
                               <div class="form-group">
                                   <label>Phone No:</label>
                                   <input type="text" class="form-control" v-model="clientData.phone_number"/>
                                   <div v-if="errors.phone_number">
                                       <span class="text-danger">{{ errors.phone_number[0] }}</span>
                                   </div>
                               </div>
                           </div>
                           <div class="col-6">
                               <div class="form-group">
                                   <label>Address:</label>
                                   <input type="text" class="form-control" v-model="clientData.address"/>
                                   <div v-if="errors.address">
                                       <span class="text-danger">{{ errors.address[0] }}</span>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div class="row">
                           <div class="col-6">
                               <div class="form-group">
                                   <label>Password:</label>
                                   <input type="password" class="form-control" v-model="clientData.user_password"/>
                                   <div v-if="errors.user_password">
                                       <span class="text-danger">{{ errors.user_password[0] }}</span>
                                   </div>
                               </div>
                           </div>
                           <div class="col-6">
                               <div class="form-group">
                                   <label>Comfirm Password:</label>
                                   <input type="password" class="form-control" v-model="clientData.comfirm_password"/>
                                   <div v-if="errors.comfirm_password">
                                       <span class="text-danger">{{ errors.comfirm_password[0] }}</span>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div class="row">
                           <div class="col-md-6" v-if="isLoading">
                               <button type="button" class="btn btn-info btn-sm btn-block" disabled @click="registerClient">Please Wait...</button>
                           </div>
                           <div class="col-md-6" v-else>
                               <button type="button" class="btn btn-info btn-sm btn-block" @click="registerClient">CREATE ACCOUNT</button>
                           </div>
                           <div class="col-md-6">
                               <RouterLink to="/login">
                                   <button type="button" class="btn btn-warning btn-sm btn-block">LOG IN</button>
                               </RouterLink>
                           </div>
                       </div>
                       <div class="row justify-content-center mt-2">
                           <div class="col-md-6">
                               <span class="text-danger" style="font-size: 18px;">{{ password_error }}</span>
                           </div>
                       </div>
                       <div class="row justify-content-center mt-2">
                           <pulse-loader
                               :loading="isLoading"
                               :color="color"
                               v-if="isLoading"
                           />
                       </div>
                   </div>
               </div>
           </div>
       </div>
    </div>
</template>

<script >
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Swal from 'sweetalert2';

export default{
    components: {
        PulseLoader,
    },
    data(){
        return{
            clientData: {
                user_name: '',
                user_email: '',
                business_name: '',
                business_type: '',
                phone_number: '',
                address: '',
                user_password: '',
                comfirm_password: '',
            },
            isLoading: false,
            color: '#40aac7',
            errors: '',
            unauthorized: '',
            password_error: '',
            any_error: '',
        }
    },

    methods: {
        async registerClient(){
            this.isLoading = true
            const controller = new AbortController();
            const { signal } = controller;

            const timeout = setTimeout(() => {
                controller.abort();
            }, 10000);

            try{
                const res = await Api().post('register-client',this.clientData,{ signal });

                clearTimeout(timeout);
                if(res.status === 200){
                    this.isLoading = false
                    // this.isLogin = false
                    Swal.fire({
                        title: "Successfully!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });                    
                    this.clientData.user_name = ''
                    this.clientData.user_email ='',
                    this.clientData.business_name = ''
                    this.clientData.business_type = ''
                    this.clientData.phone_number = ''
                    this.clientData.address = ''
                    this.clientData.user_password = ''
                    this.clientData.comfirm_password = ''
                    // this.$router.push('/login')
                }else if(res.status === 201){
                    this.isLoading = false;
                    this.password_error = res.data.error;
                }
            }catch(error){
                if (error.name === 'AbortError'){
                    this.any_error = 'Request was aborted due to timeout';
                }else if(error.response){
                    if(error.response.status === 422){
                        this.isLoading = false;
                        this.errors = error.response.data.errors;
                    }
                }else{
                    this.isLoading = false;
                    this.any_error = 'Network error or other issue';
                }
            }finally{
                this.isLoading = false;
            }
        },
    }
}
</script>