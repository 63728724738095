<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <!-- <content-loader v-if="isLoading"/> -->
                <div class="container-fluid">
                    <div class="row" style="margin-bottom: 4%;">
                        <div class="col-sm-6 col-md-3 col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span style="font-weight: 600;">Invoice</span>
                                        </div>
                                        <div class="col-md-2">
                                            <RouterLink to="/profoma-lists">
                                                <button type="button" class="btn btn-info btn-sm" ><i class="fas fa-chevron-left"></i> Back</button> 
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
            
                                <div class="card-body" id="table-content" v-else>
                                    <div class="card-container">
                                        <div class="row pt-2 pl-2">
                                            <div class="col-md-5">
                                                <img :src="data.logo_name" alt="logo" width="150" />
                                            </div>
                                            <div class="col-md-4 mt-5">
                                            <span style="font-size: 24px;"><b>PROFOMA INVOICE</b></span>
                                        </div>
                                        <div class="col-md-3 text-end">
                                                <span><b>{{ data.business_name }}</b></span>
                                                <p>
                                                    {{ data.client_address }}, {{ data.region }}<br>
                                                    Phone: {{ data.mobile_no }}<br>
                                                    Email: {{ data.client_email }}
                                                </p>
                                            </div>
                                        </div>
            
                                        <div class="row pl-2">
                                            <div class="col-md-9">
                                                <h4>To:</h4>
                                                <p>
                                                    {{ data.customer_name }}<br>
                                                    {{ data.address }}<br>
                                                    {{ data.phone_no }}<br>
                                                    {{ data.email }}
                                                </p>
                                            </div>
                                            <div class="col-md-3">
                                                <h5>Invoice #{{ data.invoice_no }}</h5>
                                                <p>
                                                    Date: {{ data.date }}<br>
                                                    <!-- Due Date: 2024-08-17 -->
                                                </p>
                                            </div>
                                        </div>
            
                                        <div class="row p-2">
                                            <div class="col-md-12">
                                                <table id="example1" class="table table-hover table-bordered table-sm table-sm table-responsive-sm">
                                                    <tr>
                                                        <th>Description</th>
                                                        <th>Quantity</th>
                                                        <th>Unit Price</th>
                                                        <th>Total</th>
                                                    </tr>
                    
                                                    <tbody>
                                                        <tr v-for="(sale, i) in sales" :key="i">
                                                            <td>{{ sale.products.product_name }} {{ sale.sub_products.sub_product_name }}</td>
                                                            <td>{{ sale.quantity }}</td>
                                                            <td>{{ formatNumber(sale.selling_price) }}</td>
                                                            <td>{{ formatNumber(sale.total_items) }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
            
                                        <div class="row p-2">
                                            <div class="col-md-9"></div>
                                            <div class="col-md-3">
                                                <table id="example1" class="table table-hover table-bordered table-sm table-sm table-responsive-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>Grand Total</td>
                                                            <td>{{ formatNumber(data.grand_total) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Discount Amount</td>
                                                            <td>{{ formatNumber(data.discount_amount) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Sub Total</td>
                                                            <td>{{ formatNumber(data.sub_total) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Vat Amount</td>
                                                            <td>{{ formatNumber(data.vat_amount) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total</td>
                                                            <td>{{ formatNumber(data.total_amount) }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-6" v-if="isPrinting">
                                            <button type="button" disabled class="btn btn-info btn-sm hide-on-pdf" @click="printPdf"><i class="fas fa-print"></i> Printing</button>
                                        </div>
                                        <div class="col-md-6" v-else>
                                            <button type="button" class="btn btn-info btn-sm hide-on-pdf" @click="printPdf"><i class="fas fa-print"></i> Print</button>
                                        </div>
                                        <pulse-loader 
                                            :loading="isPrinting" 
                                            :color="color"
                                            v-if="isPrinting" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
                <ControlSidebar></ControlSidebar>
            </div>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// import ContentLoader from '../../components/ContentLoader.vue'
// import Swal from 'sweetalert2';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        // ContentLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
                month: '',
                date: '',
                invoice_no: 0,
                invoiceId: this.$route.params.id,
                grand_total: 0,
                discount_amount: 0,
                sub_total: 0,
                vat_amount: 0,
                total_amount: 0,
                customerId: '',
                customer_name: '',
                phone_no: '',
                email: '',
                address: '',
                logo_name: '',
                business_name: '',
                primary_color: '',
                secondary_color: '',
                mobile_no: '',
                client_address: '',
                region: '',
                website: '',
                tin_no: '',
                client_email: '',
            },
            isLoading: false,
            isPrinting: false,
            sales: [],
            color: '#40aac7'
        }
    },

    mounted(){
        this.fetchInvoice();
        this.fetchCustomers();
        this.fetchCompanyInfo();
    },

    methods: {

        async fetchInvoice(){
            this.isLoading = true
            await Api().get(`sales/${this.data.invoiceId}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.sales = res.data
                    this.data.month = res.data[0].invoices.month
                    this.data.invoice_no = res.data[0].invoices.invoice_no
                    this.data.date = res.data[0].invoices.date
                    this.data.grand_total = res.data[0].invoices.grand_total
                    this.data.discount_amount = res.data[0].invoices.discount_amount
                    this.data.sub_total = res.data[0].invoices.sub_total
                    this.data.vat_amount = res.data[0].invoices.vat_amount
                    this.data.total_amount = res.data[0].invoices.total_amount
                    this.data.total_amount = res.data[0].invoices.total_amount
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to get customer informations.', error)
                }
            })
        },

        async fetchCustomers(){
            this.isLoading = true
            await Api().get(`get-invoice-customer/${this.data.invoiceId}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.data.customer_name = res.data[0].customers.customer_name
                    this.data.phone_no = res.data[0].customers.phone_no
                    this.data.email = res.data[0].customers.email
                    this.data.address = res.data[0].customers.address
                }
            }).catch(error =>{
                if(error){
                    console.log('Failed to get users')
                }
            })
        },

        async fetchCompanyInfo(){
                this.isLoading = true
                await Api().get('get-company-info')
                .then(res =>{
                    if(res.status === 200){
                        this.isLoading = false
                        this.data.business_name = res.data.client.business_name
                        this.data.primary_color = res.data.client.primary_color
                        this.data.secondary_color = res.data.client.secondary_color
                        this.data.logo_name = res.data.client.logo_name
                        this.data.mobile_no = res.data.client.mobile_no
                        this.data.client_address = res.data.client.address
                        this.data.region = res.data.client.region
                        this.data.website = res.data.client.website
                        this.data.tin_no = res.data.client.tin_no
                        this.data.client_email = res.data.client.email
                    }
                }).catch(error =>{
                    if(error){
                        this.isLoading = false
                        console.log('Failed to get company info',error);
                    }
                })
            },
        
       
        formatNumber(value){
            return new Intl.NumberFormat('en-US',{
                style: 'currency',
                currency: 'TZS',
                minimumFractionDigits: 2,
            }).format(value);
        },

        async printPdf(){
            // Hide buttons
            this.isPrinting = true
            const buttons = document.querySelectorAll('.hide-on-pdf');
            buttons.forEach(button => button.style.display = 'none');

            const element = document.getElementById('table-content');
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/png');
            
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt',
                format: 'a4'
            });

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      
            // Convert the PDF to a Blob to create a URL for preview
            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new window or tab for preview
            const pdfWindow = window.open(pdfUrl, '_blank');

            if (pdfWindow) {
                this.isPrinting = false
                // Automatically print the PDF after opening the preview
                pdfWindow.addEventListener('load', () => {
                // pdfWindow.print();
                });
            } else {
                this.isLoading = false
                // If the window couldn't be opened (e.g., due to pop-up blockers)
                alert('Please allow pop-ups to preview and print the PDF.');
            }

            // Show buttons again
            buttons.forEach(button => button.style.display = 'inline-block');
        },
    }
}
</script>

<style scoped>
.card-container{
    border: 1px solid #eee;
}
</style>