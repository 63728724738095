<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-4 col-sm-6 col-lg-9" style="margin-top: 2%; margin-bottom: 4%;">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-10">
                                            <span>New bank informations</span>
                                        </div>
                                        <div class="col-2">
                                            <RouterLink to="/bank-list">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
            
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
                               
                                <div class="card-body" v-else>
                                    <div class="row p-2">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <span>Bank Name:</span>
                                                <input type="text" class="form-control" v-model="data.bank_name" />
                                                <div v-if="errors.bank_name">
                                                    <span class="text-danger">{{ errors.bank_name[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <span>Swift Code:</span>
                                                <input class="form-control" v-model="data.swift_code"/>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="row p-2">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <span>Address:</span>
                                                <input type="text" class="form-control" v-model="data.address" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <span>Email:</span>
                                                <input class="form-control" v-model="data.email"/>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="row p-2">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <span>Mobile No:</span>
                                                <input type="text" class="form-control" v-model="data.phone_no" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                           
                                        </div>
                                    </div>
                                   
                
                                    <div class="row mb-3 p-2">
                                        <div class="col-4" v-if="isUpdating">
                                            <button type="button" class="btn btn-info btn-sm" disabled>Submiting...</button>
                                        </div>
                                        <div class="col-4" v-else>
                                            <button type="button" class="btn btn-info btn-sm" @click="updateBank">Submit</button>
                                        </div>
                                        <pulse-loader 
                                            :loading="isUpdating" 
                                            :color="color"
                                            v-if="isUpdating" 
                                        />
                                        <div class="col-6">
                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
                bank_name: '',
                swift_code: '',
                address: '',
                email: '',
                phone_no: '',
                id: this.$route.params.id
            },
            isLoading: false,
            isUpdating: false,
            errors: '',
            color: '#40aac7',
        }
    },

    mounted(){
        this.fetchBank();
    },  

   

    methods: {
        async fetchBank(){
            this.isLoading = true
            await Api().get(`banks/${this.data.id}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.data.bank_name = res.data[0].bank_name
                    this.data.email = res.data[0].email
                    this.data.address = res.data[0].address
                    this.data.phone_no = res.data[0].phone_no
                    this.data.swift_code = res.data[0].swift_code
                }else{
                    this.isLoading = false
                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                    });
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to fetch bak informations.',error)
                }
            })
        },

        async updateBank(){
            this.isUpdating = true
            await Api().put(`banks/${this.data.id}`,this.data)
            .then(res => {
                if(res.status === 200){
                    this.isUpdating = false
                    Swal.fire({
                        title: "Successfully!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });
                }else{
                    this.isUpdating = false
                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                    });
                }
            }).catch(error =>{
                if(error.response.status === 422){
                    this.isUpdating = false
                    this.errors = error.response.data.errors
                }
            })
        }
    }
}
</script>