<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 2%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Pay Slip List</span>
                                        </div>
                                        <div class="col-md-2">
                                            <RouterLink to="/self-services">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row mb-2">
                                        <div class="col-md-3">
                                            <input type="text" v-model="searchQuery" placeholder="Search..." class="form-control" style="height: 35px;" />
                                        </div>
                                    </div>
                                     <pulse-loader 
                                        :loading="isLoading" 
                                        :color="color"
                                        v-if="isLoading" 
                                        style="margin-top: 50px; margin-left: 40%;"
                                    />
                                    <table v-else id="example1" class="table table-hover table-bordered table-striped table-sm table-sm table-responsive-sm">
                                        <tr>
                                            <th>Full Name</th>
                                            <th>Phone</th>
                                            <th>Address</th>
                                            <th>Month</th>
                                            <th>Department</th>
                                            <th>View</th>
                                        </tr>

                                        <tbody>
                                            <tr v-for="(pay_slip, i) in displayedPaySlips" :key="i">
                                                <td>{{ pay_slip.first_name }} {{ pay_slip.middle_name }} {{ pay_slip.last_name }}</td>
                                                <td>{{ pay_slip.employees.mobile_no }}</td>
                                                <td>{{ pay_slip.employees.address }}</td>
                                                <td>{{ pay_slip.payrolls.month }}</td>
                                                <td>{{ pay_slip.employees.departiment }}</td>
                                                <td>
                                                    <RouterLink :to="{name: 'view-salary-slip-self', params: {id: pay_slip.id}}">
                                                        <button type="button" class="btn btn-info btn-sm"><i class="fa fa-eye"></i></button>
                                                    </RouterLink>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Pagination 
                                        :currentPage="currentPage" 
                                        :totalPages="totalPages" 
                                        @page-change="handlePageChange" 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";
// import Datepicker from 'vuejs3-datepicker';
import Api from "../../services/Api";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Pagination from '../../components/Pagination.vue';

export default{
    components: {
        Navbar,
        Sidebar,
        ContentHeader,
        Footer,
        ControlSidebar,
        // Datepicker,
        PulseLoader,
        Pagination,
    },
    
    data(){
        return{
            // month: '',
            isLoading: false,
            color: '#40aac7',
            errors: '',
            pay_slips: [],
            displayedPaySlips: [],
            searchQuery: '',
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
        }
    },

    // setup(){
    //     function handleChangedMonth(payload) {
    //         console.log( payload )
    //     }
    //   return {handleChangedMonth}
    // },

    mounted(){
        this.fetchPaySlip();
    },

    methods: {
        async fetchPaySlip(){
            this.isLoading = true
            await Api().get('payslip-list-self-service')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.pay_slips = res.data
                    this.updatePaginatedData();
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to fetch pay slip.', error)
                }
            })
        },

        updatePaginatedData() {
            const filteredProducts = this.pay_slips.filter(payslip => 
                payslip.first_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                payslip.middle_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                payslip.last_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                payslip.payrolls.month.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            this.displayedPaySlips = filteredProducts.slice(start, end);
            this.totalPages = Math.ceil(filteredProducts.length / this.perPage);
        },

        handlePageChange(page) {
            this.currentPage = page;
            this.updatePaginatedData();
        },
    },

    watch: {
        searchQuery: 'updatePaginatedData',
        currentPage: 'updatePaginatedData'
    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>