<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 4%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <!-- <content-loader v-if="isLoading"/> -->
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6 col-md-3 col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span style="font-weight: 600;">NSSF</span>
                                        </div>
                                        <div class="col-md-2">
                                            <RouterLink to="/payroll-list">
                                                <button type="button" class="btn btn-info btn-sm" ><i class="fas fa-chevron-left"></i> Back</button> 
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
            
                                <div class="card-body" v-else  id="table-content">
                                    <table id="example1" class="table table-hover table-striped table-bordered table-sm table-sm table-responsive-sm">
                                        <tr align="center" style="background-color: #962C30">
                                            <td colspan="6">
                                                <img src="../../assets/img/nssf-2.png" width="50"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6">
                                                <p style="text-align: center; font-size: 18px;"><b>NATIONAL SOCIAL SECURITY FUND</b></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" style="background-color: #188937">
                                                <p style="text-align: center; color:#FFFFFF; font-size: 18px;"><b>SCHEDULE FOR ARREARS OF STATUTORY CONTRIBUTIONS</b></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" style="background-color: #FBC106; font-size: 18px;">
                                                <p style="text-align: center;"><b>FOR THE PERIOD: {{ month }} {{ formateDate(date) }}</b></p>
                                            </td>
                                        </tr>
            
                                        <tr style="background-color: #962C30; color: #FFFFFF;">
                                            <th>S/N</th>
                                            <th>NSSF NO</th>
                                            <th>FIRST NAME</th>
                                            <th>MIDDLE NAME</th>
                                            <th>LAST NAME</th>
                                            <th>AMOUNT</th>
                                        </tr>
            
                                        <tbody>
                                            <tr v-for="(nssf, i) in nssfs" :key="i">
                                                <td>{{ i+1 }}</td>
                                                <td>{{ nssf.social_seq_no }}</td>
                                                <td>{{ nssf.first_name }}</td>
                                                <td>{{ nssf.middle_name }}</td>
                                                <td>{{ nssf.last_name }}</td>
                                                <td>{{ formatNumber(nssf.amount) }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="4"></td>
                                                <td align="center"><strong>TOTAL</strong></td>
                                                <td><strong>{{ formatNumber(totalAmount()) }}</strong></td>
                                            </tr>
                                        </tbody>
                                    
                                    </table>
                                    <div class="row mt-3">
                                        <div class="col-md-4" v-if="isPrinting">
                                            <button type="button" disabled class="btn btn-info btn-sm hide-on-pdf" @click="printPdf"><i class="fas fa-print"></i> Print</button>
                                            <!-- <button type="button" disabled class="btn btn-secondary btn-sm ml-3 hide-on-pdf">Export to Excel</button> -->
                                        </div>
                                        <div class="col-md-4" v-else>
                                            <button type="button" class="btn btn-info btn-sm hide-on-pdf" @click="printPdf"><i class="fas fa-print"></i> Print</button>
                                            <!-- <button type="button" class="btn btn-secondary btn-sm ml-3 hide-on-pdf">Export to Excel</button> -->
                                        </div>
                                        <pulse-loader 
                                            :loading="isPrinting" 
                                            :color="color"
                                            v-if="isPrinting" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// import ContentLoader from '../../components/ContentLoader.vue'
// import Swal from 'sweetalert2';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";


export default{
    components: {
        PulseLoader,
        // ContentLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            month: '',
            date: '',
            isLoading: false,
            isPrinting: false,
            payrollId: this.$route.params.id,
            nssfs: [],
            color: '#40aac7'
        }
    },

    mounted(){
        this.fetchNssf();
    },

    methods: {

        async fetchNssf(){
            this.isLoading = true
            await Api().get(`get-nssf/${this.payrollId}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.nssfs = res.data
                    this.month = res.data[0].payrolls.month
                    this.date = res.data[0].payrolls.date
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to get sdl informations.', error)
                }
            })
        },
       
        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        },

    

        totalAmount(){
            return this.nssfs.reduce((sum, item)=>{
                return sum + Number(item.amount);
            },0)
        },

        async printPdf(){
            this.isPrinting = true
            // Hide buttons
            const buttons = document.querySelectorAll('.hide-on-pdf');
            buttons.forEach(button => button.style.display = 'none');

            const element = document.getElementById('table-content');
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/png');
            
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt',
                format: 'a4'
            });

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      
            // Convert the PDF to a Blob to create a URL for preview
            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new window or tab for preview
            const pdfWindow = window.open(pdfUrl, '_blank');

            if (pdfWindow) {
                // Automatically print the PDF after opening the preview
                pdfWindow.addEventListener('load', () => {
                    this.isPrinting = false
                // pdfWindow.print();
                });
            } else {
                this.isPrinting = false
                // If the window couldn't be opened (e.g., due to pop-up blockers)
                alert('Please allow pop-ups to preview and print the PDF.');
            }

            // Show buttons again
            buttons.forEach(button => button.style.display = 'inline-block');
            this.isPrinting = false
            
        },

        // printPdf(){
        //     const buttons = document.querySelectorAll('.hide-on-pdf');
        //     buttons.forEach(button =>{
        //         button.style.display = 'none'
        //     })
        //     const element = document.getElementById('table-content');
        //     html2canvas(element).then(convas =>{
        //         const imgData = convas.toDataURL('img/png');
        //         const pdf = new jsPDF({
        //             orientation: 'portrait',
        //             unit: 'pt',
        //             format: 'a4'
        //         });
        //         const imgPros = pdf.getImageProperties(imgData);
        //         const pdfWidth = pdf.internal.pageSize.getWidth();
        //         const pdfHeight = (imgPros.height * pdfWidth) / imgPros.width;
        //         pdf.addImage(imgData,'PNG',0,0, pdfWidth, pdfHeight);
        //         pdf.save('nssf.pdf');

        //         buttons.forEach(button =>{
        //             button.style.display = 'inline-block'
        //         })
        //     })
        // },

        formateDate(dateString){
            const date = new Date(dateString)
            return date.getFullYear();
        }

    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>