<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 4%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <!-- <content-loader v-if="isLoading"/> -->
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6 col-md-3 col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span style="font-weight: 600;">SDL Returns</span>
                                        </div>
                                        <div class="col-md-2">
                                            <RouterLink to="/payroll-list">
                                                <button type="button" class="btn btn-info btn-sm" ><i class="fas fa-chevron-left"></i> Back</button> 
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
            
                                <div class="card-body" v-else  id="table-content">
                                    <table id="example1" class="table table-hover table-striped table-bordered table-sm table-sm table-responsive-sm">
                                        <tr align="center">
                                            <td colspan="9">
                                                <img src="../../assets/img/tralogo.jpg" width="60"/>
                                            </td>
                                        </tr>
                                        <tr style="background-color: yellow;">
                                            <td colspan="2" align="center"><strong>Month</strong> </td>
                                            <td colspan="1" align="center"><strong>{{ month }}</strong></td>
                                            <td colspan="1" align="center"><strong>Year</strong></td>
                                            <td colspan="1" align="center"><strong>2024</strong></td>
                                            <td colspan="5"><strong>Tanzania Mainland</strong></td>
                                        </tr>
                                        <tr>
                                            <td colspan="9" align="center">
                                                <span style="font-weight: 600;">
                                                    Skill and Development Levy Monthly Return for Tanzania Mainland
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="9">
                                                <p style="text-align: center; font-weight: 600;">
                                                    Note: This return is submited under the provisions of
                                                    section 16 of Vocational Education Training Act, Cap 82.
                                                    You are required to submit the return and make
                                                    payment within 7 days after the end of the month of which
                                                    it relates.
                                                </p>
                                            </td>
                                        </tr>
                                        
                                        <tr>
                                            <th width="50">S.N</th>
                                            <th>NATURE OF EMPLOYMENT</th>
                                            <th>NUMBER OF EMPLOYEES</th>
                                            <th>BASIC SALARY</th>
                                            <th>OTHER ALLOWANCES</th>
                                            <th>GROSS PAYMENTS</th>
                                            <th>EXEMPTION</th>
                                            <th>AMOUNT SUBJECT TO SDL</th>
                                            <th>SDL PAYABLE</th>
                                        </tr>
            
                                        <tbody>
                                            <tr v-for="(sdl, i) in sdls" :key="i">
                                                <td>{{ i+1 }}</td>
                                                <td>{{ sdl.employment_nature }}</td>
                                                <td>{{ sdl.no_of_employee }}</td>
                                                <td>{{ formatNumber(sdl.total_basic_salary) }}</td>
                                                <td>{{ formatNumber(sdl.total_allowance) }}</td>
                                                <td>{{ formatNumber(sdl.total_gross_salary )}}</td>
                                                <td>{{ formatNumber(sdl.exemption) }}</td>
                                                <td>{{ formatNumber(sdl.amount_subject_to_sdl) }}</td>
                                                <td>{{ formatNumber(sdl.sdl_payable) }}</td>
                                            </tr>
            
                                            <tr>
                                                <td colspan="2" align="center"><strong>TOTAL</strong></td>
                                                <td><strong>{{ formatNumber(totaNoEmployee()) }}</strong></td>
                                                <td><strong>{{ formatNumber(totaBasicSalary()) }}</strong></td>
                                                <td><strong>{{ formatNumber(totalAllowance()) }}</strong></td>
                                                <td><strong>{{ formatNumber(totalGrossSalary()) }}</strong></td>
                                                <td><strong>{{ formatNumber(totalExemption()) }}</strong></td>
                                                <td><strong>{{ formatNumber(totalSubjectToSdl()) }}</strong></td>
                                                <td><strong>{{ formatNumber(totalPayable())}}</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="row mt-3">
                                        <div class="col-md-4" v-if="isPrinting">
                                            <button type="button" disabled class="btn btn-info btn-sm hide-on-pdf" @click="printPdf"><i class="fas fa-print"></i> Print</button>
                                            <!-- <button type="button" disabled class="btn btn-secondary btn-sm ml-3 hide-on-pdf">Export to Excel</button> -->
                                        </div>
                                        <div class="col-md-4" v-else>
                                            <button type="button" class="btn btn-info btn-sm hide-on-pdf" @click="printPdf"><i class="fas fa-print"></i> Print</button>
                                            <!-- <button type="button" class="btn btn-secondary btn-sm ml-3 hide-on-pdf">Export to Excel</button> -->
                                        </div>
                                        <pulse-loader 
                                            :loading="isPrinting" 
                                            :color="color"
                                            v-if="isPrinting" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// import ContentLoader from '../../components/ContentLoader.vue'
// import Swal from 'sweetalert2';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        // ContentLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            month: '',
            isLoading: false,
            isPrinting: false,
            payrollId: this.$route.params.id,
            sdls: [],
            color: '#40aac7'
        }
    },

    mounted(){
        this.fetchSdl();
    },

    methods: {

        async fetchSdl(){
            this.isLoading = true
            await Api().get(`get-sdl/${this.payrollId}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.sdls = res.data
                    this.month = res.data[0].payrolls.month
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to get sdl informations.', error)
                }
            })
        },
       
        formatNumber(value){
            return new Intl.NumberFormat().format(value);
        },

        totaNoEmployee(){
            return this.sdls.reduce((sum, item)=>{
                return sum + Number(item.no_of_employee);
            },0)
        },

        totaBasicSalary(){
            return this.sdls.reduce((sum, item)=>{
                return sum + Number(item.total_basic_salary);
            },0)
        },

        totalAllowance(){
            return this.sdls.reduce((sum, item)=>{
                return sum + Number(item.total_allowance);
            },0)
        },

        totalGrossSalary(){
            return this.sdls.reduce((sum, item)=>{
                return sum + Number(item.total_gross_salary);
            },0)
        },

        totalExemption(){
            return this.sdls.reduce((sum, item)=>{
                return sum + Number(item.exemption);
            },0)
        },

        totalSubjectToSdl(){
            return this.sdls.reduce((sum, item)=>{
                return sum + Number(item.amount_subject_to_sdl);
            },0)
        },

        totalPayable(){
            return this.sdls.reduce((sum, item)=>{
                return sum + Number(item.sdl_payable);
            },0)
        },

        async printPdf(){
            // Hide buttons
            this.isPrinting = true
            const buttons = document.querySelectorAll('.hide-on-pdf');
            buttons.forEach(button => button.style.display = 'none');

            const element = document.getElementById('table-content');
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/png');
            
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt',
                format: 'a4'
            });

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      
            // Convert the PDF to a Blob to create a URL for preview
            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new window or tab for preview
            const pdfWindow = window.open(pdfUrl, '_blank');

            if (pdfWindow) {
                this.isPrinting = false
                // Automatically print the PDF after opening the preview
                pdfWindow.addEventListener('load', () => {
                // pdfWindow.print();
                });
            } else {
                this.isLoading = false
                // If the window couldn't be opened (e.g., due to pop-up blockers)
                alert('Please allow pop-ups to preview and print the PDF.');
            }

            // Show buttons again
            buttons.forEach(button => button.style.display = 'inline-block');
        },

        // printPdf(){
        //     const buttons = document.querySelectorAll('.hide-on-pdf');
        //     buttons.forEach(button =>{
        //         button.style.display = 'none'
        //     })
        //     const element = document.getElementById('table-content');
        //     html2canvas(element).then(convas =>{
        //         const imgData = convas.toDataURL('img/png');
        //         const pdf = new jsPDF({
        //             orientation: 'portrait',
        //             unit: 'pt',
        //             format: 'a4'
        //         });
        //         const imgPros = pdf.getImageProperties(imgData);
        //         const pdfWidth = pdf.internal.pageSize.getWidth();
        //         const pdfHeight = (imgPros.height * pdfWidth) / imgPros.width;
        //         pdf.addImage(imgData,'PNG',0,0, pdfWidth, pdfHeight);
        //         pdf.save('sdl.pdf');

        //         buttons.forEach(button =>{
        //             button.style.display = 'inline-block'
        //         })
        //     })
        // }

    }
}
</script>

<style scoped>
    /* #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    } */
</style>