<template>
    <div class="container-fluid">
        <div class="row justify-content-center" style="margin-top: 8%;">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-md-3">
                                <span style="font-size: 24px; font-weight: 600;">Login</span>
                            </div>
                        </div>
                        <div class="row" v-if="any_error">
                            <div class="col-md-12">
                                <div class="alert alert-danger" role="alert">
                                    {{ any_error }}
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Email:</label>
                                    <input type="email" class="form-control" v-model="data.email"/>
                                    <div v-if="errors.email">
                                        <span class="text-danger">{{ errors.email[0] }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Password:</label>
                                    <input type="password" class="form-control" v-model="data.password"/>
                                    <div v-if="errors.password">
                                        <span class="text-danger">{{ errors.password[0] }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-md-12" v-if="isLoading">
                                <button type="button" class="btn btn-info btn-sm" disabled>Please Wait...</button>
                            </div>
                            <div class="col-md-12" v-else>
                                <button type="button" class="btn btn-info btn-sm btn-block" @click="login">LOG IN</button>
                            </div>
                            <!-- <div class="col-md-6">
                                <RouterLink to="/register-client">
                                    <button type="button" class="btn btn-default btn-sm btn-block" @click="changerToLogin">CREATE NEW ACCOUNT</button>
                                </RouterLink>
                            </div> -->
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-6">
                                <RouterLink to="/forget-password">
                                    <span  style="font-weight: 600; color: brown;">Forget your password?</span>
                                </RouterLink>
                            </div>
                            <div class="col-md-6">
                                <!-- <a href="https://depima.co.tz" style="font-weight: 600; color: #16B4C9;">Home Page</a> -->
                            </div>
                        </div>
                        <div class="row justify-content-center mt-2">
                            <pulse-loader
                             :loading="isLoading"
                             :color="color"
                             v-if="isLoading"
                            />
                        </div>
                        <span class="text-danger">{{ unauthorized }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { mapActions } from 'vuex';
// import Swal from 'sweetalert2';
// import store from '../../store/store';

export default{
    components: {
        PulseLoader
    },
    data(){
        return{
            data: {
                email: '',
                password: ''
            },

            isLoading: false,
            color: '#40aac7',
            errors: '',
            unauthorized: '',
            isLogin: false,
            password_error: '',
            permissions: [],
            any_error: '',
        }
    },

    methods: {
        ...mapActions(['updateToken','updateUser','updateClient','updatePermissions']),

        async login() {
            this.isLoading = true;

            const controller = new AbortController();
            const { signal } = controller;

            const timeout = setTimeout(() => {
                controller.abort();
            }, 10000);

            try {
                const res = await Api().post('login', this.data, { signal });

                clearTimeout(timeout);

                if (res.status === 200) {
                    this.isLoading = false;
                    this.updateToken(res.data.token);
                    this.updateUser(res.data.user);
                    this.fetchClient();
                    this.fetchPermissions();
                    this.$router.push({ name: 'dashboard' });
                    // .then(() => {
                    //     window.location.reload();
                    // })
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    this.any_error = 'Request was aborted due to timeout';
                } else if (error.response) {
                    if (error.response.status === 422) {
                        this.isLoading = false;
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 401) {
                        this.isLoading = false;
                        this.unauthorized = error.response.data.error;
                        this.data.email = '';
                        this.data.password = '';
                    }
                } else {
                    this.any_error = 'Network error or other issue';
                    this.isLoading = false;
                }
            } finally {
                this.isLoading = false;
            }
        },


        async fetchClient(){
            this.isLoading = true
            await Api().get('get-client')
            .then(res =>{
                if(res.status == 200){
                    this.isLoading = false
                    this.updateClient(res.data)
                    // console.log(store.getters.isClientSubscribed)
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to fetch client.',error)
                }
            })
        },

        async fetchPermissions(){
            this.isLoading = true
            await Api().get(`side-bar-permissions`)
            .then(res =>{
                if(res.status === 200){
                this.isLoading = false
                this.updatePermissions(res.data)
                this.permissions = res.data
                }
            }).catch(error =>{
                if(error){
                this.isLoading = false
                console.error('Failed to fetch permissions.',error)
                }
            });
        },
    },
}
</script>

<!-- Apply global styles -->
<style>
/* Full-page background color */
body {
    height: 100%;
    margin: 0;
    background-color: #16B4C9;
}

/* Ensures the container fills the height */
.full-height {
    min-height: 100vh;
}
</style>