<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 4%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="row pl-2 pr-2">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Company Info</span>
                                    </div>
                                    <div class="col-md-2">
                                        <RouterLink to="/settings">
                                            <button type="button" class="btn btn-info btn-sm" ><i class="fas fa-chevron-left"></i> Back</button> 
                                        </RouterLink>
                                    </div>
                                </div>
                            </div>
                            <pulse-loader 
                                :loading="isLoading" 
                                :color="color"
                                v-if="isLoading" 
                                style="margin-top: 50px; margin-left: 40%;"
                            />
                            <div class="card-body" v-else>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="card card-info card-outline">
                                            <div class="card-body box-profile">
                                                <div class="text-center" v-if="data.logo_name != ''">
                                                <img class="profile-user-img img-fluid img-circle"
                                                    :src="data.logo_name"
                                                    alt="Company Logo">
                                                </div>
                                                <div class="text-center" v-else>
                                                <img class="profile-user-img img-fluid img-circle"
                                                    src="../../assets/icon.png"
                                                    alt="Company Logo">
                                                </div>
                                                <h3 class="profile-username text-center">{{ data.business_name }}</h3>
                                                <p class="text-muted text-center">{{ data.business_type }}</p>
                                                <ul class="list-group list-group-unbordered mb-3">
                                                    <li class="list-group-item">
                                                        <b>Status</b> <a class="float-right"><span class="badge badge-success">{{ client.subscriptions }}</span></a>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <b>Subscription Due</b> <a class="float-right">{{ client.subscription_end }}</a>
                                                    </li>
                                                </ul>
                                                <!-- <a href="#" class="btn btn-info btn-block"><b>Follow</b></a> -->
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="col-md-9">
                                        <div class="card card-info card-outline">
                                            <div class="card-body">
                                                <table class="table table-sm table-hover table-bordered">
                                                    <tr>
                                                        <td>Mobile Number</td>
                                                        <td>Address</td>
                                                        <td>Region</td>
                                                        <td>Website</td>
                                                    </tr>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <input type="text" class="form-control" v-model="data.mobile_no"/>
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" v-model="data.address"/>
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" v-model="data.region"/>
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" v-model="data.website"/>
                                                            </td>
                                                        </tr>
                                                    </tbody>
            
                                                    <tr>
                                                        <td colspan="4"></td>
                                                    </tr>
            
                                                    <tr>
                                                        <td>Tin Number</td>
                                                        <td>Email Address</td>
                                                        <td>Nssf (%)</td>
                                                        <td>Wcf (%)</td>
                                                    </tr>
            
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <input type="text" class="form-control" v-model="data.tin_no"/>
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" v-model="data.email"/>
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" v-model="data.nssf"/>
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" v-model="data.wcf"/>
                                                            </td>
                                                        </tr>
                                                    </tbody>
            
                                                    <tr>
                                                        <td colspan="4"></td>
                                                    </tr>
            
                                                    <tr>
                                                        <td>Sdl (%)</td>
                                                        <td>Loan Board (%)</td>
                                                        <td>Primary Color Code</td>
                                                        <td>Secondary Color Code</td>
                                                    </tr>
            
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <input type="text" class="form-control" v-model="data.sdl"/>
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" v-model="data.loan_board"/>
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" v-model="data.primary_color"/>
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" v-model="data.secondary_color"/>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="row">
                                                    <div class="col-md-8">
                                                        <button type="button" v-if="updating" disabled class="btn btn-info btn-sm mt-3">Submiting</button>
                                                        <button type="button" v-else @click="updateCompanyInfo" class="btn btn-info btn-sm mt-3">Submit</button>
                                                        <pulse-loader 
                                                            :loading="updating" 
                                                            :color="color"
                                                            v-if="updating" 
                                                        />
                                                    </div>
                                                    <div class="col-md-4 mt-2">
                                                        <input type="file" class="form-control" @change="handleFileChange"/>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="alert alert-light" role="alert">
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <span>
                                                        <b>Warning:</b> If you reset the system, you will delete all data except users,
                                                        and you won't be able to revert this!
                                                    </span>
                                                </div>
                                                <div class="col-md-2">
                                                    <button type="button" class="btn btn-danger btn-sm" @click="deleteConfirm">Reset System</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from 'sweetalert2';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

    export default{
        components: {
            PulseLoader,
            Navbar,
            Sidebar,
            ContentHeader,
            ControlSidebar,
            Footer
        },
        data(){
            return{
                data: {
                    business_name: '',
                    business_type: '',
                    address: '',
                    email: '',
                    region: '',
                    website: '',
                    mobile_no: '',
                    tin_no: '',
                    nssf: 0,
                    wcf: 0,
                    loan_board: 0,
                    sdl: 0,
                    primary_color: '',
                    secondary_color: '',
                    logo_name: '',
                    users_count: 0,
                    employees_count: 0,
                },
                color: '#40aac7',
                isLoading: false,
                updating: false,
                fileBase64: '',
                client: {},
                error_message: '',
            }
        },

        mounted(){
            this.fetchCompanyInfo();
            this.fetchClient();
        },

        methods: {
            async fetchCompanyInfo(){
                this.isLoading = true
                await Api().get('get-company-info')
                .then(res =>{
                    if(res.status === 200){
                        this.isLoading = false
                        this.data.business_name = res.data.client.business_name
                        this.data.business_type = res.data.client.business_type
                        this.data.mobile_no = res.data.client.mobile_no
                        this.data.address = res.data.client.address
                        this.data.region = res.data.client.region
                        this.data.website = res.data.client.website
                        this.data.tin_no = res.data.client.tin_no
                        this.data.email = res.data.client.email
                        this.data.nssf = res.data.client.nssf
                        this.data.wcf = res.data.client.wcf
                        this.data.sdl = res.data.client.sdl
                        this.data.loan_board = res.data.client.loan_board
                        this.data.primary_color = res.data.client.primary_color
                        this.data.secondary_color = res.data.client.secondary_color
                        this.data.logo_name = res.data.client.logo_name
                        this.data.users_count = res.data.users_count
                        this.data.employees_count = res.data.employees_count
                    }
                }).catch(error =>{
                    if(error){
                        this.isLoading = false
                        console.log('Failed to get company info',error);
                    }
                })
            },

            async fetchClient(){
                this.isLoading = true
                await Api().get('get-client')
                .then(res =>{
                    if(res.status == 200){
                        this.isLoading = false
                        this.client = res.data
                    }
                }).catch(error =>{
                    if(error){
                        this.isLoading = false
                        console.log('Failed to fetch client.',error)
                    }
                })
            },

            handleFileChange(event) {
                const file = event.target.files[0];
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.fileBase64 = e.target.result.split(',')[1]; // Extract the base64 string
                };
                reader.readAsDataURL(file);
            },

            async updateCompanyInfo(){
                this.updating = true
                const payload = {
                    ...this.data,
                    file: this.fileBase64 // Add the base64 string to the payload
                };
                await Api().put('update-company-info',payload)
                .then(res =>{
                    if(res.status === 200){
                        this.updating = false
                        Swal.fire({
                            title: "Successfully!",
                            text: `${res.data.message}`,
                            icon: "success"
                        });
                    }
                }).catch(e =>{
                    if(e){
                        this.updating = false
                        console.log('Failed to update company info.',e)
                    }
                })
            },

            formatNumber(value){
                return new Intl.NumberFormat().format(value);
            },

            async resetSystem(){
                this.isLoading = true
                await Api().post('format-all-system')
                .then(res =>{
                    if(res.status === 200){
                        this.isLoading = false
                    }
                }).catch(error =>{
                    if(error){
                        this.isLoading = false
                        this.error_message = error.response.data.errors
                    }
                })
            },

            deleteConfirm(){
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: "btn btn-success",
                        cancelButton: "btn btn-danger"
                    },
                    buttonsStyling: false
                });

                swalWithBootstrapButtons.fire({
                title: "Are you sure?",
                text: "If you reset the system, you will delete all data and you won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true
                }).then((result) => {
                if (result.isConfirmed) {
                    this.resetSystem();
                    swalWithBootstrapButtons.fire({
                    title: "Deleted!",
                    text: "System has been reseted.",
                    icon: "success"
                    });
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire({
                    title: "Cancelled",
                    text: "Your system data is safe",
                    icon: "error"
                    });
                }
            });
        },
        }
    }
</script>

<style scoped>
    input{
        height: 30px;
    }
</style>