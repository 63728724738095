<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                   <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span style="font-size: 18px;"><b>Profile Info</b></span>
                                        </div>
                                        <div class="col-md-2">
                                            <RouterLink to="/self-services">
                                                <button type="button" class="btn btn-info btn-sm" ><i class="fas fa-chevron-left"></i> Back</button> 
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>

                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />

                                <div class="card-body" v-else>
                                    <div class="row" v-if="error">
                                        <div class="col-md-12">
                                            <div class="alert alert-danger" role="alert">
                                              {{ error }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="card card-info card-outline">
                                                <div class="card-body box-profile">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <div class="text-center">
                                                                <img class="profile-user-img img-fluid img-circle"
                                                                    src="../../assets/icon.png"
                                                                    alt="Company Logo">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-7">
                                                            <h3 class="profile-username"><b>{{ users.full_name }}</b></h3>
                                                            <span class="text-muted">{{ employees.designation }}</span><br>
                                                            <span class="text-muted">{{ employees.departiment }}</span><br>
                                                            <p style="margin-top: 5px;">EmployeeId: {{ employees.staffId }}</p>
                                                        </div>
                                                    </div><hr>
                                                    <div class="row mt-2">
                                                        <div class="col-md-12 text-center">
                                                            <span style="font-size: 22px;"><b>Bank Information</b></span>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2">
                                                        <div class="col-md-4">
                                                            <span><b>Bank Name:</b></span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span>{{ employees.bank_name }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-1">
                                                        <div class="col-md-4">
                                                            <span><b>Bank Account:</b></span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span>{{ employees.bank_account_no }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-1">
                                                        <div class="col-md-4">
                                                            <span><b>Bank Branch:</b></span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span>{{ employees.bank_branch }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-1">
                                                        <div class="col-md-4">
                                                            <span><b>Swift Code:</b></span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="card card-info card-outline">
                                                <div class="card-body">
                                                    <div class="row">
                                                            <div class="col-md-4">
                                                                <span><b>Phone:</b></span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <span>{{ users.phone }}</span>
                                                            </div>
                                                    </div>
                                                    <div class="row mt-1">
                                                            <div class="col-md-4">
                                                                <span><b>Email:</b></span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <span>{{ users.email }}</span>
                                                            </div>
                                                    </div>
                                                    <div class="row mt-1">
                                                            <div class="col-md-4">
                                                                <span><b>Birthday:</b></span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <span>{{ employees.birth_date }}</span>
                                                            </div>
                                                    </div>
                                                    <div class="row mt-1">
                                                            <div class="col-md-4">
                                                                <span><b>Gender:</b></span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <span></span>
                                                            </div>
                                                    </div>
                                                    <div class="row mt-1">
                                                            <div class="col-md-4">
                                                                <span><b>Address:</b></span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <span>{{ employees.address }}</span>
                                                            </div>
                                                    </div><hr>

                                                    <div class="row mt-1">
                                                        <div class="col-md-12 text-center">
                                                            <span style="font-size: 22px;"><b>Emergency Contact</b></span>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-1">
                                                        <div class="col-md-4">
                                                            <span><b>Full Name:</b></span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <span>{{ employees.relative_full_name }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-1">
                                                        <div class="col-md-4">
                                                            <span><b>Relationship:</b></span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-1">
                                                        <div class="col-md-4">
                                                            <span><b>Phone:</b></span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <span>{{ employees.relative_mobile }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-1">
                                                        <div class="col-md-4">
                                                            <span><b>Address:</b></span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <span>{{ employees.relative_address }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
// import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";

export default{
    components: {
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
                
            },
            isLoading: false,
            errors: '',
            error: '',
            color: '#40aac7',
            users: {},
            employees: {},
        }
    },

    mounted(){
        this.fetchProfileInfo();
    },

    methods: {
       async fetchProfileInfo(){
            this.isLoading = true
            await Api().get('get-profile-self')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.users = res.data.users
                    this.employees = res.data.employees
                }else if(res.status === 201){
                    this.isLoading = false
                    this.error = res.data.error
                    console.log(res.data.error)
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to fetch profile date.', error)
                }
            })
       }
    }
}
</script>