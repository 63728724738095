<template>
    <div class="chart-wrapper">
      <span>Expenses</span>
      <apexchart
        v-if="chartDataLoaded"
        type="bar"
        :options="chartOptions"
        :series="series"
      />
      <div v-else>Loading...</div>
    </div>
  </template>
  
  <script>
  import VueApexCharts from 'vue3-apexcharts';
  import Api from '../services/Api';
  
  export default {
    components: {
      apexchart: VueApexCharts
    },
    props: {
      apiUrl: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        series: [],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350,
            toolbar: {
              show: true
            },
          },
          plotOptions: {
            bar: {
              vertical: true,
              columnWidth: '50%'
            }
          },
          xaxis: {
            categories: []
          },
          responsive: [
            {
              breakpoint: 768,
              options: {
                chart: {
                  height: 300
                },
                plotOptions: {
                  bar: {
                    columnWidth: '70%'
                  }
                }
              }
            },
            {
              breakpoint: 480,
              options: {
                chart: {
                  height: 300
                },
                plotOptions: {
                  bar: {
                    columnWidth: '90%'
                  }
                },
                xaxis: {
                  labels: {
                    show: false
                  }
                }
              }
            }
          ]
        },
        chartDataLoaded: false
      };
    },
    
    async mounted() {
      try {
        const response = await Api().get(this.apiUrl);
        const expenses = response.data.expenses;
  
        const categories = expenses.map(expense => expense.month);
        const data = expenses.map(expense => parseFloat(expense.total_amount));
  
        this.series = [{
          name: 'Total Amount',
          data: data
        }];
        
        this.chartOptions.xaxis.categories = categories;
        this.chartDataLoaded = true;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };
  </script>
  
  <style scoped>
  .chart-wrapper {
    width: 60%;
    /* margin: 0 auto; */
    max-width: 60%;
  }
  </style>
  