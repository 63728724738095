<!-- src/components/Pagination.vue -->
<template>
    <nav aria-label="Page navigation">
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" @click.prevent="emitPageChange(currentPage - 1)" href="#">Previous</a>
        </li>
        <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: page === currentPage }">
          <a class="page-link" @click.prevent="emitPageChange(page)" href="#">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" @click.prevent="emitPageChange(currentPage + 1)" href="#">Next</a>
        </li>
      </ul>
    </nav>
  </template>
  
  <script>
    export default {
      props: {
        currentPage: {
          type: Number,
          required: true
        },
        totalPages: {
          type: Number,
          required: true
        }
      },
      
      methods: {
        emitPageChange(page) {
          if (page >= 1 && page <= this.totalPages) {
            this.$emit('page-change', page);
          }
        }
      }
    };
  </script>
  
  <style scoped>
  .pagination {
    justify-content: left;
    margin-top: 1px;
  }
  .page-item.disabled .page-link {
    pointer-events: none;
    cursor: default;
    color: #6c757d;
  }
  .page-item.active .page-link {
    background-color: #17a2b8;
    border-color: #17a2b8;
    color: white;
  }
  </style>
  