<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 2%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-4 col-sm-6 col-lg-9" style="margin-top: 3%; margin-bottom: 4%;">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-10">
                                            <span>Update client payment</span>
                                        </div>
                                        <div class="col-2">
                                            <RouterLink to="/client-lists">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
            
                                <div class="card-body" v-else>
                                    <div class="row justify-content-center p-2">
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <div class="form-group">
                                                <span>Amount</span>
                                                <input class="form-control"
                                                 style="height: 35px;"
                                                 v-model="formattedAmount"
                                                  @blur="removeFormatting('amount')" 
                                                    @focus="addFormatting('amount')"
                                                />
                                                <div v-if="errors.amount">
                                                    <span class="text-danger">{{ errors.amount[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6 col-lg-5">
                                            <span>Date</span><br>
                                            <date-picker v-model:value="data.date" 
                                                value-type="YYYY-MM-DD" 
                                                placeholder="Select Date"
                                            />
                                            <div v-if="errors.date">
                                                <span class="text-danger">{{ errors.date[0] }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row justify-content-center mb-3">
                                        <div class="col-4" v-if="isUpdating">
                                            <button type="button" class="btn btn-info btn-sm" disabled>Submiting...</button>
                                        </div>
                                        <div class="col-4" v-else>
                                            <button type="button" class="btn btn-info btn-sm" @click="updateClient">Submit</button>
                                        </div>
                                        <pulse-loader 
                                            :loading="isUpdating" 
                                            :color="color"
                                            v-if="isUpdating" 
                                        />
                                        <div class="col-6">
                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

export default{
    components: {
        PulseLoader,
        DatePicker,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer
    },
    data(){
        return{
            data: {
                amount: '',
                date: '',
                clientId: this.$route.params.clientId,
            },
            isLoading: false,
            isUpdating: false,
            errors: '',
            color: '#40aac7',
        }
    },

    computed: {
        formattedAmount: {
            get() {
                return this.formatNumber(this.data.amount);
            },

            set(value) {
                this.data.amount = this.parseNumber(value);
            }
        },
    },

    methods: {
      
        async updateClient(){
            this.isUpdating = true
            await Api().post('client-update-payment',this.data)
            .then(res =>{
                if(res.status === 200){
                    this.isUpdating = false
                    this.data.amount = ''
                    this.data.date = ''
                    Swal.fire({
                        title: "Successfully!",
                        text: `${res.data.message}`,
                        icon: "success"
                    });
                }else{
                    this.isUpdating = false
                    Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${res.data.error}`,
                    });
                }
            }).catch(error =>{
                if(error){
                    this.isUpdating = false
                    this.errors = error.response.data.errors
                }
            })
        },

        formatNumber(value) {
            return new Intl.NumberFormat().format(value);
        },

        parseNumber(value) {
            if (typeof value !== 'string') {
                value = String(value);
            }
            return parseFloat(value.replace(/,/g, '')) || 0;
        },
        

        addFormatting(fieldName) {
            this.data[fieldName] = this.formatNumber(this.data[fieldName]);
        },

        removeFormatting(fieldName) {
            this.data[fieldName] = this.parseNumber(this.data[fieldName]);
        },
    }
}
</script>