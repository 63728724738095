<template>
    <body class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6 col-md-3 col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Sub Products List</span>
                                        </div>
                                        <div class="col-md-2">
                                            <RouterLink to="/products-list">
                                                <button type="button" class="btn btn-info btn-sm" ><i class="fas fa-chevron-left"></i> Back</button> 
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>

                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
                            
                                <div class="card-body" v-else>
                                    <div class="row mb-1">
                                        <div class="col-md-3">
                                            <input type="text" v-model="searchQuery" placeholder="Search..." class="form-control" style="height: 35px;" />
                                        </div>
                                    </div>
                                    <table id="example1" class="table table-hover table-bordered table-striped table-sm table-sm table-responsive-sm">
                                        <tr>
                                            <th>Product Name</th>
                                            <th>Sub Product Name</th>
                                            <th>Unit Measure</th>
                                            <th v-if="hasPermission('edit_product')">Edit</th>
                                            <th v-if="hasPermission('delete_product')">Delete</th>
                                        </tr>
            
                                        <tbody>
                                            <tr v-for="(product, i) in displayedProducts" :key="i">
                                                <td>{{ product.products.product_name }}</td>
                                                <td>{{ product.sub_product_name }}</td>
                                                <td>{{ product.unit_measure }}</td>
                                                <td v-if="hasPermission('edit_product')">
                                                    <RouterLink :to="{name: 'edit-sub-product', params: {id: product.id}}">
                                                        <button type="button" class="btn btn-info btn-sm"><i class="fa fa-edit"></i></button>
                                                    </RouterLink>
                                                </td>
                                                <td v-if="hasPermission('delete_product')">
                                                    <button type="button" class="btn btn-danger btn-sm" @click="deleteConfirm(product.id)"><i class="fa fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Pagination 
                                        :currentPage="currentPage" 
                                        :totalPages="totalPages" 
                                        @page-change="handlePageChange" 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <ControlSidebar></ControlSidebar>
        </div>
    </body>
</template>


<script>
import Api from '../../services/Api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from 'sweetalert2';
import store from '../../store/store';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";
import Pagination from '../../components/Pagination.vue';

export default {
    components: {
        PulseLoader,
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer,
        Pagination,
    },

    data() {
        return {
            sub_products: [],
            displayedProducts: [],
            searchQuery: '',
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
            isLoading: false,
            permissions: [],
            color: '#40aac7'
        };
    },

    mounted() {
        this.fetchSubProducts();
        this.fetchPermissions();
    },

    methods: {
        async fetchSubProducts() {
            this.isLoading = true;
            try {
                const res = await Api().get('sub-products');
                if (res.status === 200) {
                    this.sub_products = res.data;
                    this.updatePaginatedData();
                }
            } catch (error) {
                console.error('Failed to get sub-products:', error);
            } finally {
                this.isLoading = false;
            }
        },

        updatePaginatedData() {
            const filteredProducts = this.sub_products.filter(product => 
                product.products.product_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                product.sub_product_name.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            this.displayedProducts = filteredProducts.slice(start, end);
            this.totalPages = Math.ceil(filteredProducts.length / this.perPage);
        },

        handlePageChange(page) {
            this.currentPage = page;
            this.updatePaginatedData();
        },

        async deleteConfirm(id) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });

            const result = await swalWithBootstrapButtons.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true
            });

            if (result.isConfirmed) {
                await this.deleteSubProduct(id);
                this.sub_products = this.sub_products.filter(product => product.id !== id);
                this.updatePaginatedData();
                swalWithBootstrapButtons.fire({
                    title: "Deleted!",
                    text: "Sub product has been deleted.",
                    icon: "success"
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire({
                    title: "Cancelled",
                    text: "Your product is safe",
                    icon: "error"
                });
            }
        },

        async deleteSubProduct(id) {
            this.isLoading = true;
            try {
                const res = await Api().delete(`sub-products/${id}`);
                if (res.status === 200) {
                    console.log('Sub product deleted successfully');
                }
            } catch (error) {
                console.error('Failed to delete sub-product:', error);
            } finally {
                this.isLoading = false;
            }
        },

        async fetchPermissions() {
            this.isLoading = true;
            try {
                const res = await Api().get(`permissions/${store.state.user.id}`);
                if (res.status === 200) {
                    this.permissions = res.data;
                }
            } catch (error) {
                console.error('Failed to fetch permissions:', error);
            } finally {
                this.isLoading = false;
            }
        },

        hasPermission(permissionKey) {
            return this.permissions.some(permission => permission.key === permissionKey && permission.value);
        }
    },

    watch: {
        searchQuery: 'updatePaginatedData',
        currentPage: 'updatePaginatedData'
    }
}
</script>



<style scoped>
    #example1 {
        border-collapse: collapse;
        border: 1px solid #839E99;
        background: #f1f8ee;
        color: #033;
    }
</style>
