<template>
    <body  class="hold-transition sidebar-mini layout-fixed sidebar-collapse" style="margin-top: 3%;">
        <div class="wrapper">
            <Navbar></Navbar>
            <Sidebar></Sidebar>
            <div class="content-wrapper">
                <ContentHeader></ContentHeader>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span><b>Pending Leaves</b></span>
                                        </div>
                                        <div class="col-md-2">
                                            <RouterLink to="/hr-dashboard">
                                                <button type="button" class="btn btn-info btn-sm"><i class="fas fa-chevron-left"></i> Back</button>
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                <pulse-loader 
                                    :loading="isLoading" 
                                    :color="color"
                                    v-if="isLoading" 
                                    style="margin-top: 50px; margin-left: 40%;"
                                />
                                <div class="card-body" v-else>
                                    <div class="table-responsive">
                                        <div class="row mb-1">
                                            <div class="col-md-3">
                                                <input type="text" v-model="searchQuery" placeholder="Search..." class="form-control" style="height: 35px;" />
                                            </div>
                                        </div>
                                        <table id="example1" class="table table-hover table-bordered table-striped table-sm table-sm table-responsive-sm">
                                            <tr>
                                                <th>Leave Type</th>
                                                <th>Employee Name</th>
                                                <th>From</th>
                                                <th>To</th>
                                                <th>Days Requested</th>
                                                <th>Reason</th>
                                                <th>Status</th>
                                                <th>Requested By</th>
                                                <th>View</th>
                                                <th>Delete</th>
                                            </tr>
    
                                            <tbody>
                                                <tr v-for="(leave, i) in displayedLeaves" :key="i">
                                                    <td>{{ leave.leave_types.name }}</td>
                                                    <td>{{ leave.requestedBy }}</td>
                                                    <td>{{ leave.fromDate }}</td>
                                                    <td>{{ leave.toDate }}</td>
                                                    <td align="center">{{ leave.days_requested }}</td>
                                                    <td>{{ leave.reason.substring(0, 10) }}...</td>
                                                    <td>
                                                        <span v-if="leave.status === 'Approved'" class="badge badge-success">{{ leave.status }}</span>
                                                        <span v-else class="badge badge-danger">{{ leave.status }}</span>
                                                    </td>
                                                    <td>{{ leave.requestedBy }}</td>
                                                    <td>
                                                        <RouterLink :to="{name: 'view-leave-request', params: {id: leave.id}}">
                                                            <button type="button" class="btn btn-info btn-sm"><i class="fa fa-eye"></i></button>
                                                        </RouterLink>
                                                    </td>
                                                    <td>
                                                        <button v-if="leave.status === 'Pending'" @click="deleteConfirm(leave.id, i)" type="button" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <Pagination 
                                            :currentPage="currentPage" 
                                            :totalPages="totalPages" 
                                            @page-change="handlePageChange" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
                <ControlSidebar></ControlSidebar>
            </div>
        </div>
    </body>
</template>

<script>
import Api from '../../services/Api';
import Swal from 'sweetalert2';
import Navbar from "../../components/Navbar.vue";
import Sidebar from "../../components/Sidebar.vue";
import ContentHeader from "../../components/ContentHeader.vue";
import Footer from "../../components/Footer.vue";
import ControlSidebar from "../../components/ControlSidebar.vue";
import Pagination from '../../components/Pagination.vue';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default{
    components: {
        Navbar,
        Sidebar,
        ContentHeader,
        ControlSidebar,
        Footer,
        Pagination,
        PulseLoader
    },
    data(){
        return{
            isLoading: false,
            color: '#40aac7',
            get_leaves: [],
            displayedLeaves: [],
            searchQuery: '',
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
        }
    },

    mounted(){
        this.getLeave();
    },

    methods: {
        async getLeave(){
            this.isLoading = true
            await Api().get('get-pending-leaves')
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.get_leaves = res.data
                    this.updatePaginatedData();
                }
            }).catch(error =>{
                if(error){
                    this.isLoading = false
                    console.log('Failed to get leaves.',error)
                }
            })
        },

        updatePaginatedData() {
            const filteredProducts = this.get_leaves.filter(leave => 
                leave.leave_types.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                leave.fromDate.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                leave.toDate.toLowerCase().includes(this.searchQuery.toLowerCase()) 
            );
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            this.displayedLeaves = filteredProducts.slice(start, end);
            this.totalPages = Math.ceil(filteredProducts.length / this.perPage);
        },

        handlePageChange(page) {
            this.currentPage = page;
            this.updatePaginatedData();
        },


        async deleteLeaveRequest(id, index){
            this.isLoading = true
            await Api().delete(`delete-reave-requests/${id}`)
            .then(res =>{
                if(res.status === 200){
                    this.isLoading = false
                    this.displayedLeaves.splice(index, 1)
                }
            })
        },

        deleteConfirm(id, index){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true
            }).then((result) => {
            if (result.isConfirmed) {
                this.deleteLeaveRequest(id, index);
                swalWithBootstrapButtons.fire({
                title: "Deleted!",
                text: "Leave request has been deleted.",
                icon: "success"
                });
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire({
                title: "Cancelled",
                text: "Your Leave request is safe",
                icon: "error"
                });
            }
            });
        },
    },

    watch: {
        searchQuery: 'updatePaginatedData',
        currentPage: 'updatePaginatedData'
    }
}
</script>

<style scoped>
    #example1{
        border-collapse: collapse; 
        border: 1px solid #839E99;
        background: #f1f8ee;  
        color: #033;
    }
</style>